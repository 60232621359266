<template>
  <div class="rooms-wrapper flex-grow-1 mr-sm-4">
    <h2 class="text-h5 mb-4">{{ $t("paymentGuarantee") }}</h2>
    <p v-if="guaranteeText" class="show-line-breaks">{{ guaranteeText }}</p>
    <iframe
      ref="ccFormVault"
      :src="iframeUrl"
      scrolling="no"
      class="cc-form-iframe"
    ></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { axiosPost } from "@/api/requests";

export default {
  name: "VaultCcForm",
  computed: {
    iframeUrl() {
      let url =
        process.env.VUE_APP_CCFORM_URL +
        "?resid=" +
        this.resId +
        "&hotelcode=" +
        this.hotel.code +
        "&arrival=" +
        this.stayDates.arrivalDate +
        "&departure=" +
        this.stayDates.departureDate +
        "&locale=" +
        this.language.locale;
      if (this.appStyles.primaryColor) {
        url = url + "&color=" + this.appStyles.primaryColor.substring(1);
      }
      return url;
    },
    guaranteeText() {
      let guaranteeText = "";
      const guaranteeTextMatch = this.hotel.uiSettings.guarantee.find(
        (lang) => lang.language === this.language.locale
      );
      if (guaranteeTextMatch) {
        guaranteeText = guaranteeTextMatch.description;
      }
      return guaranteeText;
    },
    ...mapState(["stayDates", "hotel", "token", "resId", "segment"]),
    ...mapState("language", ["language"]),
    ...mapState("appStyles", ["appStyles"]),
  },
  mounted() {
    window.addEventListener("message", this.handleCcFormIframeMessage);
  },
  destroyed() {
    window.removeEventListener("message", this.handleCcFormIframeMessage);
  },
  methods: {
    sendMessageToFrame(message) {
      const target = new URL(process.env.VUE_APP_CCFORM_URL),
        targetOrigin = target.origin;
      this.$refs.ccFormVault.contentWindow.postMessage(message, targetOrigin);
    },
    handleCcFormIframeMessage(e) {
      const iframeUrl = new URL(process.env.VUE_APP_CCFORM_URL),
        iframeUrlOrigin = iframeUrl.origin;
      if (e.origin === iframeUrlOrigin) {
        try {
          const messageData = JSON.parse(e.data);
          switch (messageData.action) {
            case "showForm":
              this.sendMessageToFrame("showForm");
              break;
            case "handleVaultPostSuccess":
              this.handleVaultSuccess(messageData.data);
              break;
            default:
              break;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    handleVaultSuccess(ccdata) {
      const url = process.env.VUE_APP_IBE_URL + "/pay/guarantee";
      const data = {
        Environment: this.hotel.environment,
        HotelCode: this.hotel.code,
        Segment: this.segment,
        reservationID: this.resId,
        lastFourDigits: ccdata.lastFourDigits,
        cardholderName: ccdata.cardholderName,
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          if (response.success) {
            this.$emit("booking-finished");
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
  },
};
</script>

<style lang="css">
.cc-form-iframe {
  width: 400px;
  height: 100%;
  min-height: 500px;
  border: 0;
  display: block;
  margin: 0 auto;
}
</style>
