<template>
  <div
    class="mx-sm-2 d-flex flex-column align-center align-md-start flex-grow-1"
  >
    <h3 class="room-name-wrapper my-2 mt-sm-0 notranslate">
      {{ name }}
      <v-btn
        v-if="!hideDescriptionToggle"
        icon
        color="black"
        @click.stop="$emit('toggle-description')"
      >
        <v-icon>$information</v-icon>
      </v-btn>
    </h3>
    <div class="room-amenities mt-auto">
      <v-tooltip
        v-for="(amenity, idx) in amenities"
        :key="`${amenity.id}_${idx}`"
        top
        color="primary"
      >
        <template #activator="{ on }">
          <v-icon class="mx-2" color="black" v-on="on">{{
            amenity.icon
          }}</v-icon>
        </template>
        <span>{{ $t(amenity.key) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { roomAmenities } from "@/static/staticData";
import { sortRoomAmenities } from "@/helpers/arrayHelpers";

export default {
  name: "RoomInfo",
  props: {
    name: {
      type: String,
      required: true,
    },
    roomAmenities: {
      type: Array,
      required: true,
    },
    hideDescriptionToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    amenities() {
      const amenitiesSortedByGroup = sortRoomAmenities(this.roomAmenities);
      return amenitiesSortedByGroup.map((id) =>
        roomAmenities.find((item) => item.id === id)
      );
    },
  },
};
</script>
