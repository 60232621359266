<template>
  <div class="summary-wrapper ml-auto">
    <h2 class="text-h5 mb-4">
      {{ $t("bookingSummary") }}
    </h2>
    <booking-summary-data></booking-summary-data>
  </div>
</template>

<script>
import BookingSummaryData from "@/components/BookingSummaryData";

export default {
  name: "BookingSummary",
  components: {
    BookingSummaryData,
  },
};
</script>
