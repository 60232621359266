<template>
  <div class="room-wrapper">
    <div class="d-flex flex-column flex-md-row">
      <div
        v-if="optionImage"
        :class="{
          mobile: $vuetify.breakpoint.smAndDown,
        }"
        class="room-image available"
      >
        <v-img
          max-width="100%"
          :src="optionImage.imageURL.slice(0, -5) + '2.png?' + noImageCache"
        >
        </v-img>
      </div>
      <div class="room-info mx-sm-3 d-flex flex-column flex-grow-1">
        <h3 class="my-2 mt-sm-0 notranslate">{{ optionName }}</h3>
        <p class="show-line-breaks">{{ optionDescription }}</p>
        <selected-dates-type-service-form
          v-if="selectedDatesTypeService"
          ref="selectedDatesTypeServiceForm"
          :disable-form="optionIsAddedToBooking"
          :table-reservation="option.serviceType === 1"
          :charge-type="option.chargeType"
          :service-date-options="serviceDateOptions"
          @update-service-date-options="serviceDateOptions = $event"
        ></selected-dates-type-service-form>
      </div>
      <div class="align-self-md-end">
        <div class="d-flex flex-md-column align-center">
          <div
            :class="{ 'option-price-unit': $vuetify.breakpoint.lgAndUp }"
            class="ma-2 d-flex flex-column justify-center flex-grow-1"
          >
            <div class="text-no-wrap text-center">
              <span class="currency-sign mx-2">{{ hotel.currencySign }}</span>
              <span class="text-h4">{{
                getFormattedAmount(option.price).units
              }}</span
              ><span class="ml-n1">
                {{ getFormattedAmount(option.price).cents }}</span
              >
            </div>
            <span class="text-caption text-center mt-n1">
              {{ optionUnitDescription }}
            </span>
          </div>
          <div class="d-flex flex-column justify-end">
            <template v-if="showUnitsAmount">
              <div class="my-sm-auto d-flex d-sm-block align-center">
                <h5
                  class="text-body-1 text-sm-body-2 text-center font-weight-bold"
                >
                  {{ $t("units") }}
                </h5>
                <div class="d-flex justify-center align-center">
                  <v-btn icon :disabled="units <= 1" @click.stop="subtractUnit">
                    <v-tooltip top color="primary">
                      <template #activator="{ on }">
                        <v-icon color="black" dark v-on="on"
                          >$remove_circle</v-icon
                        >
                      </template>
                      <span>-1</span>
                    </v-tooltip>
                  </v-btn>
                  <span class="mx-2">{{ units }}</span>
                  <v-btn icon @click.stop="addUnit">
                    <v-tooltip top color="primary">
                      <template #activator="{ on }">
                        <v-icon color="black" dark v-on="on"
                          >$add_circle</v-icon
                        >
                      </template>
                      <span>+1</span>
                    </v-tooltip>
                  </v-btn>
                </div>
              </div>
            </template>
            <v-btn
              class="mt-2"
              rounded
              :small="$vuetify.breakpoint.smAndDown"
              color="primary"
              :disabled="disableAddOptionButton"
              @click="addOption"
            >
              {{ $t("add") }}
              <v-icon :large="$vuetify.breakpoint.mdAndUp" right
                >$arrow_right</v-icon
              >
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { serviceChargeTypes } from "@/static/staticData";
import localizedText from "@/mixins/localizedText";
import snackBarMessages from "@/mixins/snackBarMessages";
import amounts from "@/mixins/amounts";
import SelectedDatesTypeServiceForm from "@/components/SelectedDatesTypeServiceForm";

export default {
  name: "IbeOption",
  components: {
    SelectedDatesTypeServiceForm,
  },
  mixins: [amounts, localizedText, snackBarMessages],
  props: {
    option: {
      type: Object,
      required: true,
    },
    roomBooking: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    units: 1,
    serviceDateOptions: [],
  }),
  computed: {
    optionImage() {
      let image = null;
      if (this.option.images.length > 0) {
        image = this.option.images[0];
      }
      return image;
    },
    optionName() {
      return this.getLocalizedText(this.option.serviceData, "name");
    },
    optionDescription() {
      return this.getLocalizedText(this.option.serviceData, "description");
    },
    optionUnitDescription() {
      let description = "";
      const chargetype = serviceChargeTypes.find(
        (type) => type.id === this.option.chargeType
      );
      if (chargetype) {
        description = this.$t(chargetype.key);
      }
      return description;
    },
    showUnitsAmount() {
      return [6, 7, 8, 9].includes(this.option.chargeType);
    },
    optionIsAddedToBooking() {
      return this.roomBooking.services.some(
        (service) => service.serviceCode === this.option.serviceCode
      );
    },
    selectedDatesTypeService() {
      return [14, 15, 16, 20, 29].includes(this.option.chargeType);
    },
    datesAreSelected() {
      return this.serviceDateOptions.filter((date) => date.selected).length > 0;
    },
    dateOptionUnitsDefault() {
      const selectedRoom = this.roomBooking.selectedRoom;
      let units = 1;
      switch (this.option.chargeType) {
        case 15:
          units = selectedRoom.adults;
          break;
        case 16:
          units = selectedRoom.children;
          break;
        case 20:
          units = selectedRoominfants;
          break;
        case 29:
          units =
            selectedRoom.adults + selectedRoom.children + selectedRoom.infants;
          break;
        default:
          break;
      }
      return units;
    },
    disableAddOptionButton() {
      return (
        this.optionIsAddedToBooking ||
        (this.selectedDatesTypeService && !this.datesAreSelected)
      );
    },
    unitsChangedAfterAdd() {
      const addedOption = this.roomBooking.services.find(
        (service) => service.serviceCode === this.option.serviceCode
      );
      return addedOption && addedOption.units !== this.units;
    },
    ...mapState(["hotel", "roomBookings", "noImageCache"]),
  },
  watch: {
    roomBooking: {
      immediate: true,
      handler(val) {
        if (this.selectedDatesTypeService) {
          let roomBookingServiceRange = null;
          if (this.optionIsAddedToBooking) {
            roomBookingServiceRange = val.services.find(
              (service) => service.serviceCode === this.option.serviceCode
            ).range;
          }
          this.serviceDateOptions = this.createServiceDateOptions(
            roomBookingServiceRange
          );
        }
      },
    },
  },
  methods: {
    addOption() {
      const option = JSON.parse(JSON.stringify(this.option));
      option.units = this.units;
      if (this.selectedDatesTypeService) {
        if (
          this.$refs.selectedDatesTypeServiceForm.$refs.selectDatesForm.validate()
        ) {
          const selectedDates = JSON.parse(
            JSON.stringify(this.serviceDateOptions)
          ).filter((date) => date.selected);
          selectedDates.forEach((date) => {
            date.start = !!date.time
              ? `${date.date}T${date.time}`
              : `${date.date}T00:00:00`;
          });
          option.range = selectedDates;
          this.$emit("select-option", option);
        } else {
          this.snackbarInvalidForm();
        }
      } else {
        this.$emit("select-option", option);
      }
    },
    addUnit() {
      this.units++;
      if (this.unitsChangedAfterAdd) {
        this.addOption();
      }
    },
    subtractUnit() {
      this.units--;
      if (this.unitsChangedAfterAdd) {
        this.addOption();
      }
    },
    createServiceDateOptions(roomBookingServiceRange) {
      const serviceDateOptions = JSON.parse(
        JSON.stringify(this.option.range)
      ).filter((date) => date.isAvailable);
      serviceDateOptions.forEach((date) => {
        date.date = date.start.substring(0, 10);
        date.selected = false;
        date.units = this.dateOptionUnitsDefault;
        date.time = "19:00";
        delete date.end;
      });
      if (roomBookingServiceRange) {
        const addedDateOptions = JSON.parse(
          JSON.stringify(roomBookingServiceRange)
        );
        serviceDateOptions.forEach((date) => {
          const addedOption = addedDateOptions.find(
            (option) => option.date === date.date
          );
          if (addedOption) {
            date.units = addedOption.units;
            date.selected = true;
            date.time = addedOption.time;
          }
        });
      }
      return serviceDateOptions;
    },
  },
};
</script>
