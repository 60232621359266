<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div class="booking-summary-roominfo">
        <h4 class="room-name">
          {{ roomNumber }}:
          <span class="mx-1 notranslate">{{ roomName }}</span>
          <span
            >({{
              booking.selectedRoom.adults > 1
                ? hotel.ageBuckets.length
                  ? $t("numberOfAdults").format(booking.selectedRoom.adults)
                  : $t("numberOfPersons").format(booking.selectedRoom.adults)
                : hotel.ageBuckets.length
                ? $t("1adult")
                : $t("1person")
            }}</span
          >
          <span v-if="booking.selectedRoom.children"
            >,
            {{
              booking.selectedRoom.children > 1
                ? $t("numberOfChildren").format(booking.selectedRoom.children)
                : $t("1child")
            }}</span
          >
          <span v-if="booking.selectedRoom.infants"
            >,
            {{
              booking.selectedRoom.infants > 1
                ? $t("numberOfInfants").format(booking.selectedRoom.infants)
                : $t("1infant")
            }}</span
          >)
        </h4>
        <div class="d-flex flex-column">
          <span
            v-if="booking.rate.discount"
            class="text-body-2 discount-message"
          >
            {{ $t("yourDiscount") }}
            <summary-data-amount
              :amount="booking.rate.discount"
            ></summary-data-amount>
          </span>
          <summary-data-amount
            :amount="booking.rate.total"
          ></summary-data-amount>
        </div>
      </div>
      <v-btn
        v-if="step < 3"
        icon
        class="ml-auto align-self-center"
        @click.stop="removeRoom"
      >
        <v-tooltip top color="primary">
          <template #activator="{ on }">
            <v-icon color="error" dark v-on="on">$delete</v-icon>
          </template>
          <span>{{ $t("removeRoom") }}</span>
        </v-tooltip>
      </v-btn>
    </div>
    <v-btn
      class="mx-auto mt-n6"
      icon
      color="black"
      @click.stop="showRoomInfo = !showRoomInfo"
    >
      <v-icon>{{
        showRoomInfo ? "$arrow_up_circle" : "$arrow_down_circle"
      }}</v-icon>
    </v-btn>
    <v-expand-transition>
      <div v-show="showRoomInfo">
        <h4 class="notranslate">{{ rateName }}</h4>
        <p class="mb-0">{{ ratePolicy }}</p>
        <p>{{ rateDescription }}</p>
      </div>
    </v-expand-transition>
    <template v-if="booking.services.length > 0">
      <booked-option
        v-for="(option, idx) in booking.services"
        :key="`extra_${idx}`"
        :option="option"
        @remove-option="removeOption(idx)"
      ></booked-option>
    </template>
    <template v-if="additionalRoomServices.length > 0">
      <additional-room-service
        v-for="service in additionalRoomServices"
        :key="service.serviceCode"
        :service="service"
        :room="booking"
      ></additional-room-service>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import localizedText from "@/mixins/localizedText";
import BookedOption from "@/components/BookedOption";
import SummaryDataAmount from "@/components/SummaryDataAmount";
import AdditionalRoomService from "@/components/AdditionalRoomService";

export default {
  name: "RoomBooking",
  components: {
    BookedOption,
    SummaryDataAmount,
    AdditionalRoomService,
  },
  mixins: [localizedText],
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showRoomInfo: false,
  }),
  computed: {
    roomName() {
      return this.getLocalizedText(this.booking.room.roomData, "name");
    },
    roomNumber() {
      let roomsGuestsRoomIndex = this.roomsGuests.findIndex(
        (room) => room.id === this.booking.id
      );
      return roomsGuestsRoomIndex + 1;
    },
    rateName() {
      return this.getLocalizedText(this.booking.rate.rateData, "name");
    },
    rateDescription() {
      return this.getLocalizedText(this.booking.rate.rateData, "description");
    },
    ratePolicy() {
      return this.getLocalizedText(this.booking.rate.rateData, "policy");
    },
    additionalRoomServices() {
      const additionalRoomServices = this.additionalServices.filter(
        (service) =>
          service.applicationType === 3 &&
          this.booking.availableServices.some(
            (availableService) =>
              availableService.serviceCode === service.serviceCode
          ) &&
          (service.rooms.length === 0 ||
            service.rooms.includes(this.booking.room.code))
      );
      return additionalRoomServices;
    },
    ...mapState(["hotel", "step", "roomsGuests", "additionalServices"]),
  },
  methods: {
    removeRoom() {
      this.$root
        .$confirm(
          this.$t("removeRoom"),
          this.$t("removeRoomBookingConfirmMessage")
        )
        .then((confirmResult) => {
          if (confirmResult) {
            this.$emit("remove-room");
          }
        });
    },
    removeOption(index) {
      this.booking.services.splice(index, 1);
    },
  },
};
</script>
