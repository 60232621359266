import { axiosPost } from "@/api/requests";
import { format, addDays } from "date-fns";

export default {
  data: () => ({
    dateRangePicker: false,
    dates: [],
    noAvailableDates: [],
    noArrivalDates: [],
    noDepartureDates: [],
    minLosDays: [],
    maxLosDays: [],
    loadingCalendarData: false,
    loadedCalendarMonths: [],
  }),
  computed: {
    fixedRange() {
      if (!this.packageData.rateCode) {
        return 0;
      }
      return this.packageData.maxLos;
    },
    maxDate() {
      let maxDate = format(addDays(new Date(), 546), "YYYY-MM-DD");
      if (this.dates.length === 1) {
        if (this.disabledArrivalDates.includes(this.dates[0])) {
          maxDate = this.dates[0];
        } else {
          const firstNoAvailableDate = this.noAvailableDates.find(
            (date) => date > this.dates[0]
          );
          let noAvailableDateMaxdate = null;
          if (firstNoAvailableDate) {
            noAvailableDateMaxdate = firstNoAvailableDate;
          }
          const arrivalDateMaxlos = this.maxLosDays.find(
            (date) => date.day === this.dates[0]
          );
          let maxLosDeparture = null;
          if (arrivalDateMaxlos) {
            maxLosDeparture = format(
              addDays(this.dates[0], arrivalDateMaxlos.maxLOS),
              "YYYY-MM-DD"
            );
          }
          if (firstNoAvailableDate || arrivalDateMaxlos) {
            if (firstNoAvailableDate && arrivalDateMaxlos) {
              if (noAvailableDateMaxdate <= maxLosDeparture) {
                maxDate = noAvailableDateMaxdate;
              } else {
                maxDate = maxLosDeparture;
              }
            } else if (firstNoAvailableDate) {
              maxDate = noAvailableDateMaxdate;
            } else if (arrivalDateMaxlos) {
              maxDate = maxLosDeparture;
            }
            if (this.disabledDepartureDates.includes(maxDate)) {
              const daysInbetween = Math.ceil(
                Math.abs(
                  new Date(maxDate).getTime() -
                    new Date(this.dates[0]).getTime()
                ) /
                  (1000 * 3600 * 24)
              );
              const inBetweenDisabledDepartureDates =
                this.disabledDepartureDates.filter(
                  (date) => date > this.dates[0] && date < maxDate
                );
              if (
                inBetweenDisabledDepartureDates.length ===
                daysInbetween - 1
              ) {
                maxDate = this.dates[0];
              }
            }
          }
        }
      }
      return maxDate;
    },
    minDate() {
      let minDate = format(new Date(), "YYYY-MM-DD");
      if (this.dates.length === 1) {
        minDate = this.dates[0];
      }
      return minDate;
    },
    disabledDepartureDates() {
      let disabledDepartureDates = JSON.parse(
        JSON.stringify(this.noDepartureDates)
      );
      const minlosDateSelectedDate = this.minLosDays.find(
        (date) => date.day === this.dates[0]
      );
      if (minlosDateSelectedDate) {
        for (let i = 1; i < minlosDateSelectedDate.minLOS; i++) {
          disabledDepartureDates.push(
            format(addDays(this.dates[0], i), "YYYY-MM-DD")
          );
        }
      }
      return disabledDepartureDates;
    },
    disabledArrivalDates() {
      const noAvailableDates = JSON.parse(
        JSON.stringify(this.noAvailableDates)
      );
      const noArrivalDates = JSON.parse(JSON.stringify(this.noArrivalDates));
      let disabledArrivalDates = [
        ...new Set(noArrivalDates.concat(noAvailableDates)),
      ];
      if (this.dates.length === 2) {
        disabledArrivalDates = disabledArrivalDates.filter(
          (date) => date <= this.dates[0] || date >= this.dates[1]
        );
      }
      return disabledArrivalDates;
    },
    disabledDates() {
      let disabledDates;
      switch (this.dates.length) {
        case 1:
          if (
            this.disabledDepartureDates.some((date) => date === this.dates[0])
          ) {
            disabledDates = this.disabledDepartureDates.filter(
              (date) => date !== this.dates[0]
            );
          } else {
            disabledDates = this.disabledDepartureDates;
          }
          break;
        case 2:
          if (
            this.disabledArrivalDates.some((date) => date === this.dates[1])
          ) {
            disabledDates = this.disabledArrivalDates.filter(
              (date) => date !== this.dates[1]
            );
          } else {
            disabledDates = this.disabledArrivalDates;
          }
          break;
        default:
          disabledDates = this.disabledArrivalDates;
          break;
      }
      return disabledDates;
    },
  },
  watch: {
    minDate(val) {
      if (val === this.maxDate) {
        this.$nextTick(() => {
          this.dates = [];
        });
      }
    },
  },
  methods: {
    getAvailableDates(month) {
      const monthWasLoaded = this.loadedCalendarMonths.includes(month);
      if (!monthWasLoaded) {
        this.loadingCalendarData = true;
        const url = process.env.VUE_APP_IBE_URL + "/ibe/calendar ";
        const startdate = month + "-01";
        const enddate = format(addDays(startdate, 33), "YYYY-MM-DD");
        const data = {
          Environment: this.hotel.environment,
          HotelCode: this.hotel.code,
          Segment: this.segment,
          StayDateRange: {
            StartDate: startdate,
            EndDate: enddate,
          },
        };
        axiosPost(url, data, this.token)
          .then((response) => {
            if (response.success) {
              this.handleGetAvailableDatesResponse(response.availability.days);
              this.loadedCalendarMonths.push(month);
            } else {
              this.snackbarError(this.$t("undefinedError"));
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarError(this.$t("undefinedError"));
          })
          .finally(() => {
            this.loadingCalendarData = false;
          });
      }
    },
    handleGetAvailableDatesResponse(datesData) {
      const noAvailableDates = datesData
        .filter((day) => day.available === false)
        .map((date) => format(date.day, "YYYY-MM-DD"));
      this.noAvailableDates = [
        ...new Set(this.noAvailableDates.concat(noAvailableDates)),
      ];
      const noArrivalDates = datesData
        .filter((day) => day.noArrival === true)
        .map((date) => format(date.day, "YYYY-MM-DD"));
      this.noArrivalDates = [
        ...new Set(this.noArrivalDates.concat(noArrivalDates)),
      ];
      const noDepartureDates = datesData
        .filter((day) => day.noDeparture === true)
        .map((date) => format(date.day, "YYYY-MM-DD"));
      this.noDepartureDates = [
        ...new Set(this.noDepartureDates.concat(noDepartureDates)),
      ];
      const minLosDays = datesData
        .filter((day) => day.minLOS > 1)
        .map((date) => {
          return { day: format(date.day, "YYYY-MM-DD"), minLOS: date.minLOS };
        });
      this.minLosDays = [...new Set(this.minLosDays.concat(minLosDays))];
      const maxLosDays = datesData
        .filter((day) => day.maxLOS > 1)
        .map((date) => {
          return { day: format(date.day, "YYYY-MM-DD"), maxLOS: date.maxLOS };
        });
      this.maxLosDays = [...new Set(this.maxLosDays.concat(maxLosDays))];
    },
  },
};
