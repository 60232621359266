const dialogsData = [
  {
    id: "imagesCarousel",
    ref: "imagesCarousel",
    width: "700",
    includeActions: false,
    content: "images-carousel",
    show: true,
  },
  {
    id: "searchCriteria",
    ref: "searchCriteria",
    titleKey: "searchCriteria",
    width: "320",
    includeActions: true,
    content: "search-criteria-dialog",
    show: true,
  },
  {
    id: "bookingSummary",
    ref: "bookingSummary",
    titleKey: "bookingSummary",
    width: "320",
    includeActions: false,
    content: "booking-summary-dialog",
    show: true,
  },
  {
    id: "confirmBooking",
    ref: "confirmBooking",
    titleKey: "checkConfirmBooking",
    width: "400",
    includeActions: true,
    content: "booking-confirmation-dialog",
    show: true,
  },
  {
    id: "loginDialog",
    ref: "loginDialog",
    titleKey: "login",
    width: "400",
    includeActions: true,
    content: "login-dialog",
    show: true,
  },
];

export { dialogsData };
