<template>
  <div
    class="flex-grow-1 mr-2 mr-lg-4"
    :class="{
      'not-mobile': $vuetify.breakpoint.smAndUp,
    }"
  >
    <v-btn
      rounded
      color="primary"
      class="mb-2"
      @click.stop="$emit('back-to-overview')"
    >
      <v-icon large left>$arrow_left</v-icon>
      {{ $t("toOverview") }}
    </v-btn>
    <ibe-package
      :ibe-package="ibePackage"
      :package-rooms-view="true"
      @show-package-images="showRoomImages"
    ></ibe-package>

    <div class="d-flex justify-space-between align-center">
      <h2 class="text-h5 mb-4">
        {{ $t("selectYourRoom") }}
        <room-to-select-info
          v-if="roomsGuests.length > 1"
        ></room-to-select-info>
      </h2>
      <div class="switch-wrapper d-none d-md-flex">
        <v-btn text small @click="onlyShowAvailableRooms = false">
          {{ $t("all") }}</v-btn
        >

        <v-switch
          v-model="onlyShowAvailableRooms"
          color="primary"
          class="mt-0"
          hide-details
        >
        </v-switch>
        <v-btn text small @click="onlyShowAvailableRooms = true">
          {{ $t("available") }}</v-btn
        >
      </div>
    </div>
    <div
      class="selectable-rooms-wrapper"
      :class="{
        'not-mobile': $vuetify.breakpoint.smAndUp,
      }"
    >
      <p v-if="visibleRooms.length === 0">
        {{
          onlyShowAvailableRooms
            ? $t("noAvailableRoomsFound")
            : $t("noRoomsFound")
        }}
      </p>
      <ibe-package-room
        v-for="room in visibleRooms"
        :key="room.roomCode"
        :room="room"
        :package-min-los="ibePackage.minLos"
        @show-room-images="showRoomImages"
        @select-package-room="selectPackageRoom"
      ></ibe-package-room>
    </div>
    <default-dialog
      v-if="selectedDialog"
      :show-dialog="selectedDialog.show"
      :width="selectedDialog.width"
      :title="selectedRoom.name"
      :include-actions="selectedDialog.includeActions"
      @close-dialog="closeDialog"
    >
      <template #dialog-content>
        <component
          :is="selectedDialog.content"
          :ref="selectedDialog.ref"
          :show-dialog="selectedDialog.show"
          :images="selectedRoom.images"
          @close-dialog="closeDialog"
        ></component>
      </template>
    </default-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { format, addDays } from "date-fns";
import dialogs from "@/mixins/dialogs";
import isMobile from "@/mixins/isMobile";
import IbeRoom from "@/components/IbeRoom";
import RoomToSelectInfo from "@/components/RoomToSelectInfo";
import IbePackage from "@/components/IbePackage";
import IbePackageRoom from "@/components/IbePackageRoom";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import ImagesCarousel from "@/components/dialogs/ImagesCarousel";

export default {
  name: "PackageRoomsView",
  components: {
    IbeRoom,
    IbePackage,
    IbePackageRoom,
    DefaultDialog,
    ImagesCarousel,
    RoomToSelectInfo,
  },
  mixins: [isMobile, dialogs],
  props: {
    ibePackage: {
      type: Object,
      required: true,
    },
    defaultShowAllRooms: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    onlyShowAvailableRooms: true,
    selectedRoom: null,
  }),
  computed: {
    visibleRooms() {
      let rooms = this.ibePackage.rooms;
      if (this.onlyShowAvailableRooms) {
        rooms = rooms.filter((room) => room.isAvailable);
      }
      return rooms;
    },
    ...mapState([
      "hotel",
      "roomsGuests",
      "roomBookings",
      "roomToSelect",
      "stayDates",
      "nights",
    ]),
  },
  watch: {
    onlyShowAvailableRooms(val) {
      this.$emit("update:show-allRooms", !val);
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.onlyShowAvailableRooms = !this.defaultShowAllRooms;
  },
  methods: {
    showRoomImages(data) {
      this.selectedRoom = data;
      this.showDialog("imagesCarousel");
    },
    selectPackageRoom(room) {
      if (this.ibePackage.minLos !== this.nights) {
        const stayDates = this.stayDates;
        (stayDates.departureDate = format(
          addDays(stayDates.arrivalDate, this.ibePackage.minLos),
          "YYYY-MM-DD"
        )),
          this.setStayDates(stayDates);
        this.setNights(this.ibePackage.minLos);
      }
      const ibePackage = JSON.parse(JSON.stringify(this.ibePackage));
      const roomBooking = {
        room: {
          name: room.name,
          code: room.roomData.roomCode,
          roomData: room.roomData.roomData,
          image: room.roomData.images.find((image) => image.imageType === 1),
        },
        rate: {
          rateData: ibePackage.rateData,
          rates: room.roomRates,
          total: room.total,
          nettTotal: room.nettTotal,
          code: ibePackage.rateCode,
          inclusions: ibePackage.inclusions,
          paymentMethod: ibePackage.paymentMethod,
        },
        services: [],
        availableServices: room.availableServices,
      };
      roomBooking.id = JSON.parse(JSON.stringify(this.roomToSelect.id));
      roomBooking.selectedRoom = JSON.parse(JSON.stringify(this.roomToSelect));
      const roomBookings = JSON.parse(JSON.stringify(this.roomBookings));
      roomBookings.push(roomBooking);
      this.setRoomBookings(roomBookings.sort((a, b) => a.id - b.id));
      if (this.$gtm && this.$gtm.enabled()) {
        const dataLayerEvent = {
          event: "add_to_cart",
          currency: this.hotel.currencySign,
          value: roomBooking.rate.total,
          items: [
            {
              arrivalDate: this.stayDates.arrivalDate,
              quantity: this.nights,
              item_category: roomBooking.rate.code,
              item_id: roomBooking.room.code,
              item_name: roomBooking.room.name,
              price: roomBooking.rate.total,
            },
          ],
        };
        window.dataLayer?.push(JSON.parse(JSON.stringify(dataLayerEvent)));
      }
    },
    ...mapActions(["setRoomBookings", "setNights", "setStayDates"]),
  },
};
</script>
