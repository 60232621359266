<template>
  <v-text-field
    ref="textInput"
    v-bind="$attrs"
    :validate-on-blur="validateOnBlur"
    :outlined="outlined"
    :dense="dense"
    autocomplete="off"
    background-color="white"
    color="black"
    v-on="$listeners"
  >
    <template #message="{ message }">
      {{ $t(message) }}
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "DefaultTextField",
  inheritAttrs: false,
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
