<template>
  <div
    class="rooms-wrapper flex-grow-1 mr-2 mr-lg-4"
    :class="{
      'not-mobile': $vuetify.breakpoint.smAndUp,
    }"
  >
    <div class="d-flex align-center flex-wrap">
      <h2 class="text-h5 mr-4">{{ $t("selectOptionsTitle") }}</h2>
      <default-select
        v-if="roomBookings.length > 1"
        v-model="selectedRoom"
        :items="selectableRooms"
        item-text="name"
        item-value="id"
        hide-details
        class="options-room-select"
      >
      </default-select>
      <div class="d-flex justify-end ml-auto flex-wrap">
        <v-btn
          v-if="showToNextRoomOptionsButton"
          rounded
          :small="isMobile"
          color="primary"
          class="align-self-end ma-2"
          @click="selectedRoom = nextRoomId"
        >
          {{ $t("nextRoom") }}
          <v-icon :large="!isMobile" right>$arrow_right</v-icon>
        </v-btn>
        <v-btn
          rounded
          :small="isMobile"
          color="primary"
          class="align-self-end my-2 ml-auto"
          @click="setStep(3)"
        >
          {{ $t("book") }}
          <v-icon :large="!isMobile" right>$arrow_right</v-icon>
        </v-btn>
      </div>
    </div>
    <p v-if="availableServices.length === 0">No bookable option available.</p>
    <ibe-option
      v-for="option in availableServices"
      :key="option.serviceCode"
      :option="option"
      :nights="nights"
      :room-booking="selectedRoomBooking"
      @select-option="addOption"
    ></ibe-option>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import isMobile from "@/mixins/isMobile";
import DefaultSelect from "@/components/formFields/DefaultSelect";
import IbeOption from "@/components/IbeOption";

export default {
  name: "SelectOptions",
  components: {
    DefaultSelect,
    IbeOption,
  },
  mixins: [isMobile],
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedRoom: null,
  }),
  computed: {
    nextRoomId() {
      let nextRoomid = 0;
      const selectedRoomIndex = this.roomBookings.findIndex(
        (room) => room.id === this.selectedRoom
      );
      if (this.roomBookings[selectedRoomIndex + 1]) {
        nextRoomid = this.roomBookings[selectedRoomIndex + 1].id;
      }
      return nextRoomid;
    },
    showToNextRoomOptionsButton() {
      return this.roomBookings.length > 1 && this.nextRoomId;
    },
    selectableRooms() {
      const selectableRooms = JSON.parse(
        JSON.stringify(this.roomBookings)
      ).sort((a, b) => a.id - b.id);
      const roomOptions = selectableRooms.map((bookedRoom, idx) => {
        return {
          name: this.getRoomName(bookedRoom, idx),
          id: bookedRoom.id,
        };
      });

      return roomOptions;
    },
    selectedRoomBooking() {
      return this.roomBookings.find((room) => room.id === this.selectedRoom);
    },
    availableServices() {
      const inclusions = this.selectedRoomBooking.rate.inclusions;
      const availableRoomServicesCodes =
        this.selectedRoomBooking.availableServices.map(
          (service) => service.serviceCode
        );
      let availableServices = this.services
        .filter(
          (service) =>
            service.rooms.length === 0 ||
            service.rooms.includes(this.selectedRoomBooking.room.code)
        )
        .filter((service) => !inclusions.includes(service.serviceCode))
        .filter((service) =>
          availableRoomServicesCodes.includes(service.serviceCode)
        )
        .sort((a, b) => a.sortOrder - b.sortOrder);
      if (this.selectedRoomBooking.selectedRoom.children === 0) {
        availableServices = availableServices.filter(
          (service) => ![3, 4].includes(service.chargeType)
        );
      }
      if (this.selectedRoomBooking.selectedRoom.infants === 0) {
        availableServices = availableServices.filter(
          (service) => ![17, 18].includes(service.chargeType)
        );
      }

      return availableServices;
    },
    ...mapState(["hotel", "roomBookings", "nights"]),
    ...mapState("language", ["language"]),
  },
  created() {
    this.selectedRoom = this.roomBookings[0].id;
  },
  methods: {
    addOption(option) {
      const selectedOption = JSON.parse(JSON.stringify(option));
      selectedOption.total = this.calculateTotal(option);
      const currentRoomBookings = JSON.parse(JSON.stringify(this.roomBookings));
      const currentRoomIndex = currentRoomBookings.findIndex(
        (room) => room.id === this.selectedRoom
      );
      const currentRoomServices =
        currentRoomBookings[currentRoomIndex].services;
      const existingOptionIndex = currentRoomServices.findIndex(
        (existingOption) => existingOption.serviceCode === option.serviceCode
      );
      if (existingOptionIndex !== -1) {
        currentRoomServices.splice(existingOptionIndex, 1, selectedOption);
      } else {
        currentRoomServices.push(selectedOption);
      }
      this.setRoomBookings(currentRoomBookings);
    },
    calculateTotal(option) {
      let factor = 1;
      const price = option.price,
        chargeType = option.chargeType,
        adults = this.selectedRoomBooking.selectedRoom.adults,
        nights = this.nights,
        children = this.selectedRoomBooking.selectedRoom.children,
        infants = this.selectedRoomBooking.selectedRoom.infants,
        units = option.units,
        selectedDates = option.range;
      switch (chargeType) {
        case 1: // perAdultPerNight,
          factor = adults * nights;
          break;
        case 2: // perAdultPerStay,
          factor = adults;
          break;
        case 3: // perChildPerNight,
          factor = children * nights;
          break;
        case 4: // perChildPerStay,
          factor = children;
          break;
        case 5: // perNight,
          factor = nights;
          break;
        case 6: // perUnit,
          factor = units;
          break;
        case 7: // perUnitPerAdult,
          factor = units * adults;
          break;
        case 8: // perUnitPerAdultPerNight,
          factor = units * adults * nights;
          break;
        case 9: // perUnitPerNight,
          factor = units * nights;
          break;
        case 17: // perInfantPerNight,
          factor = infants * nights;
          break;
        case 18: // perInfantPerStay,
          factor = infants;
          break;
        case 26: // PerPersonPerNight,
          factor = (adults + children + infants) * nights;
          break;
        case 27: // PerPersonPerStay,
          factor = adults + children + infants;
          break;
        case 14: // selectedDatesPerStay,
          factor = selectedDates.length;
          break;
        case 15: // selectedDatesPerPerson,
        case 16: // selectedDatesPerPerson,
        case 20: // selectedDatesPerPerson,
        case 29: // selectedDatesPerPerson,
          factor = selectedDates
            .map((date) => parseInt(date.units))
            .reduce((prev, curr) => prev + curr);
          break;
      }
      return factor * price;
    },
    getRoomName(bookedRoom, idx) {
      let name = "Undefined";
      const locale = bookedRoom.room.roomData.find(
        (lang) => lang.language === this.language.locale
      );
      if (locale && locale.name !== "") {
        name = locale.name;
      }
      const roomAdultsText =
        bookedRoom.selectedRoom.adults > 1
          ? this.hotel.ageBuckets.length
            ? this.$t("numberOfAdults").format(bookedRoom.selectedRoom.adults)
            : this.$t("numberOfPersons").format(bookedRoom.selectedRoom.adults)
          : this.hotel.ageBuckets.length
          ? this.$t("1adult")
          : this.$t("1person");
      let roomChildrenText = "";
      if (bookedRoom.selectedRoom.children === 1) {
        roomChildrenText = this.$t("1child");
      }
      if (bookedRoom.selectedRoom.children > 1) {
        roomChildrenText = this.$t("numberOfChildren").format(
          bookedRoom.selectedRoom.children
        );
      }
      let roomInfantsText = "";
      if (bookedRoom.selectedRoom.infants === 1) {
        roomInfantsText = this.$t("1infant");
      }
      if (bookedRoom.selectedRoom.infants > 1) {
        roomInfantsText = this.$t("numberOfInfants").format(
          bookedRoom.selectedRoom.infants
        );
      }
      let roomGuestsText = roomAdultsText;
      if (roomChildrenText) {
        roomGuestsText += ", " + roomChildrenText;
      }
      if (roomInfantsText) {
        roomGuestsText += ", " + roomInfantsText;
      }

      return idx + 1 + ": " + name + " (" + roomGuestsText + ")";
    },
    ...mapActions(["setStep", "setRoomBookings"]),
  },
};
</script>
