<template>
  <div
    :class="{
      mobile: isMobile,
    }"
    class="room-rates d-flex flex-sm-column justify-sm-center align-center"
  >
    <div class="d-flex flex-column justify-center align-center pr-sm-0">
      <div v-if="!isMobile" class="occupancy-wrapper">
        <v-icon
          v-for="i in persons"
          :key="i + 1"
          class="mx-n2"
          :size="26"
          color="black"
          >$man</v-icon
        >
      </div>
      <div class="best-roomrate d-flex flex-column my-1 mr-6 mx-sm-0">
        <div class="best-roomrate-amount">
          <span class="currency-sign mx-2">{{ currency }}</span>
          <span class="text-h5 text-sm-h4">{{
            getFormattedAmount(amount).units
          }}</span
          ><span class="ml-n1"> {{ getFormattedAmount(amount).cents }}</span>
        </div>
        <span class="text-caption ml-auto mt-n2">
          {{
            nights > 1
              ? nights + " " + $t("nights_plur")
              : nights + " " + $t("night")
          }}
        </span>
      </div>
    </div>
    <v-btn
      v-if="showCancelButton"
      class="align-self-sm-center"
      color="error"
      small
      @click="cancelReservation"
    >
      <v-icon v-if="!isMobile" left>$close</v-icon>
      {{ $t("cancelBookingButton") }}
    </v-btn>
  </div>
</template>

<script>
import amounts from "@/mixins/amounts";
import isMobile from "@/mixins/isMobile";

export default {
  name: "UserBookingDetails",
  mixins: [isMobile, amounts],
  props: {
    amount: {
      type: Number,
      required: true,
    },
    nights: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    persons: {
      type: Number,
      required: true,
    },
    showCancelButton: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    cancelReservation() {
      this.$root
        .$confirm(
          this.$t("cancelBooking"),
          this.$t("cancelBookingConfirmMessage")
        )
        .then((confirmResult) => {
          if (confirmResult) {
            this.$emit("cancel-reservation");
          }
        });
    },
  },
};
</script>
