<template>
  <div
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
    }"
    class="room-rates d-flex flex-column flex-sm-row justify-sm-end flex-md-column justify-md-center align-center"
  >
    <div class="d-flex flex-column justify-center align-center pr-sm-8 pr-md-0">
      <div class="occupancy-wrapper">
        <v-icon
          v-for="i in room.maxOccupancy"
          :key="i + 1"
          class="mx-n2"
          :size="26"
          :color="i <= occupancy ? 'black' : null"
          >$man</v-icon
        >
      </div>

      <div
        v-if="room.isAvailable"
        class="best-roomrate d-flex flex-column my-1"
      >
        <span class="text-caption">
          {{
            minLos > 1
              ? minLos + " " + $t("nights_plur")
              : minLos + " " + $t("night")
          }}
        </span>
      </div>
    </div>
    <v-btn
      v-if="room.isAvailable"
      rounded
      color="primary"
      @click.stop="$emit('select-package-room')"
    >
      <span class="currency-sign mr-2">{{ hotel.currencySign }}</span>
      <span class="text-h6">{{ getFormattedAmount(room.total).units }}</span
      ><span> {{ getFormattedAmount(room.total).cents }}</span>
      <v-icon large right>$arrow_right</v-icon>
    </v-btn>
    <v-btn v-else class="mt-2" disabled>{{ $t("notAvailable") }} </v-btn>
  </div>
</template>

<script>
import amounts from "@/mixins/amounts";
import { mapState } from "vuex";

export default {
  name: "PackageRoomSelectSection",
  mixins: [amounts],
  props: {
    room: {
      type: Object,
      required: true,
    },
    occupancy: {
      type: Number,
      required: true,
    },
    minLos: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(["hotel", "nights"]),
  },
};
</script>
