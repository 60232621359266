<template>
  <div class="card-text-card-actions-wrapper d-flex flex-column">
    <v-card-text class="px-3 px-sm-6">
      <dl class="grid-dl text-h6">
        <dt class="grey--text">{{ $t("arrival") }}</dt>
        <dd>
          {{ arrivalDate }}
        </dd>
        <dt class="grey--text">{{ $t("nights") }}</dt>
        <dd>
          {{ nights }}
        </dd>
        <dt class="grey--text">{{ $t("name") }}</dt>
        <dd>
          {{ bookerName }}
        </dd>
        <dt class="grey--text">{{ $t("email") }}</dt>
        <dd>
          {{ bookerDetails.email }}
        </dd>
        <dt class="grey--text">{{ $t("rooms") }}</dt>
        <dd>
          {{ roomBookings.length }}
        </dd>
        <dt class="grey--text">{{ $t("amount") }}</dt>
        <dd>
          <summary-data-amount :amount="total"></summary-data-amount>
        </dd>
      </dl>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="d-flex justify-space-between px-6 py-3">
      <v-btn color="secundairy" @click="$emit('close-dialog')">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn color="primary" @click="confirmBooking">
        {{ $t("confirm") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SummaryDataAmount from "@/components/SummaryDataAmount";

export default {
  name: "BookingConfirmationDialog",
  components: {
    SummaryDataAmount,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    bookerDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bookerName() {
      return `${this.bookerDetails.firstName} ${this.bookerDetails.surName}`;
    },
    arrivalDate() {
      if (!(this.stayDates && this.language)) {
        return "";
      }
      const formatter = new Intl.DateTimeFormat(this.datePickerLocale, {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return formatter.format(
        new Date(this.stayDates.arrivalDate + "T00:00:00")
      );
    },
    datePickerLocale() {
      if (["nn-NO", "nb-NO"].includes(this.language.locale)) {
        return "no-NO";
      }
      return this.language.locale;
    },
    ...mapState(["nights", "roomBookings", "stayDates", "total"]),
    ...mapState("language", ["language"]),
  },
  methods: {
    confirmBooking() {
      this.$emit("confirm-booking");
      this.$emit("close-dialog");
    },
  },
};
</script>
