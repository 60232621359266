<template>
  <v-card flat rounded width="100%" max-width="400px">
    <v-card-text>
      <v-alert
        class="message-wrapper error-message"
        transition="scale-transition"
        icon="$alert"
      >
        <p class="mb-0">
          {{ $t("loadingDataFailed") }}
        </p>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "LoadingDataError",
};
</script>
