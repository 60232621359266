export default {
  methods: {
    snackbarInvalidForm() {
      this.$root.$snackbar(this.$t("invalidFormInputsMessage"), {
        color: "error",
        icon: "$snackbar_error",
      });
    },
    snackbarInvalidToken() {
      this.$root.$snackbar(this.$t("securityTokenInvalidMessage"), {
        color: "error",
        icon: "$snackbar_error",
      });
    },
    snackbarSuccess(message) {
      this.$root.$snackbar(message, {
        color: "success",
        icon: "$snackbar_success",
      });
    },
    snackbarError(message) {
      this.$root.$snackbar(message, {
        color: "error",
        icon: "$snackbar_error",
      });
    },
  },
};
