<template>
  <v-list-item class="pr-0">
    <v-list-item-content
      class="py-3"
      :class="{ 'pt-9': rate.staticRateData.rateDetails.rateLabel > 0 }"
    >
      <div class="d-md-flex justify-space-between">
        <div>
          <h3 class="mb-2">
            <span class="mr-2 notranslate"> {{ rateName }}</span>
            <text-popover v-if="ratePolicy" :text="ratePolicy"></text-popover>
          </h3>
          <p class="px-6 show-line-breaks">
            {{ rateDescription }}
          </p>
        </div>
        <div
          class="align-self-end d-flex justify-end align-end"
          :class="{
            'rate-rate-amount': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <span class="text-caption mr-2 mt-2">
            {{
              nights > 1
                ? nights + " " + $t("nights_plur")
                : nights + " " + $t("night")
            }}</span
          >
          <div class="d-flex flex-column">
            <span
              v-if="rate.discount"
              class="discount-message mb-2 text-subtitle-1"
            >
              {{ $t("yourDiscount") }}
              <summary-data-amount
                :amount="rate.discount"
              ></summary-data-amount>
            </span>
            <v-btn
              rounded
              color="primary"
              @click.stop="$emit('select-room-rate', rate)"
            >
              <span class="currency-sign mr-2">{{ hotel.currencySign }}</span>
              <span class="text-h6">{{
                getFormattedAmount(rate.total).units
              }}</span
              ><span> {{ getFormattedAmount(rate.total).cents }}</span>
              <v-icon large right>$arrow_right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <v-divider v-if="!lastItem" class="mt-3"></v-divider>
    </v-list-item-content>
    <div
      v-if="rate.staticRateData.rateDetails.rateLabel"
      class="rate-type-label"
    >
      <span>{{
        $t(getRateTypeLabel(rate.staticRateData.rateDetails.rateLabel))
      }}</span>
    </div>
  </v-list-item>
</template>

<script>
import { mapState } from "vuex";
import amounts from "@/mixins/amounts";
import localizedText from "@/mixins/localizedText";
import TextPopover from "@/components/globals/TextPopover";
import SummaryDataAmount from "@/components/SummaryDataAmount";
import { rateLabelTypes } from "@/static/staticData";

export default {
  name: "RoomRate",
  components: {
    TextPopover,
    SummaryDataAmount,
  },
  mixins: [amounts, localizedText],
  props: {
    rate: {
      type: Object,
      required: true,
    },
    lastItem: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    rateName() {
      return this.getLocalizedText(this.rate.staticRateData.rateData, "name");
    },
    ratePolicy() {
      return this.getLocalizedText(this.rate.staticRateData.rateData, "policy");
    },
    rateDescription() {
      return this.getLocalizedText(
        this.rate.staticRateData.rateData,
        "description"
      );
    },
    ...mapState(["hotel", "nights"]),
  },
  methods: {
    getRateTypeLabel(id) {
      let labelKey = "";
      const rateLabel = rateLabelTypes.find((label) => label.id === id);
      if (rateLabel) {
        labelKey = rateLabel.key;
      }
      return labelKey;
    },
  },
};
</script>
