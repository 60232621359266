<template>
  <div>
    <h2 class="text-h5 mb-4">{{ $t("thankYouTitle") }}</h2>
    <template v-if="thankYouText">
      <p class="show-line-breaks">{{ thankYouText }}</p>
    </template>
    <template v-else>
      <p>{{ $t("thankYouP1") }}</p>
      <p>{{ $t("thankYouP2") }}</p>
      <p>{{ $t("thankYouP3") }}</p>
      <p>{{ hotel.name }}</p>
    </template>

    <v-btn
      v-if="!hideCreateNewBookingButton"
      color="primary"
      @click="$emit('create-new-booking')"
      >{{ $t("createNewBooking") }}</v-btn
    >
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BookingFinished",
  computed: {
    hideCreateNewBookingButton() {
      return !!this.$route.query.redirectResult;
    },
    thankYouText() {
      let thankYouText = "";
      const thankYouTextMatch = this.hotel.uiSettings.thankYou.find(
        (lang) => lang.language === this.language.locale
      );
      if (thankYouTextMatch) {
        thankYouText = thankYouTextMatch.description;
      }
      return thankYouText;
    },
    ...mapState(["hotel"]),
    ...mapState("language", ["language"]),
  },
};
</script>
