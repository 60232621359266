<template>
  <div class="rooms-wrapper flex-grow-1 mr-sm-4">
    <v-form ref="detailsForm" @submit.prevent>
      <h2 class="text-h5 mb-4">{{ $t("yourDetails") }}</h2>
      <booker-details
        ref="bookerDetails"
        @update-booker-country-code="bookerCountryCode = $event"
      ></booker-details>
      <h2 class="text-h5 mb-4">{{ $t("guestDetails") }}</h2>
      <guest-details
        v-for="roomBooking in roomBookings"
        ref="guestDetails"
        :key="roomBooking.id"
        :room-booking="roomBooking"
        :booker-is-guest-room="bookerIsGuestRoom"
        :booker-country-code="bookerCountryCode"
        @update:booker-is-guest-room="bookerIsGuestRoom = $event"
      ></guest-details>
    </v-form>
    <template v-if="showPaymentDetails">
      <h2 class="text-h5 mb-4">{{ $t("payMentDetails") }}</h2>
      <booker-payment-details
        ref="payMentDetails"
        :show-pay-later-option="showPayLaterOption"
      ></booker-payment-details>
    </template>
    <div
      class="d-flex flex-column flex-md-row-reverse flex-md-row-reverse justify-md-end align-md-center"
    >
      <v-checkbox
        v-if="showAgreeCheckbox"
        v-model="agreeToTerms"
        class="mt-0 pt-0 ml-3"
        color="black"
        hide-details
      >
        <template #label>
          <span>
            {{ $t("agreeTo") }}
            <a
              v-if="hotel.termsUrl"
              target="_blank"
              class="mx-1"
              :href="hotel.termsUrl"
              @click.stop
            >
              {{ $t("termsConditions") }}
            </a>
            <span v-if="hotel.termsUrl && hotel.privacyUrl">&amp;</span>
            <a
              v-if="hotel.privacyUrl"
              target="_blank"
              class="mx-1"
              :href="hotel.privacyUrl"
              @click.stop
            >
              {{ $t("privacyPolicy") }}
            </a>
          </span>
        </template>
      </v-checkbox>
      <div class="ma-4">
        <v-btn
          color="primary"
          :disabled="disableBookButton"
          @click="finishBooking"
          >{{ $t("book") }}
        </v-btn>
      </div>
    </div>

    <default-dialog
      v-if="selectedDialog"
      :show-dialog="selectedDialog.show"
      :width="selectedDialog.width"
      :title="$t(selectedDialog.titleKey)"
      :include-actions="selectedDialog.includeActions"
      @close-dialog="closeDialog"
    >
      <template #dialog-content>
        <component
          :is="selectedDialog.content"
          :ref="selectedDialog.ref"
          :show-dialog="selectedDialog.show"
          :booker-details="$refs.bookerDetails.bookerDetails"
          @close-dialog="closeDialog"
          @confirm-booking="postBooking"
        ></component>
      </template>
    </default-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { axiosPost } from "@/api/requests";
import dialogs from "@/mixins/dialogs";
import isMobile from "@/mixins/isMobile";
import snackBarMessages from "@/mixins/snackBarMessages";
import BookerDetails from "@/components/BookerDetails";
import GuestDetails from "@/components/GuestDetails";
import BookerPaymentDetails from "@/components/BookerPaymentDetails";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import BookingConfirmationDialog from "@/components/dialogs/BookingConfirmationDialog";
import localizedText from "@/mixins/localizedText";

export default {
  name: "BookerGuestsDetails",
  components: {
    BookerDetails,
    GuestDetails,
    BookerPaymentDetails,
    DefaultDialog,
    BookingConfirmationDialog,
  },
  mixins: [snackBarMessages, dialogs, isMobile, localizedText],
  data: () => ({
    bookerIsGuestRoom: 0,
    bookerCountryCode: "",
    agreeToTerms: false,
    finalBooking: null,
  }),
  computed: {
    showAgreeCheckbox() {
      return !!this.hotel.termsUrl || !!this.hotel.privacyUrl;
    },
    disableBookButton() {
      if (!this.showAgreeCheckbox) {
        return false;
      }
      return !this.agreeToTerms;
    },
    mandatoryBookingServices() {
      return this.additionalServices.filter(
        (service) => service.applicationType === 4
      );
    },
    mandatoryRoomServices() {
      return this.additionalServices.filter(
        (service) => service.applicationType === 3
      );
    },
    showBookingConfirmationSetting() {
      return this.hotel.settings.some(
        (setting) => setting.settingType === 8 && setting.settingValue === 1
      );
    },
    showPayLaterOption() {
      return this.roomBookings.some(
        (booking) => booking.rate.paymentMethod.paymentType === 5
      );
    },
    showPayInHotelOption() {
      return this.roomBookings.some(
        (booking) => booking.rate.paymentMethod.paymentType === 6
      );
    },
    showPaymentDetails() {
      return this.showPayLaterOption || this.showPayInHotelOption;
    },
    ...mapState([
      "hotel",
      "roomBookings",
      "promoCode",
      "token",
      "segment",
      "additionalServices",
      "stayDates",
      "nights",
      "total",
      "dueNow",
    ]),
    ...mapState("language", ["language"]),
  },
  watch: {
    roomBookings(val) {
      if (val && val.length) {
        if (!this.bookerIsGuestRoom) {
          this.bookerIsGuestRoom = val[0].id;
        }
      }
    },
  },
  methods: {
    finishBooking() {
      if (this.$refs.detailsForm.validate()) {
        this.finalBooking = this.createFinalBooking();
        if (this.showBookingConfirmationSetting || this.isMobile) {
          this.showDialog("confirmBooking");
        } else {
          this.postBooking();
        }
      } else {
        this.snackbarInvalidForm();
      }
    },
    createFinalBooking() {
      const roomBookings = JSON.parse(JSON.stringify(this.roomBookings));

      roomBookings.forEach((booking, idx) => {
        if (this.$refs.guestDetails[idx].bookerIsGuest) {
          booking.guestDetails = JSON.parse(
            JSON.stringify(this.$refs.bookerDetails.bookerDetails)
          );
          ["comments", "countryCode", "address", "postalCode", "city"].forEach(
            (key) => delete booking.guestDetails[key]
          );
          booking.guestDetails.childAges =
            this.$refs.guestDetails[idx].guestDetails.childAges;
        } else {
          booking.guestDetails = this.$refs.guestDetails[idx].guestDetails;
        }
      });

      const roomStays = roomBookings.map((room) => {
        return {
          roomCode: room.room.code,
          adults: room.selectedRoom.adults,
          children: room.selectedRoom.children,
          infants: room.selectedRoom.infants,
          rate: {
            rateCode: room.rate.code,
            total: room.rate.total,
            rates: room.rate.rates,
          },
          guestDetails: room.guestDetails,
          services: this.createRoomStayServices(room),
        };
      });
      const additionalBookingServices = this.mandatoryBookingServices.map(
        (service) => {
          return {
            serviceCode: service.serviceCode,
            units: 1,
            price: service.price,
          };
        }
      );
      const finalBooking = {
        paymentMethod: this.showPaymentDetails
          ? this.$refs.payMentDetails.paymentMethod
          : 0,
        promoCode: this.promoCode,
        language: this.language.locale.substring(0, 2),
        total: this.total,
        dueNow: this.dueNow,
        arrivalDate: this.stayDates.arrivalDate,
        departureDate: this.stayDates.departureDate,
        bookerDetails: JSON.parse(
          JSON.stringify(this.$refs.bookerDetails.bookerDetails)
        ),
        roomStays: roomStays,
        services: additionalBookingServices,
      };
      if (this.$refs.bookerDetails.businessBooking) {
        finalBooking.bookerDetails.comments =
          "Company name: " +
          this.$refs.bookerDetails.companyName +
          "; " +
          finalBooking.bookerDetails.comments;
      }
      if (this.$refs.bookerDetails.eta) {
        finalBooking.bookerDetails.comments =
          "ETA (24h): " +
          this.$refs.bookerDetails.eta +
          "; " +
          finalBooking.bookerDetails.comments;
      }

      return finalBooking;
    },
    createRoomStayServices(room) {
      let roomstayServices = room.services.map((service) => {
        const range = service.range;
        range.forEach((item) => {
          ["date", "time", "selected"].forEach((prop) => {
            delete item[prop];
          });
        });
        return {
          serviceCode: service.serviceCode,
          units: service.units,
          price: service.price,
          range: range,
        };
      });
      let mandatoryRoomStayServices = [];
      const mandatoryRoomServices = JSON.parse(
        JSON.stringify(this.mandatoryRoomServices)
      );
      mandatoryRoomServices
        .filter(
          (service) =>
            service.rooms.length === 0 || service.rooms.includes(room.room.code)
        )
        .forEach((service) => {
          const availableService = room.availableServices.find(
            (availableService) =>
              availableService.serviceCode === service.serviceCode
          );
          if (availableService) {
            service.price = availableService.price;
            mandatoryRoomStayServices.push(service);
          }
        });
      mandatoryRoomStayServices = mandatoryRoomStayServices.map((service) => {
        return {
          serviceCode: service.serviceCode,
          units: 1,
          price: service.price,
        };
      });
      return roomstayServices.concat(mandatoryRoomStayServices);
    },
    postBooking() {
      this.$root.$loader.open(this.$t("processingBooking"));
      const booking = JSON.parse(JSON.stringify(this.finalBooking));
      let navigatorString = "";
      if (this.showBookingConfirmationSetting) {
        const _navigator = {};
        for (var i in navigator) {
          _navigator[i] = navigator[i];
        }
        navigatorString = JSON.stringify(_navigator);
      }
      const url = process.env.VUE_APP_IBE_URL + "/ibe/book";
      const data = {
        Environment: this.hotel.environment,
        HotelCode: this.hotel.code,
        Segment: this.segment,
        Booking: booking,
        Navigator: navigatorString,
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          this.handlePostBookingResponse(response);
        })
        .catch((error) => {
          console.log(error);
          this.snackbarError(this.$t("undefinedError"));
        })
        .finally(() => {
          this.$root.$loader.close();
        });
    },
    handlePostBookingResponse(response) {
      if (response.success) {
        this.$emit("booking-success", response);
      } else {
        this.snackbarError(this.$t("creatingBookingFailed"));
      }
    },
  },
};
</script>
