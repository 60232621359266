<template>
  <div>
    <linear-loader v-if="showLoader" message="Loading"></linear-loader>
    <template v-else-if="hotelData">
      <app-toolbar
        :header-image="headerImage"
        :property-name="hotelData.hotelName"
      ></app-toolbar>
      <v-sheet
        color="transparent"
        width="1400"
        max-width="100%"
        class="mx-auto pa-2 pa-sm-4"
      >
        <booking-steps v-if="step < 4"></booking-steps>
        <search-criteria
          v-if="showSearchCriteria"
          ref="searchCriteria"
          :search-criteria="searchCriteria"
          :show-promo-code-input="showPromoCodeInput"
          :age-buckets="hotel.ageBuckets"
          @update:search-criteria="searchCriteria = $event"
        ></search-criteria>
        <div class="d-sm-flex">
          <template v-if="availabilityData">
            <template v-if="step === 1">
              <div class="d-flex rooms-wrapper flex-column flex-grow-1">
                <package-rooms-view
                  v-if="selectedPackage"
                  :ibe-package="selectedPackage"
                  :default-show-all-rooms="showAllRooms"
                  @update:show-allRooms="showAllRooms = $event"
                  @back-to-overview="toOverviewFromSelectedPackageView"
                ></package-rooms-view>
                <template v-else>
                  <div v-if="showRoomsFilters" class="d-flex mb-2 align-center">
                    <template v-if="showRoomsPackagesCheckboxes">
                      <v-checkbox
                        v-model="showPackages"
                        class="mt-0 pt-0"
                        color="primary"
                        hide-details
                        :ripple="false"
                        :disabled="showPackages && !showRooms"
                      >
                        <template #label>{{ $t("packages") }}</template>
                      </v-checkbox>
                      <v-checkbox
                        v-model="showRooms"
                        class="mt-0 pt-0 mx-3"
                        color="primary"
                        hide-details
                        :ripple="false"
                        :disabled="showRooms && !showPackages"
                      >
                        <template #label>{{ $t("rooms") }}</template>
                      </v-checkbox>
                    </template>
                    <default-select
                      v-if="showRoomCategoryFilter"
                      v-model="roomsCategoryFilter"
                      :items="selectableRoomCategories"
                      :translate-items="true"
                      :label="$t('roomTypeFilter')"
                      :placeholder="$t('all')"
                      persistent-placeholder
                      clearable
                      item-text="key"
                      item-value="id"
                      hide-details
                      class="room-category-filter-select"
                    >
                    </default-select>
                  </div>

                  <div v-if="showClearSelection" class="d-sm-flex align-center">
                    <p class="mb-sm-0 mr-2">{{ $t("allRoomsSelected") }}</p>
                    <v-btn
                      rounded
                      small
                      color="primary"
                      @click="clearAllSelectedRooms"
                      >{{ $t("clearSelection") }}</v-btn
                    >
                  </div>
                  <template v-else>
                    <v-btn
                      v-if="!!roomCodeFilter"
                      rounded
                      color="primary"
                      class="mb-2 align-self-start"
                      @click="roomCodeFilter = null"
                    >
                      <v-icon large left>$arrow_left</v-icon>
                      {{ $t("toOverview") }}
                    </v-btn>
                    <select-rooms
                      v-if="showRooms"
                      :rooms="rooms"
                      :room-code-filter="roomCodeFilter"
                      :rooms-category-filter="roomsCategoryFilter"
                      :default-show-all-rooms="showAllRooms"
                      :class="{
                        'order-last': showPackagesBeforeRoomsSettingIsActive,
                      }"
                      @update:show-allRooms="showAllRooms = $event"
                      @back-to-overview="toDefaultsOverViewPage"
                    ></select-rooms>
                    <select-packages
                      v-if="showsSelectPackages"
                      :packages="packages"
                      @show-package-rooms-view="showPackageRoomsView"
                    ></select-packages>
                  </template>
                </template>
              </div>
            </template>
            <select-options
              v-if="step === 2"
              :services="selectableServices"
            ></select-options>
          </template>
          <booker-guests-details
            v-show="step === 3"
            @booking-success="bookingSuccess"
          ></booker-guests-details>
          <payment-section
            v-if="step === 4"
            :payment-data="paymentData"
            :payment-methods="paymentMethods"
          >
          </payment-section>
          <vault-cc-form
            v-if="step === 5"
            @booking-finished="setStep(6)"
          ></vault-cc-form>
          <booking-finished
            v-if="step === 6"
            @create-new-booking="initPage"
          ></booking-finished>
          <template>
            <booking-summary v-if="!isMobile"></booking-summary>
            <app-footer v-else ref="appFooter"></app-footer>
          </template>
        </div>
      </v-sheet>
    </template>
    <v-container v-else-if="showLoadingError" class="justify-center d-flex">
      <loading-data-error />
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { axiosGet, axiosPost } from "@/api/requests";
import { format, addDays } from "date-fns";
import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";
import snackBarMessages from "@/mixins/snackBarMessages";
import isMobile from "@/mixins/isMobile";
import LinearLoader from "@/components/globals/LinearLoader";
import LoadingDataError from "@/components/LoadingDataError";
import BookingSummary from "@/components/BookingSummary";
import AppToolbar from "@/components/globals/AppToolbar";
import AppFooter from "@/components/globals/AppFooter";
import SearchCriteria from "@/components/SearchCriteria";
import BookingSteps from "@/components/BookingSteps";
import SelectRooms from "@/components/SelectRooms";
import SelectPackages from "@/components/SelectPackages";
import PackageRoomsView from "@/components/PackageRoomsView";
import SelectOptions from "@/components/SelectOptions";
import BookerGuestsDetails from "@/components/BookerGuestsDetails";
import PaymentSection from "@/components/PaymentSection";
import BookingFinished from "@/components/BookingFinished";
import VaultCcForm from "@/components/VaultCcForm";
import DefaultSelect from "@/components/formFields/DefaultSelect";
import { validateParameter } from "@/helpers/urlParameters";
import { initRoomsGuests } from "@/helpers/initRoomsGuests";
import { createAppStyles } from "@/helpers/createAppStyles";
import { objectsAreDifferent } from "@/helpers/objectHelpers";
import { ibeRoomCategories } from "@/static/staticData";

export default {
  name: "LandingPage",
  components: {
    LinearLoader,
    LoadingDataError,
    BookingSummary,
    AppToolbar,
    SearchCriteria,
    AppFooter,
    BookingSteps,
    SelectRooms,
    SelectPackages,
    PackageRoomsView,
    SelectOptions,
    BookerGuestsDetails,
    PaymentSection,
    BookingFinished,
    VaultCcForm,
    DefaultSelect,
  },
  mixins: [snackBarMessages, isMobile],
  data: () => ({
    showLoader: false,
    showLoadingError: false,
    hotelData: null,
    availabilityData: null,
    paymentMethods: null,
    paymentData: null,
    selectedPackage: null,
    showRooms: true,
    showPackages: true,
    roomCodeFilter: null,
    showPromoCodeInput: false,
    mobileSearchCriteriaDialogIsTriggered: false,
    roomsCategoryFilter: null,
    navigatedFromChainOverview: false,
    searchCriteria: null,
    showAllRooms: false,
  }),
  computed: {
    rooms() {
      let rooms = [];
      if (!this.hotelData || !this.availabilityData) {
        return [];
      } else {
        let availableRooms = this.availabilityData.availableRooms;
        let availableServices = this.availabilityData.availableServices;
        const availablePackages = this.availabilityData.availablePackages;
        if (this.packageData.rateCode) {
          const selectedPackage = availablePackages.find(
            (pack) =>
              pack.ratePlanCode.toLowerCase() ===
              this.packageData.rateCode.toLowerCase()
          );
          if (selectedPackage) {
            availableRooms.forEach((room) => {
              const packageRoomplan = selectedPackage.roomplans.find(
                (roomplan) => roomplan.roomCode === room.roomTypeCode
              );
              if (packageRoomplan) {
                room.rateplans.push({
                  rateCode: this.packageData.rateCode,
                  name: selectedPackage.name,
                  rates: packageRoomplan.rates,
                  isAvailable: packageRoomplan.isAvailable,
                  total: packageRoomplan.total,
                  nettTotal: packageRoomplan.nettTotal,
                });
              }
            });

            availableRooms = availableRooms.filter((room) =>
              room.rateplans.some(
                (rateplan) =>
                  rateplan.rateCode.toLowerCase() ===
                  this.packageData.rateCode.toLowerCase()
              )
            );
          }
        }
        availableRooms.forEach((availableRoom) => {
          const match = this.hotelData.rooms.find(
            (room) => availableRoom.roomTypeCode === room.roomCode
          );
          if (match) {
            match.maxOccupancy = availableRoom.roomOccupancy.maxOccupancy;
            match.invCount = availableRoom.invCount;
            match.available = availableRoom.isAvailable;
            match.bestAvailableRate = availableRoom.bestAvailableRate;
            match.availableServices = availableServices;
            let roomRates = [];
            this.hotelData.rates.forEach((rate) => {
              const ratematch = availableRoom.rateplans
                .filter((rateplan) => rateplan.isAvailable)
                .find((rateplan) => rateplan.rateCode === rate.rateCode);
              if (ratematch) {
                ratematch.staticRateData = rate;
                roomRates.push(ratematch);
              }
            });
            if (this.packageData.rateCode) {
              roomRates = roomRates.filter(
                (rate) =>
                  rate.rateCode.toLowerCase() ===
                  this.packageData.rateCode.toLowerCase()
              );
              if (roomRates.length === 0) {
                match.available = false;
              }
            }
            match.rates = roomRates.sort(
              (a, b) => a.staticRateData.sortOrder - b.staticRateData.sortOrder
            );
            rooms.push(match);
          }
        });
      }
      if (this.packageData.roomCode) {
        rooms = rooms.filter(
          (room) => room.roomCode === this.packageData.roomCode
        );
      }
      if (this.roomBookings.length > 0) {
        rooms.forEach((room) => {
          const roomSelectedInRoomBookings = this.roomBookings.filter(
            (roomBooking) => roomBooking.room.code === room.roomCode
          );
          if (roomSelectedInRoomBookings.length > 0) {
            const virtualRoomInvCount =
              room.invCount - roomSelectedInRoomBookings.length;
            if (virtualRoomInvCount < 1) {
              room.available = false;
              room.rates = [];
            }
          }
        });
      }
      return rooms.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    showRoomsFilters() {
      return (
        (this.showRoomCategoryFilter || this.showRoomsPackagesCheckboxes) &&
        !!this.roomToSelect &&
        !this.packageData.rateCode
      );
    },
    availableRoomCategories() {
      const rooms = this.rooms;
      const roomCategories = [
        ...new Set(rooms.map((room) => room.roomCategory)),
      ];
      return roomCategories;
    },
    selectableRoomCategories() {
      const selectableRoomCategories = ibeRoomCategories.filter((cat) =>
        this.availableRoomCategories.includes(cat.id)
      );
      return selectableRoomCategories;
    },
    showRoomCategoryFilter() {
      return (
        !this.isMobile &&
        this.showRooms &&
        !this.roomCodeFilter &&
        (this.availableRoomCategories.length > 1 || !!this.roomsCategoryFilter)
      );
    },
    showPackagesSettingIsActive() {
      if (!this.hotel) {
        return false;
      }
      const showPackagesSettingIsActive = this.hotel.settings.some(
        (setting) => setting.settingType === 11 && setting.settingValue === 1
      );
      return showPackagesSettingIsActive;
    },
    showRoomsPackagesCheckboxes() {
      return (
        this.showPackagesSettingIsActive &&
        !this.packageData.rateCode &&
        !this.roomCodeFilter
      );
    },
    showPackagesBeforeRoomsSettingIsActive() {
      if (!this.hotel) {
        return false;
      }
      const showPackagesBeforeRoomsSettingIsActive = this.hotel.settings.some(
        (setting) => setting.settingType === 18 && setting.settingValue === 1
      );
      return showPackagesBeforeRoomsSettingIsActive;
    },
    showsSelectPackages() {
      if (!this.availabilityData) {
        return false;
      }
      return (
        this.showPackages &&
        this.showPackagesSettingIsActive &&
        !this.packageData.rateCode &&
        !this.roomCodeFilter
      );
    },
    packages() {
      if (!this.hotelData || !this.availabilityData) {
        return [];
      }
      const availablePackages = this.availabilityData.availablePackages
        .filter((pack) => pack.isAvailable)
        .map((pack) => {
          const ibePackageHotelRate = this.hotelData.rates.find(
            (rate) => rate.rateCode === pack.ratePlanCode
          );
          return {
            rateCode: pack.ratePlanCode,
            rateData: ibePackageHotelRate.rateData,
            inclusions: ibePackageHotelRate.inclusions,
            paymentMethod: ibePackageHotelRate.paymentMethod,
            minLos: pack.minLOS,
            bestAvailableRate: pack.bestAvailableRate,
            images: ibePackageHotelRate.images,
            sortOrder: ibePackageHotelRate.sortOrder,
          };
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
      return availablePackages;
    },
    headerImage() {
      if (!this.hotelData) {
        return null;
      }
      let image;
      const headerImage = this.hotelData.uiSettings.header;
      if (headerImage.imageURL) {
        image = headerImage;
      }
      return image;
    },
    showSearchCriteria() {
      if (!this.searchCriteria) {
        return false;
      }
      return (
        this.step === 1 &&
        this.searchCriteria.dates.length === 2 &&
        this.searchCriteria.rooms.length &&
        !this.selectedPackage
      );
    },
    selectableServices() {
      if (!this.hotelData || !this.availabilityData) {
        return [];
      }
      const hotelServices = this.hotelData.services.filter(
        (service) => ![3, 4].includes(service.applicationType)
      );
      hotelServices.forEach((service) => {
        const match = this.availabilityData.availableServices.find(
          (availableService) =>
            availableService.serviceCode === service.serviceCode
        );
        if (match) {
          service.price = match.price;
          service.range = match.range;
        }
      });
      return hotelServices;
    },
    skipServices() {
      const skipServices = this.roomBookings.every(
        (room) =>
          this.selectableServices.filter(
            (service) => !room.rate.inclusions.includes(service.serviceCode)
          ).length === 0
      );
      return skipServices;
    },
    searchRoomCriteria() {
      if (!this.searchCriteria || !this.token) {
        return null;
      }
      const searchCriteria = JSON.parse(JSON.stringify(this.searchCriteria));
      const bookedRoomIds = JSON.parse(JSON.stringify(this.roomBookings)).map(
        (room) => room.id
      );
      let roomToSelect = null;
      if (bookedRoomIds.length === 0) {
        roomToSelect = searchCriteria.rooms[0];
      } else {
        roomToSelect = searchCriteria.rooms.find(
          (room) => !bookedRoomIds.includes(room.id)
        );
      }
      const searchRoomCriteria = {
        dates: searchCriteria.dates,
        room: roomToSelect,
        promoCode: this.promoCode,
      };
      return searchRoomCriteria;
    },
    showClearSelection() {
      if (!this.searchCriteria) {
        return false;
      }
      return (
        this.roomBookings.length &&
        this.roomBookings.length === this.searchCriteria.rooms.length
      );
    },
    ...mapState([
      "token",
      "hotel",
      "roomsGuests",
      "roomToSelect",
      "step",
      "roomBookings",
      "promoCode",
      "segment",
      "nights",
      "packageData",
      "resId",
      "noImageCache",
      "total",
      "stayDates",
    ]),
    ...mapState("language", ["language"]),
  },
  watch: {
    searchRoomCriteria: {
      deep: true,
      immediate: true,
      handler(val, oldval) {
        if (val) {
          if (val.room) {
            const roomChanged =
              JSON.stringify(val.room) !== JSON.stringify(this.roomToSelect);
            const datesChanged =
              !!oldval &&
              JSON.stringify(val.dates) !== JSON.stringify(oldval.dates);
            const promoCodeChanged =
              !!oldval &&
              JSON.stringify(val.promoCode) !==
                JSON.stringify(oldval.promoCode);
            if (
              roomChanged ||
              datesChanged ||
              promoCodeChanged ||
              oldval === null
            ) {
              this.setStep(1);
              this.getRoomsAvailability(val);
            }
          } else {
            this.setRoomToSelect(null);
            if (this.roomBookings.length > 0) {
              if (this.skipServices) {
                this.setStep(3);
              } else {
                this.setStep(2);
              }
            }
          }
        }
      },
    },
    language(val) {
      this.setDocumentTabTitle(val);
    },
    step(val) {
      if (val === 6) {
        if (this.$gtm && this.$gtm.enabled()) {
          const bookedRooms = this.roomBookings.map((roomBooking) => {
            return {
              arrivalDate: this.stayDates.arrivalDate,
              quantity: this.nights,
              item_category: roomBooking.rate.code,
              item_id: roomBooking.room.code,
              item_name: roomBooking.room.name,
              price: roomBooking.rate.total,
            };
          });
          const dataLayerEvent = {
            event: "purchase",
            transaction_id: this.resId,
            value: this.total,
            currency: this.hotel.currencySign,
            coupon: this.promoCode,
            items: bookedRooms,
          };
          window.dataLayer?.push(JSON.parse(JSON.stringify(dataLayerEvent)));
        }
      }
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    if (this.navigatedFromChainOverview) {
      this.searchCriteria = null;
      this.setStep(1);
      this.getPropertyData(this.$route.query.id);
    } else {
      this.initPage();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.navigatedFromChainOverview = from.name === "chainpage";
    });
  },
  methods: {
    initPage() {
      this.searchCriteria = null;
      this.selectedPackage = null;
      this.setPackageData({
        rateCode: "",
        roomCode: "",
        rateData: null,
        images: [],
        maxLos: 0,
      });
      this.setAdditionalServices([]);
      this.setSegment("");
      this.setResult(null);
      this.setNights(0);
      this.setRoomBookings([]);
      this.setRoomToSelect(null);
      this.setPromoCode("");
      this.setRoomsGuests([]);
      this.setPreAuth(false);
      this.setResId("");
      this.setToken(null);
      this.showPromoCodeInput = false;
      this.mobileSearchCriteriaDialogIsTriggered = false;
      this.hotelData = null;
      this.availabilityData = null;
      this.paymentMethods = null;
      this.paymentData = null;
      const id = this.$route.query.id;
      const promoCode = this.$route.query.promocode;
      if (
        promoCode &&
        promoCode.length > 1 &&
        /^[a-zA-Z0-9-_]*$/.test(promoCode)
      ) {
        this.setPromoCode(promoCode);
      }
      const segment = this.$route.query.segment;
      if (segment && segment.length > 1 && /^[a-zA-Z0-9]*$/.test(segment)) {
        this.setSegment(segment);
      }
      const redirectResult = this.$route.query.redirectResult;
      if (id) {
        this.setStep(1);
        this.getPropertyData(id);
      } else if (redirectResult) {
        this.getToken(redirectResult);
      } else {
        this.showLoadingError = true;
      }
    },
    initSearchCriteria() {
      const dates = this.initDates();
      const rooms = this.initRoomsGuests();
      this.setRoomsGuests(rooms);
      this.searchCriteria = {
        dates: dates,
        rooms: rooms,
      };
    },
    initDates() {
      let dates = [];
      const arrival = this.$route.query.arrival,
        departure = this.$route.query.departure;
      if (this.packageData.rateCode) {
        if (
          arrival &&
          arrival === format(arrival, "YYYY-MM-DD") &&
          arrival >= format(new Date(), "YYYY-MM-DD")
        ) {
          dates = [
            arrival,
            format(addDays(arrival, this.packageData.maxLos), "YYYY-MM-DD"),
          ];
        } else {
          dates = [
            format(new Date(), "YYYY-MM-DD"),
            format(addDays(new Date(), this.packageData.maxLos), "YYYY-MM-DD"),
          ];
        }
      } else if (
        arrival &&
        departure &&
        arrival === format(arrival, "YYYY-MM-DD") &&
        departure === format(departure, "YYYY-MM-DD") &&
        arrival < departure &&
        arrival >= format(new Date(), "YYYY-MM-DD")
      ) {
        dates = [arrival, departure];
      } else {
        dates = [
          format(new Date(), "YYYY-MM-DD"),
          format(addDays(new Date(), 1), "YYYY-MM-DD"),
        ];
      }
      return dates;
    },
    initRoomsGuests() {
      const childBucket = this.hotel.ageBuckets.find(
        (bucket) => bucket.aqc === 8
      );

      const adults = validateParameter(this.$route.query.adults)
          ? parseInt(this.$route.query.adults)
          : null,
        children =
          childBucket && validateParameter(this.$route.query.children)
            ? parseInt(this.$route.query.children)
            : 0,
        promocode = this.promoCode;
      let rooms = validateParameter(this.$route.query.rooms)
        ? parseInt(this.$route.query.rooms)
        : null;
      if (rooms > 5) {
        rooms = 5;
      }
      const roomsGuests = initRoomsGuests(rooms, adults, children, promocode);
      return roomsGuests;
    },
    getPropertyData(id) {
      this.showLoader = true;
      let url =
        process.env.VUE_APP_ROUTER_URL +
        "/ibe?id=" +
        id +
        "&segment=" +
        this.segment;
      axiosGet(url)
        .then((response) => {
          if (response.status) {
            this.showLoadingError = true;
            this.snackbarError(this.$t("loadingDataFailed"));
          } else {
            this.handleGetPropertyData(response);
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
          this.snackbarError(this.$t("loadingDataFailed"));
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    handleGetPropertyData(response) {
      if (response.success) {
        if (
          response.ibeData.gtmIdentifier &&
          process.env.VUE_APP_MODE === "PROD"
        ) {
          try {
            this.activateGtm(response.ibeData.gtmIdentifier);
            if (this.$gtm.enabled()) {
              window.dataLayer?.push({
                event: "open-be",
              });
            }
          } catch (error) {
            console.error(error);
          }
        }
        const activeLanguages = response.ibeData.languages.filter(
          (lang) => lang.active === 1
        );
        if (activeLanguages.length === 0) {
          this.showLoadingError = true;
        } else {
          this.setLanguages(activeLanguages);
          const language = this.$route.query.language;
          if (
            language &&
            activeLanguages.some(
              (lang) => lang.locale.toLowerCase() === language.toLowerCase()
            )
          ) {
            this.changeLanguage(language);
          } else {
            this.changeLanguage(activeLanguages[0].locale);
          }
          this.hotelData = response.ibeData;
          this.setSegment(this.hotelData.segment);
          const styles = createAppStyles(this.hotelData.settings);
          if (styles.font) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = `https://fonts.googleapis.com/css?family=${styles.font}`;
            document.head.appendChild(link);
          }
          this.showAllRooms = JSON.parse(
            JSON.stringify(response.ibeData.settings)
          ).some(
            (setting) =>
              setting.settingType === 16 && setting.settingValue === 1
          );
          this.setAppStyles(styles);
          this.setHotel({
            code: this.hotelData.hotelCode,
            name: this.hotelData.hotelName,
            environment: this.hotelData.environment,
            currencySign: this.hotelData.currencyCode,
            termsUrl: this.hotelData.terms,
            privacyUrl: this.hotelData.privacy,
            settings: this.hotelData.settings,
            uiSettings: this.hotelData.uiSettings,
            ageBuckets: this.hotelData.ageBuckets,
          });
          this.setDocumentTabFavicon();
          this.setDocumentTabTitle(this.language);
          const additionalServices = this.hotelData.services.filter((service) =>
            [3, 4].includes(service.applicationType)
          );
          this.setAdditionalServices(additionalServices);
          const packageParam = this.$route.query.package;
          if (packageParam) {
            this.handlePackageParameter(packageParam.toLowerCase());
          }
          const roomTypeParam = this.$route.query.roomtype;
          if (roomTypeParam && !packageParam) {
            if (
              this.hotelData.rooms.some(
                (room) =>
                  room.roomCode.toLowerCase() === roomTypeParam.toLowerCase()
              )
            ) {
              this.roomCodeFilter = roomTypeParam.toLowerCase();
            }
          }
          if (!this.navigatedFromChainOverview) {
            this.getToken();
          } else {
            const dates = this.initDates();
            const hotelAgebuckets = JSON.parse(
              JSON.stringify(this.hotel.ageBuckets)
            );
            const chainRoomsGuests = JSON.parse(
              JSON.stringify(this.roomsGuests)
            );
            const roomsGuests = JSON.parse(JSON.stringify(this.roomsGuests));
            roomsGuests.forEach((room) => {
              if (!hotelAgebuckets.find((bucket) => bucket.aqc === 8)) {
                room.children = 0;
              }
              if (!hotelAgebuckets.find((bucket) => bucket.aqc === 7)) {
                room.infants = 0;
              }
            });
            if (objectsAreDifferent(chainRoomsGuests, roomsGuests)) {
              this.setRoomsGuests(roomsGuests);
            }
            this.searchCriteria = {
              dates: dates,
              rooms: roomsGuests,
            };
          }
        }
      } else {
        this.showLoadingError = true;
        this.snackbarError(this.$t("loadingDataFailed"));
        //this.showLoader = false;
      }
    },
    getToken(redirectResult) {
      const isprod = process.env.VUE_APP_MODE === "PROD";
      const url =
        process.env.VUE_APP_IDENTITY_URL +
        "/api?id=71e29f99-4bfb-406c-9181-9dd7e99b45a5&isprod=" +
        isprod;
      axiosGet(url)
        .then((response) => {
          if (response.isAuthenticated) {
            this.setToken(response.authToken);
            if (redirectResult) {
              this.getPaymentCheckoutStatus(redirectResult);
            } else {
              this.initSearchCriteria();
            }
          } else {
            this.showLoadingError = true;
            this.snackbarError(this.$t("loadingDataFailed"));
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
          this.snackbarError(this.$t("loadingDataFailed"));
        });
    },
    getRoomsAvailability(criteria) {
      const dates = criteria.dates;
      const room = criteria.room;
      this.$root.$loader.open("Loading rooms");
      this.availabilityData = null;
      this.roomsCategoryFilter = null;
      const roomStayCandidates = [
        {
          Quantity: 1,
          GuestCounts: [
            {
              AgeQualifyingCode: 10,
              Count: room.adults,
            },
          ],
        },
      ];
      if (room.children) {
        roomStayCandidates[0].GuestCounts.push({
          AgeQualifyingCode: 8,
          Count: room.children,
        });
      }
      if (room.infants) {
        roomStayCandidates[0].GuestCounts.push({
          AgeQualifyingCode: 7,
          Count: room.infants,
        });
      }
      const url = process.env.VUE_APP_IBE_URL + "/ibe/shop";
      const data = {
        Environment: this.hotel.environment,
        HotelCode: this.hotel.code,
        Segment: this.segment,
        Availability: {
          promoCode: criteria.promoCode,
          StayDateRange: {
            StartDate: dates[0],
            EndDate: dates[1],
          },
          RoomStayCandidates: roomStayCandidates,
        },
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          this.handleGetRoomsAvailability(response, room);
        })
        .catch((error) => {
          console.log(error);
          this.snackbarError(this.$t("loadingDataFailed"));
        })
        .finally(() => {
          this.$root.$loader.close();
        });
    },
    handleGetRoomsAvailability(response, room) {
      if (response.success) {
        this.setRoomToSelect(JSON.parse(JSON.stringify(room)));
        this.availabilityData = response.availability;
        this.setStayDates({
          arrivalDate: format(
            response.availability.stayDateRange.startDate,
            "YYYY-MM-DD"
          ),
          departureDate: format(
            response.availability.stayDateRange.endDate,
            "YYYY-MM-DD"
          ),
        });
        this.showPromoCodeInput = !!response.hasPromos;
        this.setNights(response.availability.stayDateRange.duration);
        if (
          this.showPromoCodeInput &&
          !this.mobileSearchCriteriaDialogIsTriggered
        ) {
          setTimeout(() => {
            if (this.$refs.searchCriteria.$refs.searchCriteriaMobile) {
              this.$refs.searchCriteria.$refs.searchCriteriaMobile.showDialog(
                "searchCriteria"
              );
            }
            this.mobileSearchCriteriaDialogIsTriggered = true;
          }, 1000);
        }
      } else {
        //this.showLoadingError = true;
        this.snackbarError(this.$t("loadingDataFailed"));
      }
    },
    handlePackageParameter(packageParam) {
      const packageRate = this.hotelData.rates.find(
        (rate) => rate.rateCode.toLowerCase() === packageParam
      );
      const roomParam = this.$route.query.roomtype;
      let packageRoom = null;
      if (roomParam) {
        packageRoom = this.hotelData.rooms.find(
          (room) => room.roomCode.toLowerCase() === roomParam.toLowerCase()
        );
      }
      if (packageRate) {
        const packageData = {
          rateCode: packageRate.rateCode,
          rateData: packageRate.rateData,
          images: packageRate.images,
          maxLos: packageRate.bookingPolicy.maxLOS,
          roomCode: packageRoom ? packageRoom.roomCode : "",
        };
        this.setPackageData(packageData);
      }
    },
    bookingSuccess(response) {
      this.setResId(response.resID_Value);
      if (!response.paymentData) {
        this.snackbarSuccess(this.$t("bookingCreatedSuccessfully"));
        this.setStep(6);
      } else {
        const paymentData = JSON.parse(response.paymentData);
        if (paymentData.PaymentMethod.toLowerCase() === "guarantee") {
          this.setStep(5);
        } else if (response.paymentMethods) {
          const paymentMethods = response.paymentMethods
            ? JSON.parse(response.paymentMethods)
            : { paymentMethods: [] };
          if (paymentData.PaymentMethod.toLowerCase() === "preauth") {
            paymentData.PaymentMethod = "scheme";
            this.setPreAuth(true);
            paymentMethods.paymentMethods =
              paymentMethods.paymentMethods.filter(
                (method) => method.type === "scheme"
              );
          }
          this.paymentMethods = paymentMethods;
          this.paymentData = paymentData;
          this.setStep(4);
        }
      }
    },
    getPaymentCheckoutStatus(code) {
      const url = process.env.VUE_APP_IBE_URL + "/pay/paystatus";
      const data = {
        type: "Redirect",
        data: code,
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          this.handleGetPaymentCheckoutStatus(response);
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    handleGetPaymentCheckoutStatus(response) {
      if (response.success && response.paymentData && response.bookingInfo) {
        const paymentData = response.paymentData;
        const ibeData = response.ibeData;
        const bookingInfo = response.bookingInfo;
        if (!this.hotelData) {
          this.hotelData = ibeData;
          if (ibeData.gtmIdentifier && process.env.VUE_APP_MODE === "PROD") {
            try {
              this.activateGtm(ibeData.gtmIdentifier);
              if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                  event: "open-be",
                });
              }
            } catch (error) {
              console.error(error);
            }
          }
          const styles = createAppStyles(this.hotelData.settings);
          if (styles.font) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = `https://fonts.googleapis.com/css?family=${styles.font}`;
            document.head.appendChild(link);
          }
          this.setAppStyles(styles);
          this.setHotel({
            code: this.hotelData.hotelCode,
            name: this.hotelData.hotelName,
            environment: this.hotelData.environment,
            currencySign: this.hotelData.currencyCode,
            termsUrl: this.hotelData.terms,
            settings: this.hotelData.settings,
            uiSettings: this.hotelData.uiSettings,
            ageBuckets: this.hotelData.ageBuckets,
          });
          this.setStayDates({
            arrivalDate: format(bookingInfo.arrivalDate, "YYYY-MM-DD"),
            departureDate: format(bookingInfo.departureDate, "YYYY-MM-DD"),
          });
          const activeLanguages = ibeData.languages.filter(
            (lang) => lang.active === 1
          );
          this.setLanguages(activeLanguages);
          if (
            activeLanguages.some((lang) => lang.locale === bookingInfo.locale)
          ) {
            this.changeLanguage(bookingInfo.locale);
          } else if (
            bookingInfo.locale === "en-GB" &&
            activeLanguages.some((lang) => lang.locale === "en-US")
          ) {
            this.changeLanguage("en-US");
          } else if (
            bookingInfo.locale === "en-US" &&
            activeLanguages.some((lang) => lang.locale === "en-GB")
          ) {
            this.changeLanguage("en-GB");
          } else {
            this.changeLanguage(activeLanguages[0].locale);
          }

          this.setDocumentTabFavicon();
          this.setDocumentTabTitle(this.language);
          const roomsGuests = bookingInfo.roomStays.map((room, idx) => {
            return {
              id: idx + 1,
              adults: room.adults,
              children: room.children,
              infants: room.infants,
            };
          });
          this.setNights(
            Math.ceil(
              Math.abs(
                new Date(bookingInfo.departureDate.split("T")[0]).getTime() -
                  new Date(bookingInfo.arrivalDate.split("T")[0]).getTime()
              ) /
                (1000 * 3600 * 24)
            )
          );
          this.setRoomsGuests(roomsGuests);

          const additionalServices = this.hotelData.services.filter((service) =>
            [3, 4].includes(service.applicationType)
          );
          this.setAdditionalServices(additionalServices);

          this.paymentData = paymentData;
          this.setResId(paymentData.reservationID);
          this.paymentMethods = {
            paymentMethods: [],
          };

          bookingInfo.roomStays.forEach((room) => {
            room.services.forEach((service) => {
              const hotelService = this.hotelData.services.find(
                (hotelService) =>
                  hotelService.serviceCode === service.serviceCode
              );
              if (hotelService) {
                service.serviceData = hotelService.serviceData;
                service.chargeType = hotelService.chargeType;
                service.applicationType = hotelService.applicationType;
                service.serviceType = hotelService.serviceType;
                service.total = this.calculateTotal(
                  service,
                  room.adults,
                  room.children,
                  room.infants
                );
                if ([14, 15, 16, 20, 29].includes(service.chargeType)) {
                  service.range.forEach((item) => {
                    item.date = item.start.substring(0, 10);
                    item.time = item.start.split("T").pop().substring(0, 5);
                  });
                }
              }
            });
            room.availableServices = JSON.parse(JSON.stringify(room.services));
            room.services = room.services.filter(
              (service) =>
                service.applicationType !== 3 &&
                service.serviceData !== undefined
            );
          });

          const roomBookings = bookingInfo.roomStays.map((room, idx) => {
            return {
              room: {
                code: room.roomCode,
                roomData: ibeData.rooms.find(
                  (ibeRoom) => ibeRoom.roomCode === room.roomCode
                ).roomData,
                name: ibeData.rooms.find(
                  (ibeRoom) => ibeRoom.roomCode === room.roomCode
                ).name,
              },
              rate: {
                rateData: ibeData.rates.find(
                  (ibeRate) => ibeRate.rateCode === room.rate.rateCode
                ).rateData,
                total: room.rate.total,
                nettTotal: this.calcRateNettTotal(room.rate.rates),
                code: room.rate.rateCode,
                rates: room.rate.rates,
                paymentMethod: ibeData.rates.find(
                  (ibeRate) => ibeRate.rateCode === room.rate.rateCode
                ).paymentMethod,
              },
              services: room.services,
              availableServices: room.availableServices,
              selectedRoom: this.roomsGuests[idx],
              id: idx + 1,
            };
          });
          this.setRoomBookings(roomBookings);
          this.setSegment(bookingInfo.segment);
        }

        if (response.pspStatus.toLowerCase() === "authorised") {
          this.setPaidStatus();
        } else {
          this.setResult(response.pspStatus.toLowerCase());
          this.setStep(4);
        }
      } else {
        this.showLoadingError = true;
      }
    },
    setPaidStatus() {
      const url = process.env.VUE_APP_IBE_URL + "/pay/paid ";
      const data = {
        Environment: this.hotel.environment,
        HotelCode: this.hotel.code,
        Segment: this.segment,
        reservationID: this.resId,
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          if (response.success) {
            this.setStep(6);
          } else {
            this.snackbarError(this.$t("undefinedError"));
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
        })
        .finally(() => {});
    },
    calculateTotal(option, adults, children, infants) {
      let factor = 1;
      const price = option.price,
        chargeType = option.chargeType,
        nights = this.nights,
        units = option.units,
        selectedDates = option.range;
      switch (chargeType) {
        case 1: // perAdultPerNight,
          factor = adults * nights;
          break;
        case 2: // perAdultPerStay,
          factor = adults;
          break;
        case 3: // perChildPerNight,
          factor = children * nights;
          break;
        case 4: // perChildPerStay,
          factor = children;
          break;
        case 5: // perNight,
          factor = nights;
          break;
        case 6: // perUnit,
          factor = units;
          break;
        case 7: // perUnitPerAdult,
          factor = units * adults;
          break;
        case 8: // perUnitPerAdultPerNight,
          factor = units * adults * nights;
          break;
        case 9: // perUnitPerNight,
          factor = units * nights;
          break;
        case 17: // perInfantPerNight,
          factor = infants * nights;
          break;
        case 18: // perInfantPerStay,
          factor = infants;
          break;
        case 26: // PerPersonPerNight,
          factor = (adults + children + infants) * nights;
          break;
        case 27: // PerPersonPerStay,
          factor = adults + children + infants;
          break;
        case 14: // selectedDatesPerStay,
          factor = selectedDates.length;
          break;
        case 15: // selectedDatesPerPerson,
        case 16: // selectedDatesPerPerson,
        case 20: // selectedDatesPerPerson,
        case 29: // selectedDatesPerPerson,
          factor = selectedDates
            .map((date) => parseInt(date.units))
            .reduce((prev, curr) => prev + curr);
          break;
      }
      return factor * price;
    },
    calcRateNettTotal(rates) {
      let rateNettTotal = 0;
      rates.forEach((rate) => {
        rate.amounts.forEach((amount) => {
          rateNettTotal += amount.nettAmt;
        });
      });
      return rateNettTotal;
    },
    toDefaultsOverViewPage() {
      const query = Object.assign({}, this.$route.query);
      Object.keys(query)
        .filter((key) => key !== "id")
        .forEach((key) => {
          delete query[key];
        });
      this.$router.replace({ query });
      this.setPackageData({
        rateCode: "",
        roomCode: "",
        rateData: null,
        images: [],
        maxLos: 0,
      });
      this.getRoomsAvailability(this.searchRoomCriteria);
    },
    toOverviewFromSelectedPackageView() {
      this.selectedPackage = null;
      this.roomsCategoryFilter = null;
      window.scrollTo(0, 0);
    },
    setDocumentTabFavicon() {
      const faviconSettings = this.hotel.uiSettings.favIcon;
      if (faviconSettings && faviconSettings.imageURL) {
        const favicon = document.getElementById("favicon");
        favicon.href = faviconSettings.imageURL + "?" + this.noImageCache;
      }
    },
    setDocumentTabTitle(language) {
      let documentTitle = this.hotel.name;
      const faviconSettings = this.hotel.uiSettings.favIcon;
      if (faviconSettings) {
        const tabTitleLanguageMatch = faviconSettings.imageData.find(
          (lang) => lang.language === language.locale
        );
        if (tabTitleLanguageMatch && tabTitleLanguageMatch.name) {
          documentTitle = tabTitleLanguageMatch.name;
        }
      }
      document.title = documentTitle;
    },
    activateGtm(id) {
      Vue.use(VueGtm, {
        id: id,
        enabled: true,
        loadScript: true,
      });
    },
    clearAllSelectedRooms() {
      this.$root
        .$confirm(
          this.$t("clearSelection"),
          this.$t("clearSelectionConfirmMessage")
        )
        .then((confirmResult) => {
          if (confirmResult) {
            this.setRoomBookings([]);
          }
        });
    },
    showPackageRoomsView(selectedPackage) {
      const availableServices = this.availabilityData.availableServices;
      const packageData = selectedPackage;
      packageData.rooms = this.availabilityData.availablePackages
        .find((pack) => pack.ratePlanCode === selectedPackage.rateCode)
        .roomplans.map((roomplan) => {
          const packageHotelRoomData = this.hotelData.rooms.find(
            (room) => room.roomCode === roomplan.roomCode
          );
          return {
            isAvailable: roomplan.isAvailable,
            nettTotal: roomplan.nettTotal,
            total: roomplan.total,
            name: roomplan.name,
            roomData: packageHotelRoomData,
            roomRates: roomplan.rates,
            availableServices: availableServices,
            maxOccupancy: roomplan.roomOccupancy.maxOccupancy,
          };
        })
        .sort((a, b) => a.roomData.sortOrder - b.roomData.sortOrder);
      this.selectedPackage = packageData;
    },
    ...mapActions("language", ["changeLanguage", "setLanguages"]),
    ...mapActions("appStyles", ["setAppStyles"]),
    ...mapActions([
      "setToken",
      "setHotel",
      "setRoomsGuests",
      "setStep",
      "setRoomBookings",
      "setResult",
      "setPromoCode",
      "setPreAuth",
      "setNights",
      "setStayDates",
      "setAdditionalServices",
      "setSegment",
      "setPackageData",
      "setResId",
      "setRoomToSelect",
    ]),
  },
};
</script>
