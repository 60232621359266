<template>
  <div>
    <template v-if="showBookingsPage">
      <app-toolbar :header-image="headerImage"></app-toolbar>
      <v-sheet
        color="transparent"
        width="1000"
        max-width="100%"
        class="mx-auto pa-2 pa-sm-4"
      >
        <template v-if="reservations.length">
          <h2 class="text-h5 mb-4">{{ $t("upComingVisits") }}</h2>
          <template v-if="upcomingVisits.length > 0">
            <user-booking
              v-for="item in upcomingVisits"
              :key="item.uniqueID"
              :booking="item"
              @cancel-reservation="cancelReservation($event)"
            ></user-booking>
          </template>
          <p v-else>{{ $t("noUpComingVisitsFound") }}</p>
          <template v-if="cancelledReservations.length">
            <h3 class="text-h6 mb-4">{{ $t("cancelledBookings") }}</h3>
            <user-booking
              v-for="item in cancelledReservations"
              :key="item.uniqueID"
              :booking="item"
            ></user-booking>
          </template>
        </template>
        <v-container v-else-if="showLoadingError" class="justify-center d-flex">
          <loading-data-error />
        </v-container>
        <p v-else class="text-center">{{ $t("noBookingsFound") }}</p>
      </v-sheet>
    </template>

    <v-container v-else-if="showLoadingError" class="justify-center d-flex">
      <loading-data-error />
    </v-container>
  </div>
</template>

<script>
import { axiosGet, axiosPost } from "@/api/requests";
import { mapState, mapActions } from "vuex";
import snackBarMessages from "@/mixins/snackBarMessages";
import LoadingDataError from "@/components/LoadingDataError";
import AppToolbar from "@/components/globals/AppToolbar";
import UserBooking from "@/components/UserBooking";

export default {
  name: "BookingsOverview",
  components: {
    LoadingDataError,
    UserBooking,
    AppToolbar,
  },
  mixins: [snackBarMessages],
  data() {
    return {
      reservations: [],
      showLoadingError: false,
    };
  },
  computed: {
    showBookingsPage() {
      return !!this.user && !!this.hotel;
    },
    headerImage() {
      let image = null;
      if (this.hotel) {
        const headerImage = this.hotel.uiSettings.header;
        if (headerImage.imageURL) {
          image = headerImage;
        }
      }

      return image;
    },
    upcomingVisits() {
      return this.reservations.filter((res) => res.resStatus === 1);
    },
    cancelledReservations() {
      return this.reservations.filter((res) => res.resStatus === 3);
    },
    ...mapState(["hotel"]),
    ...mapState("user", ["user"]),
  },
  mounted() {
    if (this.showBookingsPage) {
      this.getUserBookings();
    } else {
      this.showLoadingError = true;
    }
  },
  methods: {
    getUserBookings() {
      this.showLoadingError = false;
      this.$root.$loader.open(this.$t("yourBookings"));
      const url =
        process.env.VUE_APP_IBE_URL +
        "/pwa/hotelreservations?name=" +
        this.hotel.environment +
        "&hotelcode=" +
        this.hotel.code +
        "&userid=" +
        this.user.id;
      axiosGet(url, this.user.authToken)
        .then((response) => {
          if (response.status && response.status === 401) {
            this.$router.go(-1);
            this.setUser(null);
            this.snackbarError(this.$t("undefinedError"));
          } else if (response.success) {
            this.reservations = response.reservations;
          } else {
            this.snackbarError(this.$t("loadingDataFailed"));
            this.showLoadingError = true;
          }
        })
        .finally(() => {
          this.$root.$loader.close();
        });
    },
    cancelReservation(item) {
      this.$root.$loader.open(this.$t("cancelBooking"));
      const url = process.env.VUE_APP_IBE_URL + "/ibe/cancel";
      const data = {
        Environment: this.hotel.environment,
        HotelCode: item.hotelCode,
        ResID: item.reservationID,
        PIN: item.pinCode,
      };
      axiosPost(url, data, this.user.authToken)
        .then((response) => {
          if (response.success) {
            this.snackbarSuccess(this.$t("cancelBookingSuccessMessage"));
            this.getUserBookings();
          } else {
            this.snackbarError(this.$t("cancelBookingFailedMessage"));
          }
        })
        .catch((error) => {
          console.log(error);
          this.snackbarError(this.$t("undefinedError"));
        })
        .finally(() => {
          this.$root.$loader.close();
        });
    },
    ...mapActions("user", ["setUser"]),
  },
};
</script>
