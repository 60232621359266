<template>
  <div class="px-2">
    <search-criteria-mobile
      v-if="isMobile"
      ref="searchCriteriaMobile"
      :stay-dates="searchCriteria.dates"
      :show-promo-code-input="showPromoCodeInput"
      :age-buckets="ageBuckets"
      @update:search-criteria="$emit('update:search-criteria', $event)"
    ></search-criteria-mobile>
    <template v-else>
      <div class="px-2 d-flex justify-space-between align-center mb-3">
        <v-row class="py-sm-2">
          <stay-dates
            v-if="hotel"
            @dates-changed="updateSearchCriteriaDates"
          ></stay-dates>
          <chain-stay-dates
            v-else
            @dates-changed="updateSearchCriteriaDates"
          ></chain-stay-dates>
          <guests-rooms
            v-if="!packageData.rateCode"
            :show-promo-code-input="showPromoCodeInput"
            :age-buckets="ageBuckets"
            @rooms-changed="updateSearchCriteriaRooms"
          ></guests-rooms>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import isMobile from "@/mixins/isMobile";
import SearchCriteriaMobile from "@/components/SearchCriteriaMobile";
import StayDates from "@/components/StayDates";
import ChainStayDates from "@/components/ChainStayDates";
import GuestsRooms from "@/components/GuestsRooms";

export default {
  name: "SearchCriteria",
  components: {
    SearchCriteriaMobile,
    StayDates,
    ChainStayDates,
    GuestsRooms,
  },
  mixins: [isMobile],
  props: {
    searchCriteria: {
      type: Object,
      required: true,
    },
    showPromoCodeInput: {
      type: Boolean,
      required: true,
    },
    ageBuckets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["packageData", "hotel", "roomsGuests"]),
  },
  methods: {
    searchRooms(criteria) {
      this.$emit("search-rooms", criteria);
    },
    datesChanged(dates) {
      this.$emit("dates-changed", dates);
    },
    updateSearchCriteriaDates(dates) {
      const criteria = JSON.parse(JSON.stringify(this.searchCriteria));
      criteria.dates = dates;
      this.$emit("update:search-criteria", criteria);
    },
    updateSearchCriteriaRooms(rooms) {
      const criteria = JSON.parse(JSON.stringify(this.searchCriteria));
      criteria.rooms = rooms;
      this.$emit("update:search-criteria", criteria);
    },
  },
};
</script>
