<template>
  <dl class="grid-dl text-body-2">
    <dt class="notranslate">
      {{ getLocalizedText(service.serviceData, "name") }}
    </dt>
    <dd class="text-right">
      <summary-data-amount :amount="totalServiceAmount"></summary-data-amount>
    </dd>
  </dl>
</template>

<script>
import { mapState } from "vuex";
import localizedText from "@/mixins/localizedText";
import SummaryDataAmount from "@/components/SummaryDataAmount";

export default {
  name: "AdditionalRoomService",
  components: {
    SummaryDataAmount,
  },
  mixins: [localizedText],
  props: {
    service: {
      type: Object,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalServiceAmount() {
      let amount = 0;
      const service = this.room.availableServices.find(
        (service) => service.serviceCode === this.service.serviceCode
      );
      const adults = this.room.selectedRoom.adults;
      const children = this.room.selectedRoom.children;
      const infants = this.room.selectedRoom.infants;
      const nettAmount = this.roomBookings.find(
        (roombooking) => roombooking.id === this.room.id
      ).rate.nettTotal;
      const nights = this.nights;
      switch (service.chargeType) {
        case 1: // perAdultPerNight,
          amount = service.price * adults * nights;
          break;
        case 2: // perAdultPerStay,
          amount = service.price * adults;
          break;
        case 3: // perChildPerNight,
          amount = service.price * children * nights;
          break;
        case 4: // perChildPerStay,
          amount = service.price * children;
          break;
        case 5: // perNight,
          amount = service.price * nights;
          break;
        case 6: // perUnit,
          amount = service.price;
          break;
        case 7: // perUnitPerAdult,
          amount = service.price * adults;
          break;
        case 8: // perUnitPerAdultPerNight,
          amount = service.price * adults * nights;
          break;
        case 9: // perUnitPerNight,
          amount = service.price * nights;
          break;
        case 10: // perStay
          amount = service.price;
          break;
        case 11: // percentagePerStay,
          amount = 0.01 * service.price * nettAmount;
          break;
        case 12: // percentagePerStayPerAdult,
          amount = 0.01 * service.price * nettAmount * adults;
          break;
        case 17: // perInfantPerNight,
          amount = service.price * infants * nights;
          break;
        case 18: // perInfantPerStay,
          amount = service.price * infants;
          break;
        case 26: // PerPersonPerNight,
          amount = service.price * (adults + children + infants) * nights;
          break;
        case 27: // PerPersonPerStay,
          amount = service.price * (adults + children + infants);
          break;
        case 28: // PercentagePerStayPerPerson,
          amount =
            0.01 * service.price * nettAmount * (adults + children + infants);
          break;
        default:
          break;
      }
      return amount;
    },
    ...mapState(["roomBookings", "nights"]),
  },
};
</script>
