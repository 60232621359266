<template>
  <v-row dense class="d-flex">
    <v-col cols="12" lg="4" class="d-flex align-center">
      <v-checkbox
        v-model="itemDetails.selected"
        class="mt-0 pt-0"
        color="black"
        hide-details
        @change="$emit('update-date-option', itemDetails)"
      >
      </v-checkbox>
      <span class="text-body-2">{{ date }}</span>
    </v-col>
    <div class="d-flex ml-8 ml-sm-0 date-selection-inputs">
      <default-number-field
        v-if="showUnitsField"
        v-model.number="itemDetails.units"
        :disabled="!itemDetails.selected"
        class="mb-2 mb-lg-0 mx-2"
        :label="$t(unitsLabelKey)"
        :rules="validationRules('persons')"
        min="1"
        :hide-details="true"
        @change="$emit('update-date-option', itemDetails)"
      >
      </default-number-field>
      <default-text-field
        v-if="tableReservation"
        v-model="itemDetails.time"
        :disabled="!itemDetails.selected"
        class="mb-2 mb-lg-0 mx-2"
        :label="$t('time')"
        type="time"
        step="900"
        :rules="validationRules('time')"
        :hide-details="true"
        @change="$emit('update-date-option', itemDetails)"
      >
      </default-text-field>
    </div>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import DefaultNumberField from "@/components/formFields/DefaultNumberField";
import DefaultTextField from "@/components/formFields/DefaultTextField";
import { objectsAreDifferent } from "@/helpers/objectHelpers";

export default {
  name: "SelectableDateOption",
  components: {
    DefaultNumberField,
    DefaultTextField,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    tableReservation: {
      type: Boolean,
      required: true,
    },
    chargeType: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    itemDetails: null,
  }),
  computed: {
    date() {
      const formatter = new Intl.DateTimeFormat(this.datePickerLocale, {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return formatter.format(new Date(this.itemDetails.date));
    },
    datePickerLocale() {
      if (["nn-NO", "nb-NO"].includes(this.language.locale)) {
        return "no-NO";
      }
      return this.language.locale;
    },
    showUnitsField() {
      return [15, 16, 20, 29].includes(this.chargeType);
    },
    unitsLabelKey() {
      let labelKey = "";
      switch (this.chargeType) {
        case 15:
          labelKey = "adults";
          break;
        case 16:
          labelKey = "childen";
          break;
        case 20:
          labelKey = "infants";
          break;
        case 29:
          labelKey = "persons";
          break;
        default:
          break;
      }
      return labelKey;
    },
    ...mapState("language", ["language"]),
  },
  watch: {
    option: {
      immediate: true,
      handler(val) {
        const optionData = JSON.parse(JSON.stringify(val));
        if (this.itemDetails) {
          const currentData = JSON.parse(JSON.stringify(this.itemDetails));
          if (objectsAreDifferent(currentData, optionData)) {
            this.itemDetails = optionData;
          }
        } else {
          this.itemDetails = optionData;
        }
      },
    },
  },
  methods: {
    validationRules(fieldname) {
      switch (fieldname) {
        case "persons":
          return [
            (v) => !!v || "invalidValue",
            (v) => /^\d+$/.test(v) || "invalidValue",
          ];
        case "time":
          return [(v) => !!v || "invalidValue"];
      }
    },
  },
};
</script>
