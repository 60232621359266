<template>
  <v-menu
    content-class="help-pop-over pop-over"
    transition="scale-transition"
    origin="bottom left"
    max-width="250"
    top
    right
    :nudge-top="40"
    :nudge-left="18"
    allow-overflow
  >
    <template #activator="{ on }">
      <v-btn icon small class="append-outer-trigger-button" v-on="on">
        <v-icon color="black">$information</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>{{ text }}</v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "TextPopover",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
