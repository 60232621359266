<template>
  <div class="linear-loader-wrapper">
    <span>{{ message }}</span>
    <v-progress-linear
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "LinearLoader",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
