<template>
  <v-stepper v-model="step" elevation="0" class="d-none d-sm-block">
    <v-stepper-header>
      <v-stepper-step
        :step="1"
        :editable="step > 1"
        :complete="allRoomsSelected"
        @click="clickStep(1)"
      >
        <v-icon v-if="step > 1" color="primary">$link_arrow_left</v-icon>
        {{ $t("rooms") }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :step="2"
        :editable="roomBookings.length === roomsGuests.length"
        :complete="step > 2"
        @click="clickStep(2)"
      >
        <v-icon v-if="allRoomsSelected && step === 3" color="primary"
          >$link_arrow_left</v-icon
        >
        <v-icon v-if="allRoomsSelected && step === 1" color="primary"
          >$link_arrow_right</v-icon
        >
        {{ $t("options") }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :step="3"
        :editable="allRoomsSelected && step < 3"
        @click="clickStep(3)"
      >
        <v-icon v-if="allRoomsSelected && step < 3" color="primary"
          >$link_arrow_right</v-icon
        >
        {{ $t("book") }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "BookingSteps",
  computed: {
    allRoomsSelected() {
      return this.roomBookings.length === this.roomsGuests.length;
    },
    ...mapState(["step", "roomBookings", "roomsGuests"]),
  },
  methods: {
    clickStep(val) {
      if (this.allRoomsSelected && val !== this.step) {
        this.setStep(val);
      }
    },
    ...mapActions(["setStep"]),
  },
};
</script>
