<template>
  <v-col cols="12" sm="6" md="5" class="d-flex flex-column py-0">
    <h3 class="mb-2 text-subtitle-2">
      {{ $t("guests") }}
    </h3>
    <div class="guest-rooms-amounts">
      <v-menu
        v-model="editGuests"
        :close-on-content-click="false"
        transition="slide-y-transition"
        bottom
        attach
        :nudge-bottom="48"
        z-index="200"
        :max-width="ageBuckets.length === 0 ? 300 : 400"
      >
        <template #activator="{ on }">
          <v-btn small icon class="mr-6" v-on="on">
            <v-icon large color="black" dark>$edit</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-container class="px-0">
              <v-form ref="editRoomsGuestsForm" @submit.prevent>
                <v-list class="pb-0">
                  <v-list-item
                    v-for="(room, idx) in rooms"
                    :key="room.id"
                    class="pb-0"
                  >
                    <v-list-item-content class="pb-0">
                      <span
                        v-if="ageBuckets.length === 2"
                        class="text-body-1 mb-2"
                      >
                        {{ $t("room") }} {{ idx + 1 }}
                      </span>

                      <v-row dense>
                        <v-col
                          v-if="ageBuckets.length < 2"
                          :cols="ageBuckets.length === 0 ? 6 : 4"
                          class="py-0 mt-4"
                        >
                          {{ $t("room") }} {{ idx + 1 }}
                        </v-col>
                        <v-col :cols="ageBuckets.length === 0 ? 6 : 4">
                          <default-number-field
                            v-model.number="room.adults"
                            :label="
                              ageBuckets.length ? $t('adults') : $t('persons')
                            "
                            :rules="validationRules('adults')"
                            min="1"
                            :disabled="roomIsBooked(room.id)"
                          >
                          </default-number-field>
                        </v-col>
                        <template v-if="ageBuckets.length > 0">
                          <template v-if="childBucket">
                            <v-col cols="4">
                              <default-number-field
                                v-model.number="room.children"
                                :label="
                                  $t('children') +
                                  ' ' +
                                  childBucket.minAge +
                                  '-' +
                                  childBucket.maxAge
                                "
                                :rules="validationRules('children')"
                                min="0"
                                :disabled="roomIsBooked(room.id)"
                              >
                              </default-number-field>
                            </v-col>
                          </template>
                          <template v-if="infantBucket">
                            <v-col cols="4">
                              <default-number-field
                                v-model.number="room.infants"
                                :label="
                                  $t('infants') +
                                  ' ' +
                                  infantBucket.minAge +
                                  '-' +
                                  infantBucket.maxAge
                                "
                                :rules="validationRules('infants')"
                                min="0"
                                :disabled="roomIsBooked(room.id)"
                              >
                              </default-number-field>
                            </v-col>
                          </template>
                        </template>
                      </v-row>
                    </v-list-item-content>
                    <v-list-item-icon
                      class="align-self-start"
                      :class="{ 'mt-12': ageBuckets.length === 2 }"
                    >
                      <v-icon v-if="roomIsBooked(room.id)" color="success"
                        >$checkmark</v-icon
                      >
                      <v-icon
                        v-else-if="rooms.length > 1"
                        color="error"
                        @click="removeRoom(idx)"
                        >$delete</v-icon
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
                <v-btn
                  v-if="showAddRoomButton"
                  small
                  text
                  class="mt-n2 ml-2"
                  @click.stop="addRoom"
                >
                  <v-icon left>$add_circle</v-icon>
                  {{ $t("addRoom") }}
                </v-btn>
                <div class="pt-3 px-3 d-flex justify-space-between">
                  <default-text-field
                    v-if="showPromoCodeInput"
                    v-model.trim="promotionCode"
                    :disabled="roomBookings.length > 0"
                    :label="$t('promoCode')"
                    :rules="validationRules('promoCode')"
                    hide-details="auto"
                    @keydown.space.prevent
                  >
                  </default-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    @click="editGuests = false"
                  >
                    {{ $t("done") }}</v-btn
                  >
                </div>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-menu>
      <span class="guest-rooms-amount">{{ guests.rooms }}</span>
      <span class="guest-rooms-label">{{ $t("rooms") }}</span>
      <span class="guest-rooms-amount">{{ guests.adults }}</span>
      <span class="guest-rooms-label">{{
        ageBuckets.length ? $t("adults") : $t("persons")
      }}</span>
      <template v-if="ageBuckets.length > 0">
        <span class="guest-rooms-amount">{{
          guests.children + guests.infants
        }}</span>
        <span class="guest-rooms-label">{{ $t("children") }}</span>
      </template>
    </div>
  </v-col>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DefaultNumberField from "@/components/formFields/DefaultNumberField";
import DefaultTextField from "@/components/formFields/DefaultTextField";

export default {
  name: "GuestRooms",
  components: { DefaultNumberField, DefaultTextField },
  props: {
    showPromoCodeInput: {
      type: Boolean,
      required: true,
    },
    ageBuckets: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    editGuests: false,
    rooms: [],
    promotionCode: "",
  }),
  computed: {
    adultsTotal() {
      const total = this.roomsGuests
        .map((room) => parseInt(room.adults))
        .reduce((prev, curr) => prev + curr);

      return total;
    },

    childrenTotal() {
      const total = this.roomsGuests
        .map((room) => parseInt(room.children))
        .reduce((prev, curr) => prev + curr);
      return total;
    },
    infantsTotal() {
      const total = this.roomsGuests
        .map((room) => parseInt(room.infants))
        .reduce((prev, curr) => prev + curr);
      return total;
    },
    guests() {
      return {
        rooms: this.roomsGuests.length,
        adults: this.adultsTotal,
        children: this.childrenTotal,
        infants: this.infantsTotal,
      };
    },
    roomsChanged() {
      const originalRooms = JSON.stringify(this.roomsGuests);
      const currentRooms = JSON.stringify(this.rooms);
      return currentRooms !== originalRooms;
    },
    promoCodeChanged() {
      return this.promotionCode !== this.promoCode;
    },
    childBucket() {
      return this.ageBuckets.find((bucket) => bucket.aqc === 8);
    },
    infantBucket() {
      return this.ageBuckets.find((bucket) => bucket.aqc === 7);
    },
    showAddRoomButton() {
      return this.rooms.length < 5;
    },
    ...mapState(["roomsGuests", "roomBookings", "promoCode", "hotel"]),
  },
  watch: {
    editGuests(val) {
      if (!val) {
        //reset invalid values
        if (
          this.promotionCode !== "" &&
          !(
            this.promotionCode.length > 1 &&
            /^[a-zA-Z0-9-_]*$/.test(this.promotionCode)
          )
        ) {
          this.promotionCode = "";
        }

        this.rooms.forEach((room) => {
          if (
            room.adults == "" ||
            room.adults < 1 ||
            !/^\d+$/.test(room.adults)
          ) {
            room.adults = 1;
          }
          if (
            room.children == "" ||
            room.children < 0 ||
            !/^\d+$/.test(room.children)
          ) {
            room.children = 0;
          }
          if (
            room.infants == "" ||
            room.infants < 0 ||
            !/^\d+$/.test(room.infants)
          ) {
            room.infants = 0;
          }
          room.promoCode = this.promotionCode;
        });

        if (this.promoCodeChanged) {
          this.setPromoCode(this.promotionCode);
        }

        if (this.roomsChanged) {
          const rooms = JSON.parse(JSON.stringify(this.rooms));
          this.setRoomsGuests(rooms);
          this.$emit("rooms-changed", rooms);
        }
      } else {
        if (this.$refs.editRoomsGuestsForm) {
          this.$refs.editRoomsGuestsForm.resetValidation();
        }
      }
    },
    promoCode: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== this.promotionCode) {
          this.promotionCode = val;
        }
      },
    },
  },
  created() {
    this.rooms = JSON.parse(JSON.stringify(this.roomsGuests));
    this.editGuests = true;
  },
  methods: {
    addRoom() {
      this.rooms.push({
        id: this.rooms.slice(-1)[0].id + 1,
        adults: 2,
        children: 0,
        infants: 0,
      });
    },
    removeRoom(index) {
      this.rooms.splice(index, 1);
    },
    roomIsBooked(roomId) {
      return this.roomBookings.some((booking) => booking.id === roomId);
    },
    validationRules(fieldname) {
      switch (fieldname) {
        case "adults":
          return [
            (v) => !!v || "invalidValue",
            (v) => /^\d+$/.test(v) || "invalidValue",
          ];
        case "children":
        case "infants":
          return [
            (v) => !!v || v === 0 || "invalidValue",
            (v) => /^\d+$/.test(v) || "invalidValue",
          ];
        case "promoCode":
          return [
            (v) =>
              v === "" ||
              (v.length > 1 && /^[a-zA-Z0-9-_]*$/.test(v)) ||
              "invalidValue",
          ];
      }
    },
    ...mapActions(["setRoomsGuests", "setPromoCode"]),
  },
};
</script>

<style>
.guest-rooms-amount {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}
.guest-rooms-label {
  text-transform: uppercase;
  font-size: 14px;
  padding: 0 10px 0 5px;
}
</style>
