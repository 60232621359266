<template>
  <v-sheet class="pa-3" rounded>
    <h2 class="text-h5 mb-4">{{ $t(resultData.title) }}</h2>
    <v-alert
      v-if="resultData.alert"
      :outlined="resultData.alert.type === 'warning'"
      :class="
        resultData.alert.type === 'error'
          ? 'message-wrapper error-message'
          : null
      "
      color="primary"
      :icon="resultData.alert.icon"
    >
      <span class="black--text">
        {{ $t(resultData.alert.message) }}
      </span>
    </v-alert>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";

const results = [
  {
    code: "authorised",
    title: "authorisedResultHeader",
    alert: null,
  },
  {
    code: "cancelled",
    title: "cancelledResultHeader",
    alert: { type: "error", icon: "$alert", message: "cancelledAlert" },
  },
  {
    code: "pending",
    title: "pendingResultHeader",
    alert: { type: "warning", icon: "$alert_circle", message: "pendingAlert" },
  },
  {
    code: "received",
    title: "pendingResultHeader",
    alert: { type: "warning", icon: "$alert_circle", message: "pendingAlert" },
  },
  {
    code: "refused",
    title: "refusedResultHeader",
    alert: { type: "error", icon: "$alert", message: "refusedAlert" },
  },
];

export default {
  name: "PaymentResult",
  data() {
    return {
      resultData: null,
    };
  },
  computed: {
    ...mapState(["result"]),
  },
  created() {
    this.resultData = this.getResultdata(this.result);
  },
  methods: {
    getResultdata(resultCode) {
      const data = results.find((result) => result.code === resultCode);
      if (data) {
        return data;
      }
    },
  },
};
</script>
