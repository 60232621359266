<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div class="booking-summary-roominfo">
        <h4 class="room-name notranslate">{{ optionName }}</h4>
        <ul
          v-if="selectedDatesTypeService && option.range.length"
          class="simple-list"
        >
          <li
            v-for="date in option.range"
            :key="date.date"
            class="d-flex flex-column text-body-2"
          >
            <template v-if="tableReservation">
              <span>
                {{
                  `${formatDate(date.date)}; ${date.time}; ${$t(
                    unitsLabelKey
                  )}: ${date.units}`
                }}</span
              >
              <summary-data-amount
                v-if="date.units * date.price > 0"
                :amount="date.units * date.price"
              ></summary-data-amount>
            </template>
            <span v-else-if="option.chargeType === 14">
              {{ `${formatDate(date.date)}: ` }}
              <summary-data-amount
                v-if="date.price > 0"
                :amount="date.price"
              ></summary-data-amount>
            </span>
            <template v-else>
              <span>
                {{
                  `${formatDate(date.date)}; ${$t(unitsLabelKey)}: ${
                    date.units
                  }`
                }}</span
              >
              <summary-data-amount
                v-if="date.units * date.price > 0"
                :amount="date.units * date.price"
              ></summary-data-amount>
            </template>
          </li>
        </ul>
        <summary-data-amount
          v-else-if="option.total > 0"
          :amount="option.total"
        ></summary-data-amount>
      </div>
      <v-btn
        v-if="step < 3"
        icon
        class="ml-auto align-self-center"
        @click.stop="removeOption"
      >
        <v-tooltip top color="primary">
          <template #activator="{ on }">
            <v-icon color="error" dark v-on="on">$delete</v-icon>
          </template>
          <span>{{ $t("removeOption") }}</span>
        </v-tooltip>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import localizedText from "@/mixins/localizedText";
import SummaryDataAmount from "@/components/SummaryDataAmount";

export default {
  name: "BookedOption",
  components: {
    SummaryDataAmount,
  },
  mixins: [localizedText],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    optionName() {
      return this.getLocalizedText(this.option.serviceData, "name");
    },
    datePickerLocale() {
      if (["nn-NO", "nb-NO"].includes(this.language.locale)) {
        return "no-NO";
      }
      return this.language.locale;
    },
    dateFormatter() {
      const formatter = new Intl.DateTimeFormat(this.datePickerLocale, {
        weekday: "short",
        day: "numeric",
        month: "short",
      });
      return formatter;
    },
    unitsLabelKey() {
      let labelKey = "";
      switch (this.option.chargeType) {
        case 15:
          labelKey = "adults";
          break;
        case 16:
          labelKey = "childen";
          break;
        case 20:
          labelKey = "infants";
          break;
        case 29:
          labelKey = "persons";
          break;
        default:
          break;
      }
      return labelKey;
    },
    selectedDatesTypeService() {
      return [14, 15, 16, 20, 29].includes(this.option.chargeType);
    },
    tableReservation() {
      return this.option.serviceType === 1;
    },
    ...mapState(["step"]),
    ...mapState("language", ["language"]),
  },
  methods: {
    removeOption() {
      this.$root
        .$confirm(
          this.$t("removeOption"),
          this.$t("removeOptionConfirmMessage").format(this.optionName)
        )
        .then((confirmResult) => {
          if (confirmResult) {
            this.$emit("remove-option");
          }
        });
    },
    formatDate(date) {
      return this.dateFormatter.format(new Date(date + "T00:00:00"));
    },
  },
};
</script>
