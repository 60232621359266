<template>
  <v-sheet class="summary-data-wrapper pa-3">
    <dl class="grid-dl text-body-2">
      <dt>{{ $t("hotel") }}</dt>
      <dd class="text-right notranslate">
        {{ hotel.name }}
      </dd>
      <dt>{{ $t("arrivalDate") }}</dt>
      <dd class="text-right notranslate">
        {{ arrivalDate }}
      </dd>
      <dt>{{ $t("nights") }}</dt>
      <dd class="text-right">
        {{ nights }}
      </dd>
      <dt>{{ hotel.ageBuckets.length ? $t("adults") : $t("persons") }}</dt>
      <dd class="text-right">
        {{ totalAdults }}
      </dd>
      <template v-if="totalChildren">
        <dt>{{ $t("children") }}</dt>
        <dd class="text-right">
          {{ totalChildren }}
        </dd>
      </template>
      <template v-if="totalInfants">
        <dt>{{ $t("infants") }}</dt>
        <dd class="text-right">
          {{ totalInfants }}
        </dd>
      </template>
      <dt>{{ $t("rooms") }}</dt>
      <dd class="text-right">
        {{ roomsGuests.length }}
      </dd>
      <template v-if="promoCode">
        <dt>{{ $t("code") }}</dt>
        <dd class="text-right notranslate">
          {{ promoCode }}
        </dd>
      </template>
    </dl>

    <template v-if="roomBookings.length > 0">
      <v-divider class="my-2"></v-divider>
      <div class="booking-summary-rooms">
        <room-booking
          v-for="(booking, idx) in roomBookings"
          :key="`booking_${idx}`"
          :booking="booking"
          :room-number="idx + 1"
          @remove-room="removeRoomBooking(idx)"
        ></room-booking>
      </div>
      <div
        v-if="mandatoryBookingServices.length > 0"
        class="additional-services"
      >
        <v-divider class="my-2"></v-divider>
        <h4>{{ $t("additionalServices") }}</h4>
        <additional-service
          v-for="service in mandatoryBookingServices"
          :key="service.serviceCode"
          :service="service"
        ></additional-service>
      </div>
      <v-divider class="my-2"></v-divider>
      <div class="d-flex justify-space-between py-2 text-h6">
        <span>{{ $t("yourTotal") }}</span>
        <summary-data-amount :amount="total"></summary-data-amount>
      </div>
      <template v-if="showDueNow">
        <v-divider class="my-2"></v-divider>
        <dl class="grid-dl text-body-2">
          <dt>{{ dueNowText }}</dt>
          <dd class="text-right">
            <summary-data-amount :amount="dueNow"></summary-data-amount>
          </dd>
        </dl>
      </template>
    </template>
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from "vuex";
import bookingSummaryData from "@/mixins/bookingSummaryData";
import RoomBooking from "@/components/RoomBooking";
import AdditionalService from "@/components/AdditionalService";
import SummaryDataAmount from "@/components/SummaryDataAmount";

export default {
  name: "BookingSummaryData",
  components: {
    RoomBooking,
    AdditionalService,
    SummaryDataAmount,
  },
  mixins: [bookingSummaryData],
  computed: {
    arrivalDate() {
      if (!(this.stayDates && this.language)) {
        return "";
      }
      const formatter = new Intl.DateTimeFormat(this.datePickerLocale, {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return formatter.format(
        new Date(this.stayDates.arrivalDate + "T00:00:00")
      );
    },
    totalAdults() {
      if (!this.roomsGuests.length) {
        return 0;
      }
      const total = this.roomsGuests
        .map((room) => parseInt(room.adults))
        .reduce((prev, curr) => prev + curr);

      return total;
    },
    totalChildren() {
      if (!this.roomsGuests.length) {
        return 0;
      }
      const total = this.roomsGuests
        .map((room) => parseInt(room.children))
        .reduce((prev, curr) => prev + curr);

      return total;
    },
    totalInfants() {
      if (!this.roomsGuests.length) {
        return 0;
      }
      const total = this.roomsGuests
        .map((room) => parseInt(room.infants))
        .reduce((prev, curr) => prev + curr);

      return total;
    },
    dueNowText() {
      const dueNowText = this.guaranteeNow
        ? this.$t("garanteeNow")
        : this.$t("dueNow");
      return dueNowText;
    },
    showDueNow() {
      const paymentIsOptional = this.roomBookings.some(
        (booking) => booking.rate.paymentMethod.paymentType === 5
      );
      if (paymentIsOptional) {
        return false;
      }
      return this.dueNow > 0;
    },
    datePickerLocale() {
      if (["nn-NO", "nb-NO"].includes(this.language.locale)) {
        return "no-NO";
      }
      return this.language.locale;
    },
    ...mapState(["hotel", "promoCode", "stayDates", "total", "dueNow"]),
    ...mapState("language", ["language"]),
  },
  methods: {
    removeRoomBooking(index) {
      let roomBookings = JSON.parse(JSON.stringify(this.roomBookings));
      roomBookings.splice(index, 1);
      this.setRoomBookings(roomBookings);
    },
    ...mapActions(["setRoomBookings"]),
  },
};
</script>
