<template>
  <span>
    <span class="currency-sign">{{ hotel.currencySign }}</span>
    {{ formattedAmount }}
  </span>
</template>

<script>
import { mapState } from "vuex";
import amounts from "@/mixins/amounts";

export default {
  name: "SummaryDataAmount",
  mixins: [amounts],
  props: {
    amount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedAmount() {
      return this.intNumberFormat.format(this.amount);
    },
    ...mapState(["hotel"]),
  },
};
</script>
