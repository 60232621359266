import { mapState } from "vuex";

export default {
  computed: {
    intNumberFormat() {
      return new Intl.NumberFormat(this.language.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    ...mapState("language", ["language"]),
  },
  methods: {
    getFormattedAmount(amount) {
      const formattedAmount = this.intNumberFormat.format(amount);
      const amountParts = {
        units: formattedAmount.slice(0, formattedAmount.length - 3),
        cents: formattedAmount.slice(-3),
      };
      return amountParts;
    },
  },
};
