<template>
  <div>
    <v-carousel v-model="slide" hide-delimiters>
      <v-carousel-item v-for="image in images" :key="image.id">
        <v-sheet height="100%" tile>
          <v-row class="fill-height" align="center" justify="center">
            <v-img
              max-width="100%"
              :src="image.url.slice(0, -5) + '1.png?' + noImageCache"
            ></v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <p v-if="images[slide].description" class="text-center mb-0">
      {{ images[slide].description }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ImagesCarousel",
  props: {
    images: {
      type: Array,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    slide: 0,
  }),
  computed: {
    ...mapState(["noImageCache"]),
  },
  watch: {
    showDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.slide = 0;
        }
      },
    },
  },
};
</script>
