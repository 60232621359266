function getObjectTextByPropVal(val, array, prop, textProp) {
  const arrItem = array.find((item) => item[prop] === val);
  if (arrItem) {
    return arrItem[textProp];
  } else {
    return "Undefined";
  }
}

function sortRoomAmenities(amenities) {
  const bedGroupIds = [1, 2, 3, 4, 13, 14, 15, 16];
  const bedGroup = [];
  const BathroomGroupIds = [8, 9, 10, 17, 19];
  const BathroomGroup = [];
  const other = [];
  amenities.forEach((id) => {
    if (bedGroupIds.includes(id)) {
      bedGroup.push(id);
    } else if (BathroomGroupIds.includes(id)) {
      BathroomGroup.push(id);
    } else {
      other.push(id);
    }
  });

  return bedGroup
    .sort((a, b) => a - b)
    .concat(
      BathroomGroup.sort((a, b) => a - b),
      other.sort((a, b) => a - b)
    );
}

export { getObjectTextByPropVal, sortRoomAmenities };
