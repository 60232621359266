<template>
  <div class="mb-4">
    <v-divider></v-divider>
    <div class="d-flex">
      <h3 class="text-h6">{{ $t("searchCriteria") }}</h3>
      <v-btn icon class="ml-3" @click.stop="showDialog('searchCriteria')">
        <v-icon color="black" large>$edit</v-icon>
      </v-btn>
    </div>

    <p class="text-body-2 mb-0">{{ dateRangeText }}</p>
    <span class="text-body-2">
      <template v-if="roomsGuests.length > 1">
        <span>{{ roomsGuests.length }}</span>
        <span class="guest-rooms-label">{{ $t("rooms") }}</span>
      </template>

      <span>{{ totalAdults }}</span>
      <span class="guest-rooms-label">{{
        totalAdults > 1
          ? ageBuckets.length
            ? $t("adults")
            : $t("persons")
          : ageBuckets.length
          ? $t("adult")
          : $t("person")
      }}</span>
      <template v-if="totalInfantsAndChildren">
        <span>{{ totalInfantsAndChildren }}</span>
        <span class="guest-rooms-label"
          >{{ totalInfantsAndChildren > 1 ? $t("children") : $t("child") }}
        </span>
      </template>
    </span>
    <p v-if="showPromoCodeInput && !!promoCode" class="text-body-2 mb-0">
      {{ $t("promoCode") }} : {{ promoCode }}
    </p>
    <v-divider></v-divider>
    <default-dialog
      v-if="selectedDialog"
      :show-dialog="selectedDialog.show"
      :width="selectedDialog.width"
      :title="$t(selectedDialog.titleKey)"
      :include-actions="selectedDialog.includeActions"
      @close-dialog="closeDialog"
    >
      <template #dialog-content>
        <component
          :is="selectedDialog.content"
          :ref="selectedDialog.ref"
          :show-dialog="selectedDialog.show"
          :stay-dates="stayDates"
          :age-buckets="ageBuckets"
          :show-promo-code-input="showPromoCodeInput"
          @search-rooms="searchRooms"
          @close-dialog="closeDialog"
        ></component>
      </template>
    </default-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dialogs from "@/mixins/dialogs";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import SearchCriteriaDialog from "@/components/dialogs/SearchCriteriaDialog";

export default {
  name: "SearchCriteriaMobile",
  components: {
    DefaultDialog,
    SearchCriteriaDialog,
  },
  mixins: [dialogs],
  props: {
    stayDates: {
      type: Array,
      required: true,
    },
    showPromoCodeInput: {
      type: Boolean,
      required: true,
    },
    ageBuckets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    dateRangeText() {
      const dates = this.stayDates;
      const formatter = new Intl.DateTimeFormat(this.language.locale, {
        day: "numeric",
        month: "short",
      });
      return dates
        .map((date) => formatter.format(new Date(date + "T00:00:00")))
        .join(" ~ ");
    },
    totalAdults() {
      const total = this.roomsGuests
        .map((room) => parseInt(room.adults))
        .reduce((prev, curr) => prev + curr);

      return total;
    },
    totalChildren() {
      const total = this.roomsGuests
        .map((room) => parseInt(room.children))
        .reduce((prev, curr) => prev + curr);

      return total;
    },
    totalInfants() {
      const total = this.roomsGuests
        .map((room) => parseInt(room.infants))
        .reduce((prev, curr) => prev + curr);

      return total;
    },
    totalInfantsAndChildren() {
      return this.totalChildren + this.totalInfants;
    },
    ...mapState(["roomsGuests", "promoCode"]),
    ...mapState("language", ["language"]),
  },
  methods: {
    searchRooms(criteria) {
      this.$emit("update:search-criteria", criteria);
    },
  },
};
</script>
