<template>
  <div class="room-wrapper" :data-room-code="room.roomCode">
    <div class="d-md-flex">
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-md-flex">
          <room-image
            v-if="roomImage"
            :available="available"
            :image="roomImage"
            :show-carousel-button="room.images.length > 1"
            @show-room-images="
              $emit('show-room-images', { images: images, name: roomName })
            "
          ></room-image>
          <room-info
            :name="roomName"
            :room-amenities="room.amenities"
            @toggle-description="showRoomDescription = !showRoomDescription"
          ></room-info>
        </div>
        <room-description
          :description="roomDescription"
          :show-description="showRoomDescription"
        ></room-description>
      </div>
      <room-best-available-rate
        :room="room"
        :occupancy="occupancy"
        :show-room-rates="showRoomRates"
        @toggle-show-room-rates="toggleShowRoomRates"
        @select-package-room="selectRoomRate"
      ></room-best-available-rate>
    </div>
    <v-expand-transition>
      <div v-show="showRoomRates" class="room-rates-wrapper pt-4 pr-6">
        <v-list dense color="transparent" class="py-0">
          <room-rate
            v-for="(rate, idx) in room.rates"
            :key="rate.rateCode"
            :rate="rate"
            :last-item="idx === room.rates.length - 1"
            @select-room-rate="selectRoomRate"
          ></room-rate>
        </v-list>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import dialogs from "@/mixins/dialogs";
import localizedText from "@/mixins/localizedText";
import { mapState, mapActions } from "vuex";
import RoomRate from "@/components/RoomRate";
import RoomImage from "@/components/RoomImage";
import RoomInfo from "@/components/RoomInfo";
import RoomDescription from "@/components/RoomDescription";
import RoomBestAvailableRate from "@/components/RoomBestAvailableRate";

export default {
  name: "IbeRoom",
  components: {
    RoomImage,
    RoomInfo,
    RoomDescription,
    RoomBestAvailableRate,
    RoomRate,
  },
  mixins: [dialogs, localizedText],
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showRoomRates: false,
    showRoomDescription: false,
  }),
  computed: {
    roomImage() {
      let image = null;
      if (this.room.images.length > 0) {
        image = this.room.images.find((image) => image.imageType === 1);
      }
      return image;
    },
    roomName() {
      return this.getLocalizedText(this.room.roomData, "name");
    },
    roomDescription() {
      return this.getLocalizedText(this.room.roomData, "description");
    },
    available() {
      return this.room.available && this.room.rates.length > 0;
    },
    images() {
      const images = this.room.images.map((image) => {
        return {
          id: image.id,
          url: image.imageURL.split("?")[0].slice(0, -5) + "2.png",
          description: this.getLocalizedText(image.imageData, "name"),
        };
      });
      return images;
    },
    roomHasChildRates() {
      if (!this.room.rates.length || !this.room.rates[0].rates.length) {
        return false;
      }
      return this.room.rates[0].rates[0].amounts.some(
        (amount) => amount.aqc === 8 && amount.amt > 0
      );
    },
    occupancy() {
      if (this.roomHasChildRates) {
        return this.roomToSelect.adults;
      }
      return this.roomToSelect.adults + this.roomToSelect.children;
    },
    ...mapState([
      "hotel",
      "roomBookings",
      "nights",
      "stayDates",
      "roomToSelect",
    ]),
  },
  methods: {
    toggleShowRoomRates() {
      this.showRoomRates = !this.showRoomRates;
      if (this.showRoomRates) {
        this.scrollRoomRatesIntoView(this.room.roomCode);
      }
    },
    scrollRoomRatesIntoView(roomCode) {
      if (!this.isMobile)
        setTimeout(() => {
          this.$vuetify.goTo(
            ".selectable-rooms-wrapper .room-wrapper[data-room-code='" +
              roomCode +
              "'] .room-rates",
            {
              offset: 0,
            }
          );
        }, 300);
      else {
        setTimeout(() => {
          this.$vuetify.goTo(
            ".room-wrapper[data-room-code='" +
              roomCode +
              "'] .room-name-wrapper",
            {
              offset: 0,
            }
          );
        }, 300);
      }
    },
    selectRoomRate(rate) {
      const room = JSON.parse(JSON.stringify(this.room));
      const roomBooking = {
        room: {
          code: room.roomCode,
          roomData: room.roomData,
          image: this.roomImage,
          name: room.name,
        },
        rate: {
          rateData: rate.staticRateData.rateData,
          total: rate.total,
          nettTotal: rate.nettTotal,
          discount: rate.discount,
          code: rate.rateCode,
          rates: rate.rates,
          inclusions: rate.staticRateData.inclusions,
          paymentMethod: rate.staticRateData.paymentMethod,
        },
        services: [],
        availableServices: room.availableServices,
      };
      roomBooking.id = JSON.parse(JSON.stringify(this.roomToSelect.id));
      roomBooking.selectedRoom = JSON.parse(JSON.stringify(this.roomToSelect));
      const roomBookings = JSON.parse(JSON.stringify(this.roomBookings));
      roomBookings.push(roomBooking);
      this.setRoomBookings(roomBookings.sort((a, b) => a.id - b.id));
      if (this.$gtm && this.$gtm.enabled()) {
        const dataLayerEvent = {
          event: "add_to_cart",
          currency: this.hotel.currencySign,
          value: roomBooking.rate.total,
          items: [
            {
              arrivalDate: this.stayDates.arrivalDate,
              quantity: this.nights,
              item_category: roomBooking.rate.code,
              item_id: roomBooking.room.code,
              item_name: roomBooking.room.name,
              price: roomBooking.rate.total,
            },
          ],
        };
        window.dataLayer?.push(JSON.parse(JSON.stringify(dataLayerEvent)));
      }
    },
    ...mapActions(["setRoomBookings"]),
  },
};
</script>
