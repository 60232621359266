var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","max-height":220},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize language-switch-button ml-2",class:{
        mobile: _vm.isMobile,
      },attrs:{"outlined":"","fab":_vm.isMobile,"depressed":"","small":!_vm.isMobile,"x-small":_vm.isMobile,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":!_vm.isMobile}},[_vm._v("mdi-translate")]),(!_vm.isMobile)?_c('span',{staticClass:"black--text selected-language notranslate"},[_vm._v(_vm._s(_vm.language.name))]):_vm._e()],1)]}}]),model:{value:(_vm.languageMenu),callback:function ($$v) {_vm.languageMenu=$$v},expression:"languageMenu"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.languages),function(lang,index){return [_c('v-list-item',{key:lang.locale,class:{
          'v-list-item--highlighted primary--text':
            lang.name === _vm.language.name,
        },on:{"click":function($event){return _vm.selectLanguage(lang)}}},[_c('v-list-item-title',{staticClass:"notranslate"},[_vm._v(_vm._s(lang.name)+" ")])],1),(index < _vm.languages.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }