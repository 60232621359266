<template>
  <v-select
    v-bind="$attrs"
    :validate-on-blur="validateOnBlur"
    :outlined="outlined"
    :dense="dense"
    :menu-props="{ offsetY: true, maxHeight: 160 }"
    color="black"
    background-color="white"
    v-on="$listeners"
  >
    <template v-if="translateItems" #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ $t(item.key) }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-if="translateItems" #selection="{ item }">
      <span class="selected-option">{{ $t(item.key) }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "DefaultSelect",
  inheritAttrs: false,
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
    translateItems: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
