<template>
  <v-dialog
    ref="defaultDialog"
    :value="showDialog"
    :width="width"
    max-width="1000"
    :transition="isMobile ? 'dialog-bottom-transition' : 'scale-transition'"
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    persistent
    content-class="default-dialog"
  >
    <v-btn
      v-if="!hideCloseButton"
      icon
      class="close-dialog-button"
      @click="closeDialog"
    >
      <v-icon large color="black">$close</v-icon>
    </v-btn>
    <v-card class="d-flex flex-column" @close-dialog="closeDialog">
      <v-img
        v-if="useLogo"
        class="ml-2 mt-2"
        :src="logo_src"
        max-width="220"
      ></v-img>
      <template v-else>
        <v-card-title class="pr-8">
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-divider></v-divider>
      </template>

      <template v-if="includeActions">
        <slot name="dialog-content"></slot>
      </template>
      <v-card-text v-else class="pa-3 pa-sm-5">
        <slot name="dialog-content"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DefaultDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: "500",
    },
    title: {
      type: String,
      required: true,
    },
    includeActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    useLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    logo_src: require("@/assets/SmartIBE-logo.png"),
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  created() {},
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
