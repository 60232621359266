<template>
  <div class="room-wrapper">
    <div class="d-md-flex">
      <div
        v-if="packageImage"
        :class="{
          mobile: $vuetify.breakpoint.smAndDown,
        }"
        class="room-image available"
      >
        <v-img max-width="100%" :src="packageImage">
          <v-btn
            v-if="packageData.images.length > 1"
            icon
            @click.stop="
              $emit('show-package-images', {
                images: images,
                name: packageName,
              })
            "
          >
            <v-tooltip top color="primary">
              <template #activator="{ on }">
                <v-icon color="white" dark v-on="on">$plus</v-icon>
              </template>
              <span>{{ $t("showPackageImages") }}</span>
            </v-tooltip>
          </v-btn>
        </v-img>
      </div>
      <div class="room-info mx-sm-3 d-flex flex-column flex-grow-1">
        <h3 class="my-2 mt-sm-0">
          <span class="mr-2 notranslate"> {{ packageName }}</span>
          <text-popover
            v-if="packagePolicy"
            :text="packagePolicy"
          ></text-popover>
        </h3>
        <p class="mb-sm-0 show-line-breaks">{{ packageDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import localizedText from "@/mixins/localizedText";
import TextPopover from "@/components/globals/TextPopover";
export default {
  name: "PackageInfo",
  components: {
    TextPopover,
  },
  mixins: [localizedText],
  computed: {
    packageName() {
      return this.getLocalizedText(this.packageData.rateData, "name");
    },
    packageDescription() {
      return this.getLocalizedText(this.packageData.rateData, "description");
    },
    packagePolicy() {
      return this.getLocalizedText(this.packageData.rateData, "policy");
    },
    packageImage() {
      let imageUrl = "";
      if (this.packageData.images.length > 0) {
        const image = this.packageData.images.find(
          (image) => image.imageType === 6
        );
        if (image) {
          imageUrl = image.imageURL.slice(0, -5) + "2.png?" + this.noImageCache;
        }
      }
      return imageUrl;
    },
    images() {
      const images = this.packageData.images.map((image) => {
        return {
          id: image.id,
          url: image.imageURL.split("?")[0].slice(0, -5) + "2.png",
          description: this.getLocalizedText(image.imageData, "name"),
        };
      });
      return images;
    },
    ...mapState(["packageData", "noImageCache"]),
  },
};
</script>
