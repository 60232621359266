import axios from "axios";

function axiosPost(url, data, token) {
  return axios
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    });
}

function axiosGet(url, token) {
  let headers = {};
  if (token) {
    headers = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
  }
  return axios
    .get(url, headers)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    });
}

export { axiosPost, axiosGet };
