<template>
  <div id="adyen-dropin"></div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { axiosPost } from "@/api/requests";
import snackBarMessages from "@/mixins/snackBarMessages";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

export default {
  name: "AdyenDropin",
  mixins: [snackBarMessages],
  props: {
    paymentMethods: {
      type: Object,
      required: true,
    },
    paymentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropin: null,
      paymentChallengePaymentData: null,
    };
  },
  computed: {
    translations() {
      if (!this.preAuth) {
        return {};
      }
      return {
        "en-US": {
          payButton: "Confirm pre-authorization of ",
        },
        "en-GB": {
          payButton: "Confirm pre-authorization of ",
        },
        "nl-NL": {
          payButton: "Bevestig pre-autorisatie van ",
        },
        "de-DE": {
          payButton: "Vorautorisierung von ",
        },
      };
    },
    ...mapState(["token", "preAuth", "resId", "hotel", "segment"]),
    ...mapState("language", ["language", "languages"]),
  },
  mounted() {
    this.initAdyenCheckout();
    this.$emit("refusal-alert", { show: false });
  },
  methods: {
    initAdyenCheckout() {
      const configuration = {
        locale: this.language.locale,
        translations: this.translations,
        environment: process.env.VUE_APP_ADYEN_ENV,
        clientKey: process.env.VUE_APP_CLIENT_KEY,
        paymentMethodsResponse: this.paymentMethods,
        amount: {
          value: this.paymentData.Amount,
          currency: this.paymentData.CurrencyCode,
        },
        onSubmit: (state, dropin) => {
          this.paymentCheckout(state.data.paymentMethod);
        },
        onAdditionalDetails: (state, dropin) => {
          this.handleAdditionalDetails(state.data.details.threeDSResult);
        },
        allowPaymentMethods: [],
        removePaymentMethods: [],
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            positionHolderNameOnTop: false,
            holderNameRequired: true,
            enableStoreDetails: false,
            hideCVC: false,
            onSubmit: (state, dropin) => {
              dropin.setStatus("loading");
              this.ccPayRequest(state.data);
            },
          },
        },
      };

      const checkout = new AdyenCheckout(configuration);

      this.dropin = checkout
        .create("dropin", {
          openFirstPaymentMethod:
            this.paymentData.PaymentMethod ===
            this.paymentMethods.paymentMethods[0].type,
        })
        .mount("#adyen-dropin");
    },
    paymentCheckout(paymentMethod) {
      const url = process.env.VUE_APP_IBE_URL + "/pay/checkout/";
      const data = {
        Environment: this.paymentData.Environment,
        HotelCode: this.paymentData.HotelCode,
        OrderID: this.paymentData.OrderID,
        ReservationID: this.paymentData.ReservationID,
        CountryCode: this.paymentData.CountryCode,
        Locale: this.language.locale,
        CurrencyCode: this.paymentData.CurrencyCode,
        Amount: this.paymentData.Amount,
        Checkin: this.paymentData.Checkin,
        Checkout: this.paymentData.Checkout,
      };
      switch (paymentMethod.type) {
        case "ideal":
          data.OnlineBanking = {
            OnlineType: "ideal",
            Issuer: paymentMethod.issuer,
          };
          break;
        case "giropay":
          data.OnlineBanking = {
            OnlineType: "giropay",
            Issuer: "",
          };
          break;
        case "mobilepay":
          data.OnlineBanking = {
            OnlineType: "mobilepay",
            Issuer: "",
          };
          break;
        case "vipps":
          data.Wallet = {
            PaymentMethod: "vipps",
          };
          break;
      }
      axiosPost(url, data, this.token)
        .then((response) => {
          this.handlePaymentCheckout(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ccPayRequest(cardData) {
      this.$emit("refusal-alert", { show: false });
      cardData.browserInfo.acceptHeader =
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8";
      const creditcard = {
        HolderName: cardData.paymentMethod.holderName,
        Number: cardData.paymentMethod.encryptedCardNumber,
        CVC: cardData.paymentMethod.encryptedSecurityCode,
        ExpiryMonth: cardData.paymentMethod.encryptedExpiryMonth,
        ExpiryYear: cardData.paymentMethod.encryptedExpiryYear,
      };
      const url = process.env.VUE_APP_IBE_URL + "/pay/checkout/";
      const data = {
        Environment: this.paymentData.Environment,
        HotelCode: this.paymentData.HotelCode,
        OrderID: this.paymentData.OrderID,
        ReservationID: this.paymentData.ReservationID,
        CountryCode: this.paymentData.CountryCode,
        Locale: this.language.locale,
        CurrencyCode: this.paymentData.CurrencyCode,
        Amount: this.paymentData.Amount,
        Checkin: this.paymentData.Checkin,
        Checkout: this.paymentData.Checkout,
        CreditCard: creditcard,
        Secure3DS: {
          shopperIP: this.paymentData.GuestIP,
          origin: process.env.VUE_APP_ENV_URL,
          redirectUrl: process.env.VUE_APP_ENV_URL,
          shopperEmail: this.paymentData.Email,
        },
        browserInfo: cardData.browserInfo,
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          this.handlePaymentCheckout(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handlePaymentCheckout(response) {
      const action = response.action;
      if (action) {
        if (action.paymentData) {
          this.paymentChallengePaymentData = action.paymentData;
        }
        this.dropin.handleAction(response.action);
      } else if (
        response.resultCode.toLowerCase() === "authorised" ||
        response.resultCode.toLowerCase() === "received"
      ) {
        this.setPaidStatus();
      } else {
        this.handleRefusalReason(response);
      }
    },
    handleAdditionalDetails(code) {
      const url = process.env.VUE_APP_IBE_URL + "/pay/paystatus/";
      const data = {
        type: "ThreeDS",
        data: code,
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          this.handleGetPaymentCheckoutStatus(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleRefusalReason(details) {
      this.$emit("refusal-alert", {
        show: true,
        refusalReasonCode: details.refusalReasonCode,
      });
      this.dropin.setStatus("ready");
    },
    handleGetPaymentCheckoutStatus(response) {
      if (response.pspStatus.toLowerCase() === "authorised") {
        this.setPaidStatus();
      } else if (response.pspStatus.toLowerCase() === "refused") {
        this.handleRefusalReason(response);
      }
    },
    setPaidStatus() {
      const url = process.env.VUE_APP_IBE_URL + "/pay/paid ";
      const data = {
        Environment: this.hotel.environment,
        HotelCode: this.hotel.code,
        Segment: this.segment,
        reservationID: this.resId,
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          if (response.success) {
            this.setStep(6);
          } else {
            this.snackbarError(this.$t("undefinedError"));
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
        })
        .finally(() => {});
    },
    ...mapActions(["setResult", "setStep"]),
  },
};
</script>
