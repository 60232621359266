function initRoomsGuests(rooms, adults, children, promocode) {
  let numberOfrooms = 1;
  let roomGuests = [{ id: 1, adults: 2, children: 0, infants: 0 }];
  if (rooms) {
    numberOfrooms = Number(rooms);
    if (adults) {
      if (numberOfrooms <= adults) {
        const guestsPerRoom = Math.floor(adults / numberOfrooms);
        const childrenPerRoom = Math.floor(children / numberOfrooms);
        roomGuests = [...Array(numberOfrooms)].map((room, idx) => {
          return {
            id: idx + 1,
            adults: guestsPerRoom,
            children: childrenPerRoom,
            infants: 0,
          };
        });
        const remainingAdults = adults - guestsPerRoom * numberOfrooms;
        if (remainingAdults > 0) {
          for (let a = 0; a < remainingAdults; a++) {
            roomGuests[a].adults++;
          }
        }
        const remainingChildren = children - childrenPerRoom * numberOfrooms;
        if (remainingChildren > 0) {
          for (let c = 0; c < remainingChildren; c++) {
            roomGuests[c].children++;
          }
        }
      }
    } else {
      roomGuests = [...Array(numberOfrooms)].map((room, idx) => {
        return {
          id: idx + 1,
          adults: 1,
          children: 0,
          infants: 0,
        };
      });
    }
  } else if (adults) {
    numberOfrooms = adults <= 3 ? 1 : Math.ceil(adults / 2);

    if (numberOfrooms === 1) {
      roomGuests = [
        {
          id: 1,
          adults: adults,
          children: children,
          infants: 0,
        },
      ];
    } else {
      roomGuests = [...Array(numberOfrooms)].map((room, idx) => {
        return {
          id: idx + 1,
          adults: 2,
          children: 0,
          infants: 0,
        };
      });
      if (adults % 2 === 1) {
        roomGuests[roomGuests.length - 1].adults = 1;
      }
    }

    if (children) {
      let numberOfChilrenRooms = children <= 2 ? 1 : Math.ceil(children / 2);
      if (numberOfChilrenRooms > numberOfrooms) {
        numberOfChilrenRooms = numberOfrooms;
      }
      if (numberOfChilrenRooms === 1) {
        roomGuests[0].children = children;
      } else {
        for (let i = 0; i < numberOfChilrenRooms; i++) {
          roomGuests[i].children = 2;
        }
        if (children % 2 === 1) {
          roomGuests[numberOfChilrenRooms - 1].children = 1;
        }
      }
    }
  }
  roomGuests.forEach((room) => {
    room.promoCode = promocode;
  });
  return roomGuests;
}

export { initRoomsGuests };
