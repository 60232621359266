<template>
  <span class="text-body-1">
    <span
      >(<span>{{
        `${$t("room")} ${roomNumber} / ${roomsGuests.length} : `
      }}</span>
      {{
        roomToSelect.adults > 1
          ? hotel.ageBuckets.length
            ? $t("numberOfAdults").format(roomToSelect.adults)
            : $t("numberOfPersons").format(roomToSelect.adults)
          : hotel.ageBuckets.length
          ? $t("1adult")
          : $t("1person")
      }}</span
    >
    <span v-if="roomToSelect.children"
      >,
      {{
        roomToSelect.children > 1
          ? $t("numberOfChildren").format(roomToSelect.children)
          : $t("1child")
      }}</span
    >
    <span v-if="roomToSelect.infants"
      >,
      {{
        roomToSelect.infants > 1
          ? $t("numberOfInfants").format(roomToSelect.infants)
          : $t("1infant")
      }}</span
    >)
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RoomToSelectInfo",
  computed: {
    roomNumber() {
      if (!this.roomToSelect) {
        return 0;
      }
      let roomIndex = this.roomsGuests.findIndex(
        (room) => room.id === this.roomToSelect.id
      );
      return roomIndex + 1;
    },
    ...mapState(["hotel", "roomsGuests", "roomToSelect"]),
  },
};
</script>
