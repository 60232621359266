<template>
  <div
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
    }"
    class="room-rates d-flex flex-column flex-sm-row justify-sm-end flex-md-column justify-md-center align-center"
  >
    <div class="d-flex flex-column justify-center align-center pr-sm-8 pr-md-0">
      <div class="best-roomrate d-flex flex-column my-1">
        <div class="best-roomrate-amount">
          <span class="text-caption">{{ $t("from") }}</span>
          <span class="currency-sign mx-2">{{ hotel.currencySign }}</span>
          <span class="text-h4">{{
            getFormattedAmount(packageRate).units
          }}</span
          ><span class="ml-n1">
            {{ getFormattedAmount(packageRate).cents }}</span
          >
        </div>
        <span
          class="text-caption ml-auto mt-n2"
          :class="{
            'font-weight-black red--text text--accent-4': minLos !== nights,
          }"
        >
          {{
            minLos > 1
              ? minLos + " " + $t("nights_plur")
              : minLos + " " + $t("night")
          }}
        </span>
      </div>
    </div>
    <v-btn
      v-if="!hideRoomsButton"
      class="align-self-sm-end align-self-md-center"
      color="primary"
      @click="$emit('show-package-rooms-view')"
      >{{ $t("rooms") }}
      <v-icon large right>$arrow_right</v-icon>
    </v-btn>
  </div>
</template>

<script>
import amounts from "@/mixins/amounts";
import { mapState } from "vuex";

export default {
  name: "PackageBestAvailableRate",
  mixins: [amounts],
  props: {
    packageRate: {
      type: Number,
      required: true,
    },
    minLos: {
      type: Number,
      required: true,
    },
    hideRoomsButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(["hotel", "nights"]),
  },
};
</script>
