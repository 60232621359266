<template>
  <v-footer app padless elevation="4" color="#ffffff" class="d-block">
    <div class="d-flex justify-space-between py-1 px-3">
      <h2 class="text-h6">
        {{ $t("bookingSummary") }}
      </h2>
      <v-btn icon color="black" @click.stop="showDialog('bookingSummary')">
        <v-icon>$arrow_up_circle</v-icon>
      </v-btn>
    </div>

    <v-divider></v-divider>
    <div class="text-body-2 text-center py-1">
      <span>SmartIBE powered by SmartHOTEL</span>
      <span class="d-inline-block">
        <v-icon small color="grey darken-3">mdi-copyright</v-icon> (<a
          href="https://www.smarthotel.nl/privacy/"
          target="_blank"
          >privacy policy</a
        >)
      </span>
    </div>
    <default-dialog
      v-if="selectedDialog"
      :show-dialog="selectedDialog.show"
      :width="selectedDialog.width"
      :title="$t(selectedDialog.titleKey)"
      :include-actions="selectedDialog.includeActions"
      @close-dialog="closeDialog"
    >
      <template #dialog-content>
        <component
          :is="selectedDialog.content"
          :ref="selectedDialog.ref"
          :show-dialog="selectedDialog.show"
          @close-dialog="closeDialog"
        ></component>
      </template>
    </default-dialog>
  </v-footer>
</template>

<script>
import dialogs from "@/mixins/dialogs";
import bookingSummaryData from "@/mixins/bookingSummaryData";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import BookingSummaryDialog from "@/components/dialogs/BookingSummaryDialog";

export default {
  name: "AppFooter",
  components: {
    DefaultDialog,
    BookingSummaryDialog,
  },
  mixins: [dialogs, bookingSummaryData],
};
</script>
