<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    nudge-top="4"
    max-width="290"
  >
    <template #activator="{ on }">
      <v-text-field
        ref="dateRangePickerTextField"
        :value="dateRangeText"
        :label="label"
        :rules="rules"
        :disabled="disabled"
        color="black"
        background-color="white"
        readonly
        outlined
        dense
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-card :loading="loading">
      <v-date-picker
        v-model="dates"
        :min="min"
        :max="max"
        no-title
        range
        color="primary"
        :first-day-of-week="1"
        :disabled="loading"
        :allowed-dates="allowedDates"
        :locale="datePickerLocale"
        :picker-date.sync="pickerDate"
        @click:date="selectDate"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!validDaterange"
          @click="show = false"
        >
          {{ $t("done") }}</v-btn
        >
      </v-date-picker>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { format, addDays } from "date-fns";
import isMobile from "@/mixins/isMobile";

export default {
  name: "DateRangePicker",
  mixins: [isMobile],
  props: {
    label: {
      type: String,
      required: true,
    },
    itemDates: {
      type: Array,
      required: true,
    },
    min: {
      type: String,
      required: false,
      default: null,
    },
    max: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    fixedRange: {
      type: Number,
      required: true,
    },
    disabledDates: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dates: [],
      show: false,
      pickerDate: null,
    };
  },
  computed: {
    dateRangeText() {
      const dates = this.dates;
      if (dates.length === 0) {
        return "";
      }
      const formatData = this.isMobile
        ? { day: "numeric", month: "short" }
        : { weekday: "short", day: "numeric", month: "short", year: "numeric" };
      const formatter = new Intl.DateTimeFormat(
        this.datePickerLocale,
        formatData
      );
      return dates
        .map((date) => formatter.format(new Date(date + "T00:00:00")))
        .join(" ~ ");
    },
    validDaterange() {
      return this.dates.length === 2;
    },
    rules() {
      return [
        (v) => !!v || this.$t("invalidDateRange"),
        (v) => this.validDaterange || this.$t("invalidDateRange"),
      ];
    },
    datePickerLocale() {
      if (["nn-NO", "nb-NO"].includes(this.language.locale)) {
        return "no-NO";
      }
      return this.language.locale;
    },
    ...mapState("language", ["language"]),
  },
  watch: {
    itemDates: {
      immediate: true,
      deep: true,
      handler(val) {
        this.dates = JSON.parse(JSON.stringify(val));
      },
    },
    show(val) {
      if (!val) {
        this.$emit("update:dates", this.dates);
      }
      this.$emit("update:date-picker", val);
    },
    pickerDate(val) {
      if (val.length === 7) {
        this.$emit("get-dates-availablility", val);
      }
    },
  },
  methods: {
    selectDate(date) {
      if (this.fixedRange) {
        this.dates = [
          date,
          format(addDays(date, this.fixedRange), "YYYY-MM-DD"),
        ];
      }
      if (this.dates.length === 2 && this.dates[0] === this.dates[1]) {
        this.dates = [this.dates[0]];
      }
      this.$emit("update:dates", this.dates);
    },
    allowedDates(val) {
      return this.disabledDates.indexOf(val) === -1;
    },
  },
};
</script>
