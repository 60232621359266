<template>
  <v-container class="details-section pa-0 pb-0 mb-4">
    <div class="room-wrapper">
      <div class="d-flex">
        <div class="room-image available d-none d-sm-block">
          <v-img
            v-if="roomBooking.room.image"
            max-width="100%"
            :src="
              roomBooking.room.image.imageURL.slice(0, -5) +
              '2.png?' +
              noImageCache
            "
          >
          </v-img>
        </div>
        <div class="room-info mx-sm-2 d-flex flex-column">
          <h3 class="mb-2 notranslate">
            {{ getLocalizedText(roomBooking.room.roomData, "name") }}
          </h3>
          <v-checkbox
            v-model="bookerIsGuest"
            hide-details
            color="black"
            class="mt-0"
          >
            <template #label>{{ $t("bookerIsGuestLabel") }}</template>
          </v-checkbox>
          <div v-if="showChildrenAges" class="d-flex mt-auto">
            <h4 class="mt-2 mr-3">{{ $t("childrenAges") }}</h4>
            <default-select
              v-for="(child, idx) in roomBooking.selectedRoom.children"
              :key="roomBooking.id + '_child_' + (idx + 1)"
              v-model="guestDetails.childAges[idx]"
              :items="childAges"
              :label="$t('child') + ' ' + child"
              :rules="validationRules('childAge')"
              persistent-placeholder
              class="mx-2 child-age-select"
              hide-details
            >
            </default-select>
            <default-select
              v-for="(infant, idx) in roomBooking.selectedRoom.infants"
              :key="roomBooking.id + '_infant_' + (idx + 1)"
              v-model="
                guestDetails.childAges[roomBooking.selectedRoom.children + idx]
              "
              :items="infantAges"
              :label="$t('infant') + ' ' + infant"
              :rules="validationRules('childAge')"
              persistent-placeholder
              class="mx-2 child-age-select"
              hide-details
            >
            </default-select>
          </div>
        </div>
      </div>
    </div>
    <v-expand-transition>
      <v-container v-if="!bookerIsGuest" class="">
        <v-row>
          <v-col cols="12" sm="6" class="pt-0 pb-1">
            <default-text-field
              v-model.trim="guestDetails.firstName"
              :label="`${$t('firstName')} *`"
              :rules="validationRules('firstName')"
            >
            </default-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-1">
            <default-text-field
              v-model.trim="guestDetails.surName"
              :label="`${$t('surName')} *`"
              :rules="validationRules('surName')"
            >
            </default-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pt-0 pb-1">
            <default-text-field
              v-model.trim="guestDetails.phoneNumber"
              :label="`${$t('phoneNumber')} *`"
              :rules="phoneNumberValidationRules"
            >
            </default-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-1">
            <default-text-field
              v-model.trim="guestDetails.email"
              :label="`${$t('emailAddress')} *`"
              :rules="validationRules('email')"
            >
            </default-text-field>
          </v-col>
        </v-row>
        <small>* {{ $t("requiredFieldError") }}</small>
      </v-container>
    </v-expand-transition>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import localizedText from "@/mixins/localizedText";
import DefaultTextField from "@/components/formFields/DefaultTextField";
import DefaultSelect from "@/components/formFields/DefaultSelect";

export default {
  name: "GuestDetails",
  components: {
    DefaultTextField,
    DefaultSelect,
  },
  mixins: [localizedText],
  props: {
    roomBooking: {
      type: Object,
      required: true,
    },
    bookerIsGuestRoom: {
      type: Number,
      required: true,
    },
    bookerCountryCode: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    bookerIsGuest: false,
    guestDetails: {
      firstName: "",
      surName: "",
      phoneNumber: "",
      email: "",
      childAges: [],
    },
  }),
  computed: {
    showChildrenAges() {
      return (
        (this.roomBooking.selectedRoom.children > 0 ||
          this.roomBooking.selectedRoom.infants > 0) &&
        this.hotel.settings.some(
          (setting) => setting.settingType === 5 && setting.settingValue === 1
        )
      );
    },
    childAges() {
      const childBucket = this.hotel.ageBuckets.find(
        (bucket) => bucket.aqc === 8
      );
      if (!childBucket) {
        return [];
      }
      return Array.from(
        { length: childBucket.maxAge - childBucket.minAge + 1 },
        (value, index) => childBucket.minAge + index
      );
    },
    infantAges() {
      const infantBucket = this.hotel.ageBuckets.find(
        (bucket) => bucket.aqc === 7
      );
      if (!infantBucket) {
        return [];
      }
      return Array.from(
        { length: infantBucket.maxAge - infantBucket.minAge + 1 },
        (value, index) => infantBucket.minAge + index
      );
    },
    phoneNumberValidationRules() {
      const validatePhoneNumberSetting = this.hotel.settings.find(
        (setting) => setting.settingType === 12
      );
      if (
        !validatePhoneNumberSetting ||
        !validatePhoneNumberSetting.settingValue
      ) {
        return [(v) => !!v || "requiredFieldError"];
      }
      return [
        (v) => !!v || "requiredFieldError",
        (v) => ["0", "+"].includes(v[0]) || "invalidPhoneNumber",
      ];
    },
    ...mapState(["noImageCache", "hotel"]),
  },
  watch: {
    bookerIsGuestRoom(val) {
      if (val === this.roomBooking.id) {
        this.bookerIsGuest = true;
      } else {
        this.bookerIsGuest = false;
      }
    },
    bookerIsGuestRoom: {
      immediate: true,
      handler(val) {
        if (val === this.roomBooking.id) {
          this.bookerIsGuest = true;
        } else {
          this.bookerIsGuest = false;
        }
      },
    },
    bookerIsGuest(val) {
      if (val) {
        this.$emit("update:booker-is-guest-room", this.roomBooking.id);
      }
    },
  },
  methods: {
    validationRules(fieldname) {
      switch (fieldname) {
        case "firstName":
        case "surName":
        case "email":
          return [(v) => !!v || "requiredFieldError"];
        case "childAge":
          return [(v) => !!v || v === 0 || "requiredFieldError"];
      }
    },
  },
};
</script>
