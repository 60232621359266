<template>
  <v-menu v-model="languageMenu" offset-y :max-height="220">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-capitalize language-switch-button ml-2"
        :class="{
          mobile: isMobile,
        }"
        outlined
        :fab="isMobile"
        depressed
        :small="!isMobile"
        :x-small="isMobile"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :left="!isMobile">mdi-translate</v-icon>
        <span
          v-if="!isMobile"
          class="black--text selected-language notranslate"
          >{{ language.name }}</span
        >
      </v-btn>
    </template>
    <v-list dense>
      <template v-for="(lang, index) in languages">
        <v-list-item
          :key="lang.locale"
          :class="{
            'v-list-item--highlighted primary--text':
              lang.name === language.name,
          }"
          @click="selectLanguage(lang)"
        >
          <v-list-item-title class="notranslate"
            >{{ lang.name }}
          </v-list-item-title>
        </v-list-item>
        <v-divider v-if="index < languages.length - 1" :key="index"></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from "vuex";
import isMobile from "@/mixins/isMobile";

export default {
  name: "LanguageSwitch",
  mixins: [isMobile],
  data: () => ({
    languageMenu: false,
  }),
  computed: {
    ...mapState("language", ["language", "languages"]),
  },
  methods: {
    selectLanguage(lang) {
      this.changeLanguage(lang.locale);
    },
    ...mapActions("language", ["changeLanguage"]),
  },
};
</script>
