<template>
  <v-app-bar extended hide-on-scroll :extension-height="80" flat>
    <div
      class="app-bar-content-wrapper flex-grow-1 d-flex flex-column justify-space-between"
    >
      <div class="top-bar d-flex justify-end align-center" style="z-index: 500">
        <v-btn
          v-if="$route.name === 'bookings'"
          :fab="isMobile"
          depressed
          :small="!isMobile"
          :x-small="isMobile"
          color="primary"
          class="mr-auto"
          @click="$router.go(-1)"
        >
          <v-icon :left="!isMobile">$arrow_left</v-icon>
          <span v-if="!isMobile"> {{ $t("back") }}</span>
        </v-btn>
        <v-btn
          v-if="showStepBackButton"
          color="primary"
          small
          text
          class="mr-auto ml-n4"
          @click="setStep(step - 1)"
        >
          <v-icon left>$arrow_left</v-icon>{{ stepBackText }}</v-btn
        >
        <v-btn
          v-if="showStepForwardButton"
          color="primary"
          small
          text
          class="mr-auto ml-n4"
          @click="setStep(step + 1)"
        >
          {{ stepForwardText }}
          <v-icon right>$arrow_right</v-icon>
        </v-btn>
        <user-login v-if="showUserLogin"></user-login>
        <language-switch v-if="languages.length > 1"></language-switch>
      </div>
    </div>
    <template #extension>
      <v-sheet
        width="1400"
        max-width="100%"
        class="mx-auto"
        color="transparent"
      >
        <div class="d-flex justify-center">
          <component
            :is="!!headerLinkUrl ? 'a' : 'div'"
            target="_blank"
            :href="headerLinkUrl"
          >
            <v-img
              v-if="headerImage"
              :src="imageUrl"
              :height="80"
              :max-width="300"
              contain
              position="center center"
            />
            <h1 v-else class="text-center text-h4">{{ propertyName }}</h1>
          </component>
        </div>
      </v-sheet>
    </template>
  </v-app-bar>
</template>
<script>
import { mapState, mapActions } from "vuex";
import LanguageSwitch from "@/components/globals/LanguageSwitch";
import UserLogin from "@/components/globals/UserLogin";
import isMobile from "@/mixins/isMobile";

export default {
  name: "AppToolbar",
  components: {
    LanguageSwitch,
    UserLogin,
  },
  mixins: [isMobile],
  props: {
    headerImage: {
      type: Object,
      required: false,
      default: () => null,
    },
    propertyName: {
      type: String,
      required: true,
    },
  },
  computed: {
    imageUrl() {
      if (!this.headerImage) {
        return "";
      }
      return (
        this.headerImage.imageURL.slice(0, -5) + "2.png?" + this.noImageCache
      );
    },
    headerLinkUrl() {
      if (!this.headerImage || !this.language) {
        return "";
      }
      let headerLinkUrl = "";
      const localizedData = this.headerImage.imageData.find(
        (data) => data.language === this.language.locale
      );
      if (localizedData && localizedData.name !== "") {
        headerLinkUrl = localizedData.name;
      } else {
        const englishData = this.headerImage.imageData.find(
          (data) =>
            ["en-US", "en-GB"].includes(data.language) && data.name !== ""
        );
        if (englishData) {
          headerLinkUrl = englishData.name;
        } else {
          const firstSetName = this.headerImage.imageData.find(
            (data) => data.name !== ""
          );
          if (firstSetName) {
            headerLinkUrl = firstSetName.name;
          }
        }
      }
      return headerLinkUrl;
    },
    showUserLogin() {
      return (
        process.env.VUE_APP_MODE !== "PROD" &&
        this.hotel &&
        (!this.isMobile || (this.roomBookings.length === 0 && this.step === 1))
      );
    },
    showStepBackButton() {
      return this.isMobile && [2, 3].includes(this.step);
    },
    showStepForwardButton() {
      return (
        this.isMobile &&
        ((this.roomBookings.length === this.roomsGuests.length &&
          this.step === 1) ||
          this.step === 2)
      );
    },
    stepBackText() {
      let text = "";
      if (this.showStepBackButton) {
        switch (this.step) {
          case 2:
            text = this.$t("rooms");
            break;
          case 3:
            text = this.$t("options");
            break;
        }
      }
      return text;
    },
    stepForwardText() {
      let text = "";
      if (this.showStepForwardButton) {
        switch (this.step) {
          case 1:
            text = this.$t("options");
            break;
          case 2:
            text = this.$t("book");
            break;
        }
      }
      return text;
    },
    ...mapState([
      "hotel",
      "step",
      "roomBookings",
      "roomsGuests",
      "noImageCache",
    ]),
    ...mapState("language", ["language", "languages"]),
  },
  methods: {
    ...mapActions(["setStep"]),
  },
};
</script>
