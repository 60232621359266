<template>
  <div>
    <linear-loader v-if="showLoader" message="Loading"></linear-loader>
    <template v-else-if="hotels.length">
      <app-toolbar
        :header-image="headerImage"
        :property-name="chainData.chainName"
      ></app-toolbar>
      <v-sheet
        color="transparent"
        width="1000"
        max-width="100%"
        class="mx-auto pa-2 pa-sm-4"
      >
        <p v-if="chainIntro" class="show-line-breaks">
          {{ chainIntro }}
        </p>
        <search-criteria
          v-if="showSearchCriteria"
          ref="searchCriteria"
          :search-criteria="searchCriteria"
          :show-promo-code-input="showPromoCodeInput"
          :age-buckets="chainData.ageBuckets"
          @update:search-criteria="searchCriteria = $event"
        ></search-criteria>
        <div class="d-sm-flex flex-column">
          <h2 class="text-h5 mb-4">
            {{ $t("selectYourHotel") }}
          </h2>
          <hotel-option
            v-for="hotel in hotels"
            :key="hotel.apiKey"
            :hotel="hotel"
            :loading="loadingBestPrices"
          ></hotel-option>
        </div>
      </v-sheet>
    </template>
    <v-container v-else-if="showLoadingError" class="justify-center d-flex">
      <loading-data-error />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { axiosGet, axiosPost } from "@/api/requests";
import { format, addDays } from "date-fns";
import snackBarMessages from "@/mixins/snackBarMessages";
import isMobile from "@/mixins/isMobile";
import LinearLoader from "@/components/globals/LinearLoader";
import LoadingDataError from "@/components/LoadingDataError";
import AppToolbar from "@/components/globals/AppToolbar";
import SearchCriteria from "@/components/SearchCriteria";
import HotelOption from "@/components/HotelOption";
import { createAppStyles } from "@/helpers/createAppStyles";
import { validateParameter } from "@/helpers/urlParameters";
import { initRoomsGuests } from "@/helpers/initRoomsGuests";
import localizedText from "@/mixins/localizedText";

export default {
  name: "ChainPage",
  components: {
    LinearLoader,
    LoadingDataError,
    AppToolbar,
    HotelOption,
    SearchCriteria,
  },
  mixins: [snackBarMessages, isMobile, localizedText],
  data: () => ({
    hotels: [],
    chainData: null,
    chainAvailabilityData: null,
    showLoader: false,
    loadingBestPrices: false,
    showLoadingError: false,
    showPromoCodeInput: false,
    searchCriteria: null,
  }),
  computed: {
    headerImage() {
      if (!this.chainData) {
        return null;
      }
      let image;
      const headerImage = this.chainData.uiSettings.header;
      if (headerImage.imageURL) {
        image = headerImage;
      }
      return image;
    },
    chainIntro() {
      return this.getLocalizedText(
        this.chainData.uiSettings.info,
        "description"
      );
    },
    showSearchCriteria() {
      if (!this.searchCriteria) {
        return false;
      }
      return (
        this.chainData &&
        this.searchCriteria.dates.length === 2 &&
        this.searchCriteria.rooms.length
      );
    },
    ...mapState(["roomsGuests", "nights", "stayDates", "token", "promoCode"]),
    ...mapState("language", ["language", "languages"]),
  },
  watch: {
    searchCriteria: {
      deep: true,
      handler(val) {
        if (this.token && this.hotels.length) {
          this.getChainAvailability(val);
        }
      },
    },
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.setPackageData({
        rateCode: "",
        roomCode: "",
        rateData: null,
        images: [],
        maxLos: 0,
      });
      this.setHotel(null);
      this.setNights(0);
      this.setRoomBookings([]);
      this.setPromoCode("");
      this.setRoomsGuests([]);
      this.showPromoCodeInput = false;
      this.chainData = null;
      this.chainAvailabilityData = null;
      this.hotels = [];
      const id = this.$route.query.id;
      if (id) {
        this.getChainData(id);
      } else {
        this.showLoadingError = true;
      }
    },
    getChainData(id) {
      this.showLoader = true;
      let url = process.env.VUE_APP_ROUTER_URL + "/ibe/chain?id=" + id;
      axiosGet(url)
        .then((response) => {
          if (response.success) {
            this.handleGetChainData(response.ibeChainData);
          } else {
            this.showLoadingError = true;
            this.snackbarError(this.$t("loadingDataFailed"));
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
          this.snackbarError(this.$t("loadingDataFailed"));
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    handleGetChainData(chainData) {
      const activeLanguages = chainData.languages.filter(
        (lang) => lang.active === 1
      );
      if (activeLanguages.length === 0) {
        this.showLoadingError = true;
      } else {
        const hotels = chainData.hotelList;
        hotels.forEach((hotel) => {
          hotel.isAvailable = false;
          hotel.bestAvailableRate = 0;
        });
        this.hotels = hotels.sort((a, b) => a.sortOrder - b.sortOrder);
        this.setLanguages(activeLanguages);
        const language = this.$route.query.language;
        if (
          language &&
          activeLanguages.some(
            (lang) => lang.locale.toLowerCase() === language.toLowerCase()
          )
        ) {
          this.changeLanguage(language);
        } else {
          this.changeLanguage(activeLanguages[0].locale);
        }
        if (!chainData.ageBuckets) {
          chainData.ageBuckets = [];
        }
        this.chainData = chainData;

        const styles = createAppStyles(this.chainData.settings);
        if (styles.font) {
          const link = document.createElement("link");
          link.rel = "stylesheet";
          link.href = `https://fonts.googleapis.com/css?family=${styles.font}`;
          document.head.appendChild(link);
        }
        this.setAppStyles(styles);
        this.setDocumentTabFavicon();
        this.setDocumentTabTitle(this.language);
        this.getToken();
      }
    },
    initSearchCriteria() {
      const dates = this.initDates();
      const rooms = this.initRooms();
      this.setRoomsGuests(rooms);
      this.searchCriteria = {
        dates: dates,
        rooms: rooms,
      };
    },
    initDates() {
      let dates = [];
      const arrival = this.$route.query.arrival,
        departure = this.$route.query.departure;
      if (
        arrival &&
        departure &&
        arrival === format(arrival, "YYYY-MM-DD") &&
        departure === format(departure, "YYYY-MM-DD") &&
        arrival < departure &&
        arrival >= format(new Date(), "YYYY-MM-DD")
      ) {
        dates = [arrival, departure];
      } else {
        dates = [
          format(new Date(), "YYYY-MM-DD"),
          format(addDays(new Date(), 1), "YYYY-MM-DD"),
        ];
      }
      return dates;
    },
    initRooms() {
      const childBucket = this.chainData.ageBuckets.find(
        (bucket) => bucket.aqc === 8
      );

      const adults = validateParameter(this.$route.query.adults)
          ? parseInt(this.$route.query.adults)
          : null,
        children =
          childBucket && validateParameter(this.$route.query.children)
            ? parseInt(this.$route.query.children)
            : 0,
        promocode = this.promoCode;
      let rooms = validateParameter(this.$route.query.rooms)
        ? parseInt(this.$route.query.rooms)
        : null;
      if (rooms > 5) {
        rooms = 5;
      }
      const roomsGuests = initRoomsGuests(rooms, adults, children, promocode);
      return roomsGuests;
    },
    getChainAvailability(criteria) {
      const dates = criteria.dates;
      const rooms = criteria.rooms;
      this.loadingBestPrices = true;
      const roomStayCandidates = [];
      rooms.forEach((room) => {
        const roomData = {
          Quantity: 1,
          GuestCounts: [
            {
              AgeQualifyingCode: 10,
              Count: room.adults,
            },
          ],
        };
        if (room.children) {
          roomData.GuestCounts.push({
            AgeQualifyingCode: 8,
            Count: room.children,
          });
        }
        if (room.infants) {
          roomData.GuestCounts.push({
            AgeQualifyingCode: 7,
            Count: room.infants,
          });
        }
        roomStayCandidates.push(roomData);
      });

      const url = process.env.VUE_APP_IBE_URL + "/ibe/shopchain";
      const data = {
        Environment: this.chainData.environment,
        ChainCode: this.chainData.chainCode,
        Availability: {
          promoCode: this.showPromoCodeInput ? this.promoCode : "",
          StayDateRange: {
            StartDate: dates[0],
            EndDate: dates[1],
          },
          RoomStayCandidates: roomStayCandidates,
        },
      };
      axiosPost(url, data, this.token)
        .then((response) => {
          if (response.success) {
            const promoCode = this.$route.query.promocode;
            if (
              promoCode &&
              promoCode.length > 1 &&
              /^[a-zA-Z0-9-_]*$/.test(promoCode) &&
              !this.showPromoCodeInput &&
              response.hasPromos
            ) {
              this.showPromoCodeInput = true;
              this.setPromoCode(promoCode);
              this.initSearchCriteria();
            } else {
              this.setStayDates({
                arrivalDate: dates[0],
                departureDate: dates[1],
              });
              this.handleGetChainAvailability(response);
            }
          } else {
            this.snackbarError(this.$t("loadingDataFailed"));
          }
        })
        .catch((error) => {
          console.log(error);
          this.snackbarError(this.$t("loadingDataFailed"));
        })
        .finally(() => {
          this.loadingBestPrices = false;
        });
    },
    handleGetChainAvailability(response) {
      this.showPromoCodeInput = response.hasPromos;
      const dates = JSON.parse(JSON.stringify(this.searchCriteria.dates));
      this.setNights(
        Math.ceil(
          Math.abs(
            new Date(dates[1]).getTime() - new Date(dates[0]).getTime()
          ) /
            (1000 * 3600 * 24)
        )
      );
      const hotelsAvailabilities = response.chainAvailability.hotels;
      this.hotels.forEach((hotel) => {
        const hotelAvailability = hotelsAvailabilities.find(
          (hotelAvailability) => hotelAvailability.hotelCode === hotel.hotelCode
        );
        if (hotelAvailability) {
          hotel.isAvailable = hotelAvailability.isAvailable;
          hotel.bestAvailableRate = hotelAvailability.bestAvailableRate;
        } else {
          hotel.isAvailable = false;
          hotel.bestAvailableRate = 0;
        }
      });
    },
    getToken() {
      const isprod = process.env.VUE_APP_MODE === "PROD";
      const url =
        process.env.VUE_APP_IDENTITY_URL +
        "/api?id=71e29f99-4bfb-406c-9181-9dd7e99b45a5&isprod=" +
        isprod;
      axiosGet(url)
        .then((response) => {
          if (response.isAuthenticated) {
            this.setToken(response.authToken);
            this.initSearchCriteria();
          } else {
            this.showLoadingError = true;
            this.snackbarError(this.$t("loadingDataFailed"));
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingError = true;
          this.snackbarError(this.$t("loadingDataFailed"));
        });
    },
    setDocumentTabFavicon() {
      const faviconSettings = this.chainData.uiSettings.favIcon;
      if (faviconSettings && faviconSettings.imageURL) {
        const favicon = document.getElementById("favicon");
        favicon.href = faviconSettings.imageURL + "?" + this.noImageCache;
      }
    },
    setDocumentTabTitle(language) {
      let documentTitle = this.chainData.name;
      const faviconSettings = this.chainData.uiSettings.favIcon;
      if (faviconSettings) {
        const tabTitleLanguageMatch = faviconSettings.imageData.find(
          (lang) => lang.language === language.locale
        );
        if (tabTitleLanguageMatch && tabTitleLanguageMatch.name) {
          documentTitle = tabTitleLanguageMatch.name;
        }
      }
      document.title = documentTitle;
    },
    ...mapActions("language", ["changeLanguage", "setLanguages"]),
    ...mapActions("appStyles", ["setAppStyles"]),
    ...mapActions([
      "setRoomsGuests",
      "setStayDates",
      "setNights",
      "setToken",
      "setHotel",
      "setResult",
      "setStep",
      "setRoomBookings",
      "setPromoCode",
      "setPackageData",
    ]),
  },
};
</script>
