function getSettingColorCode(val) {
  let colorCode = null;
  if (val) {
    let hexString = val.toString(16);
    if (hexString.length < 6) {
      const addZeros = "0".repeat(6 - hexString.length);
      hexString = addZeros + hexString;
    }
    colorCode = "#" + hexString;
  }
  return colorCode;
}

function createAppStyles(settings) {
  let primaryColor = null;
  const primaryColorSetting = settings.find((item) => item.settingType === 2);
  if (primaryColorSetting) {
    primaryColor = getSettingColorCode(primaryColorSetting.settingValue);
  }
  let backgroundColor = null;
  const backgroundColorSetting = settings.find(
    (item) => item.settingType === 13
  );
  if (backgroundColorSetting) {
    backgroundColor = getSettingColorCode(backgroundColorSetting.settingValue);
  }
  let containerBgColor = null;
  const containerBgColorColorSetting = settings.find(
    (item) => item.settingType === 14
  );
  if (containerBgColorColorSetting) {
    containerBgColor = getSettingColorCode(
      containerBgColorColorSetting.settingValue
    );
  }
  let font = null;
  const fontSetting = settings.find(
    (item) => item.settingType === 15 && item.settingName !== ""
  );
  if (fontSetting) {
    font = fontSetting.settingName;
  }
  const appStyles = {
    primaryColor: primaryColor,
    backgroundColor: backgroundColor,
    containerBgColor: containerBgColor,
    font: font,
  };
  return appStyles;
}

export { createAppStyles };
