<template>
  <div
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
    }"
    class="room-rates d-flex flex-column flex-sm-row justify-sm-end flex-md-column justify-md-center align-center"
  >
    <div class="d-flex flex-column justify-center align-center pr-sm-8 pr-md-0">
      <div class="occupancy-wrapper">
        <v-icon
          v-for="i in room.maxOccupancy"
          :key="i + 1"
          class="mx-n2"
          :size="26"
          :color="i <= occupancy ? 'black' : null"
          >$man</v-icon
        >
      </div>

      <div class="best-roomrate d-flex flex-column my-1">
        <div
          v-if="
            !packageData.rateCode && room.rates.length > 0 && room.available
          "
          class="best-roomrate-amount"
        >
          <span class="text-caption">{{ $t("from") }}</span>
          <span class="currency-sign mx-2">{{ hotel.currencySign }}</span>
          <span class="text-h4">{{
            getFormattedAmount(room.bestAvailableRate).units
          }}</span
          ><span class="ml-n1">
            {{ getFormattedAmount(room.bestAvailableRate).cents }}</span
          >
        </div>
        <span
          class="text-caption"
          :class="{ ' ml-auto mt-n2': !packageData.rateCode }"
        >
          {{
            nights > 1
              ? nights + " " + $t("nights_plur")
              : nights + " " + $t("night")
          }}
        </span>
      </div>
    </div>

    <template v-if="packageData.rateCode">
      <template v-if="packageRate && room.available">
        <span
          v-if="packageRate.discount"
          class="discount-message mb-2 text-subtitle-1"
        >
          {{ $t("yourDiscount") }}
          <summary-data-amount
            :amount="packageRate.discount"
          ></summary-data-amount>
        </span>
        <v-btn
          rounded
          color="primary"
          @click.stop="$emit('select-package-room', packageRate)"
        >
          <span class="currency-sign mr-2">{{ hotel.currencySign }}</span>
          <span class="text-h6">{{
            getFormattedAmount(packageRate.total).units
          }}</span
          ><span> {{ getFormattedAmount(packageRate.total).cents }}</span>
          <v-icon large right>$arrow_right</v-icon>
        </v-btn>
      </template>
      <v-btn v-else disabled color="primary">{{ $t("notAvailable") }} </v-btn>
    </template>
    <template v-else>
      <v-btn
        v-if="room.rates.length > 0 && room.available"
        class="align-self-sm-end align-self-md-center"
        color="primary"
        @click.stop="$emit('toggle-show-room-rates')"
        >{{ $t("viewRates") }}
        <v-icon large right>{{
          showRoomRates ? "$arrow_up" : "$arrow_down"
        }}</v-icon>
      </v-btn>
      <v-btn v-else disabled color="primary">{{ $t("notAvailable") }} </v-btn>
    </template>
  </div>
</template>

<script>
import SummaryDataAmount from "@/components/SummaryDataAmount";
import amounts from "@/mixins/amounts";
import { mapState } from "vuex";

export default {
  name: "RoomBestAvailableRate",
  components: {
    SummaryDataAmount,
  },
  mixins: [amounts],
  props: {
    room: {
      type: Object,
      required: true,
    },
    occupancy: {
      type: Number,
      required: true,
    },
    showRoomRates: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    packageRate() {
      const rate = this.room.rates.find(
        (rate) =>
          rate.rateCode.toLowerCase() ===
          this.packageData.rateCode.toLowerCase()
      );
      return rate;
    },
    ...mapState(["hotel", "nights", "packageData"]),
  },
};
</script>
