<template>
  <div class="card-text-card-actions-wrapper d-flex flex-column">
    <v-card-text class="pt-6 px-3 px-sm-6">
      <v-alert
        v-if="showLoginError"
        dense
        class="message-wrapper error-message text-body-2 mb-6"
        transition="scale-transition"
        icon="$alert"
      >
        {{ $t("loginErrorMessage") }}
      </v-alert>
      <v-form ref="loginForm" validate-on="blur" @submit.prevent>
        <v-container>
          <v-row>
            <v-col cols="1" class="pa-0 pb-1">
              <v-icon class="mt-2">$username</v-icon>
            </v-col>
            <v-col cols="11" class="pa-0 pb-1">
              <default-text-field
                ref="emailInput"
                v-model.trim="email"
                :label="$t('email')"
                :rules="validationRules('email')"
                inputmode="email"
                @keydown.space.prevent
                @keyup.enter="submitLoginForm"
              >
              </default-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1" class="pa-0 pb-1">
              <v-icon class="mt-4">$password</v-icon>
            </v-col>
            <v-col cols="10" class="pa-0 pb-1">
              <v-otp-input
                ref="otpInput"
                v-model="pinCode"
                length="4"
                :type="showPin ? 'text' : 'password'"
                :rules="validationRules('pinCode')"
                @finish="submitLoginForm"
              />
            </v-col>
            <v-col cols="1" class="pa-0 pb-1">
              <v-btn class="mt-4" icon small @click="showPin = !showPin">
                <v-icon
                  v-text="showPin ? '$show_password' : '$hide_password'"
                ></v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container> </v-form
    ></v-card-text>
    <v-card-actions class="pb-4 px-3 px-sm-6">
      <p class="text-body-2 mb-0 mr-4">{{ $t("userLoginIntro") }}</p>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="disableLoginButton"
        @click="submitLoginForm"
        >{{ $t("login") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { axiosGet } from "@/api/requests";
import snackBarMessages from "@/mixins/snackBarMessages";
import DefaultTextField from "@/components/formFields/DefaultTextField";

export default {
  name: "LoginDialog",
  components: { DefaultTextField },
  mixins: [snackBarMessages],
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      pinCode: "",
      showPin: false,
      showLoginError: false,
      loading: false,
    };
  },
  computed: {
    disableLoginButton() {
      return this.email === "" || this.pinCode.length < 4;
    },
    ...mapState(["roomBookings"]),
  },
  watch: {
    showDialog: {
      handler(val) {
        if (val) {
          this.$refs.emailInput.$refs.textInput.focus();
        }
        this.resetForm();
      },
    },
  },
  methods: {
    submitLoginForm() {
      this.$refs.emailInput.$refs.textInput.blur();
      if (!this.disableLoginButton) {
        if (this.$refs.loginForm.validate()) {
          this.loading = true;
          this.showLoginError = false;
          const url =
            process.env.VUE_APP_IDENTITY_URL +
            "/pwa?username=" +
            this.email +
            "&pin=" +
            this.pinCode;
          axiosGet(url)
            .then((response) => {
              this.handleLoginResponse(response);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.snackbarInvalidForm();
        }
      }
    },
    handleLoginResponse(response) {
      if (response.isAuthenticated) {
        const user = {
          name: response.name,
          authToken: response.authToken,
          id: response.userID,
        };
        this.setUser(user);
        if (this.roomBookings.length === 0) {
          this.$router.push("/bookings");
        }
        this.$emit("close-dialog");
      } else {
        this.showLoginError = true;
      }
    },
    validationRules(fieldname) {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      switch (fieldname) {
        case "email":
          return [
            (v) => !!v || "requiredFieldError",
            (v) => emailRegex.test(v) || "invalidEmailError",
          ];
        case "pinCode":
          return [(v) => (v.length === 4 && /^\d+$/.test(v)) || ""];
      }
    },
    resetForm() {
      this.email = "";
      this.pinCode = "";
      this.showPin = false;
      this.$refs.loginForm.resetValidation();
      this.showLoginError = false;
    },
    ...mapActions("user", ["setUser"]),
  },
};
</script>
