<template>
  <v-container class="details-section pt-8 mb-4">
    <v-row>
      <v-col cols="12" sm="6" class="pt-0 pb-1">
        <default-text-field
          v-model.trim="bookerDetails.firstName"
          :label="`${$t('firstName')} *`"
          :rules="validationRules('firstName')"
        >
        </default-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-1">
        <default-text-field
          v-model.trim="bookerDetails.surName"
          :label="`${$t('surName')} *`"
          :rules="validationRules('surName')"
        >
        </default-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <default-text-field
          v-model.trim="bookerDetails.phoneNumber"
          :label="`${$t('phoneNumber')} *`"
          :rules="phoneNumberValidationRules"
        >
        </default-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <default-text-field
          v-model.trim="bookerDetails.email"
          :label="`${$t('emailAddress')} *`"
          :rules="validationRules('email')"
        >
        </default-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <default-autocomplete
          v-model="bookerDetails.countryCode"
          :items="countryOptions"
          item-text="name"
          item-value="id"
          :label="`${$t('country')} *`"
          :rules="validationRules('country')"
          @change="
            $emit('update-booker-country-code', bookerDetails.countryCode)
          "
        >
        </default-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <default-text-field
          v-model.trim="bookerDetails.address"
          :label="adressFieldsRequired ? `${$t('address')} *` : $t('address')"
          :rules="adressFieldsRequired ? validationRules('address') : []"
        >
        </default-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <default-text-field
          v-model.trim="bookerDetails.postalCode"
          :label="
            adressFieldsRequired ? `${$t('postalCode')} *` : $t('postalCode')
          "
          :rules="adressFieldsRequired ? validationRules('postalCode') : []"
        >
        </default-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <default-text-field
          v-model.trim="bookerDetails.city"
          :label="adressFieldsRequired ? `${$t('city')} *` : $t('city')"
          :rules="adressFieldsRequired ? validationRules('city') : []"
        >
        </default-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <default-textarea
          v-model.trim="bookerDetails.comments"
          :label="$t('specialRequests')"
          rows="2"
        >
        </default-textarea>
      </v-col>
      <v-col v-if="!hideEtaField" cols="12" sm="6" md="2" class="pt-0 pb-1">
        <default-text-field
          :key="etaFieldKey"
          v-model.trim="eta"
          :label="$t('estimatedTimeArrival')"
          type="time"
          step="900"
          :rules="etaValidationRules"
          @blur="blurEtafield"
        >
        </default-text-field>
      </v-col>
      <template v-if="!hideCompanyField">
        <v-col cols="6" md="3" class="pt-0 pb-1">
          <v-checkbox
            v-model="businessBooking"
            class="mt-0 pt-0 ml-3"
            color="black"
          >
            <template #label>{{ $t("businessBookingLabel") }}</template>
          </v-checkbox>
        </v-col>
        <v-col v-if="businessBooking" cols="6" md="" class="pt-0 pb-1">
          <default-text-field
            v-model.trim="companyName"
            :label="`${$t('companyName')} *`"
            :rules="validationRules('companyName')"
          >
          </default-text-field>
        </v-col>
      </template>
    </v-row>
    <small>* {{ $t("requiredFieldError") }}</small>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import DefaultTextField from "@/components/formFields/DefaultTextField";
import DefaultTextarea from "@/components/formFields/DefaultTextarea";
import DefaultAutocomplete from "@/components/formFields/DefaultAutocomplete";
import { countryCodes } from "@/static/countries";

export default {
  name: "BookerDetails",
  components: {
    DefaultTextField,
    DefaultTextarea,
    DefaultAutocomplete,
  },
  data: () => ({
    bookerDetails: {
      firstName: "",
      surName: "",
      phoneNumber: "",
      email: "",
      comments: "",
      countryCode: "",
      address: "",
      postalCode: "",
      city: "",
    },
    businessBooking: false,
    companyName: "",
    eta: "",
    etaFieldKey: 0,
    showTimePicker: false,
  }),
  computed: {
    countryOptions() {
      const countrieOptions = countryCodes
        .map((code) => {
          return { id: code, name: this.regionNames.of(code) };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      return countrieOptions;
    },
    regionNames() {
      return new Intl.DisplayNames([this.datePickerLocale.substring(0, 2)], {
        type: "region",
      });
    },
    adressFieldsRequired() {
      const bookerAdressSetting = this.hotel.settings.find(
        (setting) => setting.settingType === 1
      );
      if (!bookerAdressSetting) {
        return true;
      }
      return bookerAdressSetting.settingValue === 1;
    },
    hideEtaField() {
      const hideEtaSetting = this.hotel.settings.find(
        (setting) => setting.settingType === 9
      );
      if (!hideEtaSetting) {
        return false;
      }
      return hideEtaSetting.settingValue === 1;
    },
    etaValidationRules() {
      const requireEtaSetting = this.hotel.settings.find(
        (setting) => setting.settingType === 17
      );
      if (requireEtaSetting && requireEtaSetting.settingValue === 1) {
        return [(v) => !!v || "requiredFieldError"];
      }
      return [];
    },
    hideCompanyField() {
      const hideCompanySetting = this.hotel.settings.find(
        (setting) => setting.settingType === 10
      );
      if (!hideCompanySetting) {
        return false;
      }
      return hideCompanySetting.settingValue === 1;
    },
    datePickerLocale() {
      if (["nn-NO", "nb-NO"].includes(this.language.locale)) {
        return "no-NO";
      }
      return this.language.locale;
    },
    phoneNumberValidationRules() {
      const validatePhoneNumberSetting = this.hotel.settings.find(
        (setting) => setting.settingType === 12
      );
      if (
        !validatePhoneNumberSetting ||
        !validatePhoneNumberSetting.settingValue
      ) {
        return [(v) => !!v || "requiredFieldError"];
      }
      return [
        (v) => !!v || "requiredFieldError",
        (v) => ["0", "+"].includes(v[0]) || "invalidPhoneNumber",
      ];
    },
    ...mapState(["hotel"]),
    ...mapState("language", ["language", "languages"]),
  },
  methods: {
    validationRules(fieldname) {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      switch (fieldname) {
        case "firstName":
        case "surName":
        case "country":
        case "address":
        case "postalCode":
        case "city":
        case "companyName":
          return [(v) => !!v || "requiredFieldError"];
        case "email":
          return [
            (v) => !!v || "requiredFieldError",
            (v) => emailRegex.test(v) || "invalidValue",
          ];
      }
    },
    blurEtafield() {
      if (!this.eta) {
        this.etaFieldKey++;
      }
    },
  },
};
</script>
