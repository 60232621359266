<template>
  <div class="room-wrapper" :data-room-code="room.roomCode">
    <div class="d-md-flex">
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-md-flex">
          <room-image
            v-if="roomImage"
            :available="true"
            :image="roomImage"
            :show-carousel-button="room.roomData.images.length > 1"
            @show-room-images="
              $emit('show-room-images', { images: images, name: roomName })
            "
          ></room-image>
          <room-info
            :name="roomName"
            :room-amenities="room.roomData.amenities"
            :hide-description-toggle="true"
          ></room-info>
        </div>
        <room-description
          :description="roomDescription"
          :show-description="true"
        ></room-description>
      </div>
      <package-room-select-section
        :room="room"
        :occupancy="occupancy"
        :min-los="packageMinLos"
        @select-package-room="$emit('select-package-room', room)"
      ></package-room-select-section>
    </div>
  </div>
</template>

<script>
import dialogs from "@/mixins/dialogs";
import localizedText from "@/mixins/localizedText";
import { mapState } from "vuex";
import RoomImage from "@/components/RoomImage";
import RoomInfo from "@/components/RoomInfo";
import RoomDescription from "@/components/RoomDescription";
import PackageRoomSelectSection from "@/components/PackageRoomSelectSection";

export default {
  name: "IbePackageRoom",
  components: {
    RoomImage,
    RoomInfo,
    RoomDescription,
    PackageRoomSelectSection,
  },
  mixins: [dialogs, localizedText],
  props: {
    room: {
      type: Object,
      required: true,
    },
    packageMinLos: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    showRoomDescription: true,
  }),
  computed: {
    roomImage() {
      let image = null;
      if (this.room.roomData.images.length > 0) {
        image = this.room.roomData.images.find(
          (image) => image.imageType === 1
        );
      }
      return image;
    },
    roomName() {
      return this.getLocalizedText(this.room.roomData.roomData, "name");
    },
    roomDescription() {
      return this.getLocalizedText(this.room.roomData.roomData, "description");
    },
    images() {
      const images = this.room.roomData.images.map((image) => {
        return {
          id: image.id,
          url: image.imageURL.split("?")[0].slice(0, -5) + "2.png",
          description: this.getLocalizedText(image.imageData, "name"),
        };
      });
      return images;
    },
    occupancy() {
      return this.roomToSelect.adults;
    },
    ...mapState(["roomToSelect"]),
  },
};
</script>
