<template>
  <dl class="grid-dl text-body-2">
    <dt class="notranslate">
      {{ getLocalizedText(service.serviceData, "name") }}
    </dt>
    <dd class="text-right">
      <summary-data-amount :amount="totalServiceAmount"></summary-data-amount>
    </dd>
  </dl>
</template>

<script>
import { mapState } from "vuex";
import localizedText from "@/mixins/localizedText";
import SummaryDataAmount from "@/components/SummaryDataAmount";

export default {
  name: "AdditionalService",
  components: {
    SummaryDataAmount,
  },
  mixins: [localizedText],
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalServiceAmount() {
      let amount = 0;
      const service = this.service;
      const adults = this.roomBookings
        .map((booking) => parseInt(booking.selectedRoom.adults))
        .reduce((prev, curr) => prev + curr);
      const children = this.roomBookings
        .map((booking) => parseInt(booking.selectedRoom.children))
        .reduce((prev, curr) => prev + curr);
      const infants = this.roomBookings
        .map((booking) => parseInt(booking.selectedRoom.infants))
        .reduce((prev, curr) => prev + curr);
      const totalNettAmount = this.roomBookings
        .map((booking) => parseFloat(booking.rate.nettTotal))
        .reduce((prev, curr) => prev + curr);
      const nights = this.nights;
      switch (service.chargeType) {
        case 1: // perAdultPerNight,
          amount = service.price * adults * nights;
          break;
        case 2: // perAdultPerStay,
          amount = service.price * adults;
          break;
        case 3: // perChildPerNight,
          amount = service.price * children * nights;
          break;
        case 4: // perChildPerStay,
          amount = service.price * children;
          break;
        case 5: // perNight,
          amount = service.price * nights;
          break;
        case 6: // perUnit,
          amount = service.price;
          break;
        case 7: // perUnitPerAdult,
          amount = service.price * adults;
          break;
        case 8: // perUnitPerAdultPerNight,
          amount = service.price * adults * nights;
          break;
        case 9: // perUnitPerNight,
          amount = service.price * nights;
          break;
        case 10: // perStay
          amount = service.price;
          break;
        case 11: // percentagePerStay,
          amount = 0.01 * service.price * totalNettAmount;
          break;
        case 12: // percentagePerStayPerAdult,
          amount = 0.01 * service.price * totalNettAmount * adults;
          break;
        case 17: // perInfantPerNight,
          amount = service.price * infants * nights;
          break;
        case 18: // perInfantPerStay,
          amount = service.price * infants;
          break;
        case 26: // PerPersonPerNight,
          amount = service.price * (adults + children + infants) * nights;
          break;
        case 27: // PerPersonPerStay,
          amount = service.price * (adults + children + infants);
          break;
        case 28: // PercentagePerStayPerPerson,
          amount =
            0.01 *
            service.price *
            totalNettAmount *
            (adults + children + infants);
          break;
        default:
          break;
      }
      return amount;
    },
    ...mapState(["roomBookings", "nights"]),
  },
};
</script>
