<template>
  <v-container class="details-section pa-3 mb-4">
    <h3 class="mb-2">{{ $t("payOptionsTitle") }}</h3>
    <v-radio-group
      v-model="paymentMethod"
      hide-details
      class="mt-0 d-block"
      mandatory
    >
      <v-radio :value="1">
        <template #label>
          <span class="black--text"> {{ $t("payNow") }}</span>
        </template>
      </v-radio>
      <p class="pl-8">{{ $t("payNowDescription") }}</p>
      <template v-if="showPayLaterOption">
        <v-radio :value="2">
          <template #label>
            <span class="black--text"> {{ $t("payLater") }}</span>
          </template>
        </v-radio>
        <p class="pl-8 mb-0">{{ $t("payLaterDescription") }}</p>
      </template>
      <template v-else>
        <v-radio :value="3">
          <template #label>
            <span class="black--text"> {{ $t("payInHotel") }}</span>
          </template>
        </v-radio>
        <p class="pl-8 mb-0">{{ $t("payInHotelDescription") }}</p>
      </template>
    </v-radio-group>
  </v-container>
</template>

<script>
export default {
  name: "BookerPaymentDetails",
  props: {
    showPayLaterOption: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    paymentMethod: 1,
  }),
};
</script>
