<template>
  <div
    class="flex-grow-1 mr-2 mr-lg-4"
    :class="{
      'not-mobile': $vuetify.breakpoint.smAndUp,
    }"
  >
    <template v-if="packageData.rateCode">
      <v-btn
        rounded
        color="primary"
        class="mb-2"
        @click.stop="$emit('back-to-overview')"
      >
        <v-icon large left>$arrow_left</v-icon>
        {{ $t("toOverview") }}
      </v-btn>
      <package-info @show-package-images="showRoomImages"></package-info>
    </template>

    <div class="d-flex justify-space-between align-center">
      <h2 class="text-h5 mb-4">
        {{ $t("selectYourRoom") }}
        <room-to-select-info
          v-if="roomsGuests.length > 1"
        ></room-to-select-info>
      </h2>
      <div
        v-if="!packageData.roomCode && !roomCodeFilter"
        class="switch-wrapper d-none d-md-flex"
      >
        <v-btn text small @click="onlyShowAvailableRooms = false">
          {{ $t("all") }}</v-btn
        >

        <v-switch
          v-model="onlyShowAvailableRooms"
          color="primary"
          class="mt-0"
          hide-details
        >
        </v-switch>
        <v-btn text small @click="onlyShowAvailableRooms = true">
          {{ $t("available") }}</v-btn
        >
      </div>
    </div>
    <div
      class="selectable-rooms-wrapper"
      :class="{
        'not-mobile': $vuetify.breakpoint.smAndUp,
      }"
    >
      <p v-if="visibleRooms.length === 0">
        {{
          onlyShowAvailableRooms
            ? $t("noAvailableRoomsFound")
            : $t("noRoomsFound")
        }}
      </p>
      <ibe-room
        v-for="room in visibleRooms"
        :key="room.roomCode"
        :room="room"
        @show-room-images="showRoomImages"
      ></ibe-room>
    </div>

    <default-dialog
      v-if="selectedDialog"
      :show-dialog="selectedDialog.show"
      :width="selectedDialog.width"
      :title="selectedRoom.name"
      :include-actions="selectedDialog.includeActions"
      @close-dialog="closeDialog"
    >
      <template #dialog-content>
        <component
          :is="selectedDialog.content"
          :ref="selectedDialog.ref"
          :show-dialog="selectedDialog.show"
          :images="selectedRoom.images"
          @close-dialog="closeDialog"
        ></component>
      </template>
    </default-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import dialogs from "@/mixins/dialogs";
import isMobile from "@/mixins/isMobile";
import IbeRoom from "@/components/IbeRoom";
import RoomToSelectInfo from "@/components/RoomToSelectInfo";
import PackageInfo from "@/components/PackageInfo";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import ImagesCarousel from "@/components/dialogs/ImagesCarousel";

export default {
  name: "SelectRooms",
  components: {
    IbeRoom,
    PackageInfo,
    DefaultDialog,
    ImagesCarousel,
    RoomToSelectInfo,
  },
  mixins: [isMobile, dialogs],
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    roomCodeFilter: {
      type: String,
      required: false,
      default: "",
    },
    roomsCategoryFilter: {
      type: Number,
      required: false,
      default: null,
    },
    defaultShowAllRooms: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    onlyShowAvailableRooms: true,
    selectedRoom: null,
  }),
  computed: {
    visibleRooms() {
      let rooms = this.rooms;
      if (this.roomCodeFilter) {
        const selectedRoom = rooms.find(
          (room) => room.roomCode.toLowerCase() === this.roomCodeFilter
        );
        if (selectedRoom.available) {
          return [selectedRoom];
        } else {
          const availableRooms = rooms.filter((room) => room.available);
          availableRooms.unshift(selectedRoom);
          return availableRooms;
        }
      }
      if (this.onlyShowAvailableRooms && !this.packageData.roomCode) {
        rooms = rooms.filter((room) => room.available);
      }
      if (this.roomsCategoryFilter !== null) {
        rooms = rooms.filter(
          (room) => room.roomCategory === this.roomsCategoryFilter
        );
      }
      return rooms;
    },
    ...mapState(["roomsGuests", "packageData"]),
  },
  watch: {
    onlyShowAvailableRooms(val) {
      this.$emit("update:show-allRooms", !val);
    },
  },
  mounted() {
    this.onlyShowAvailableRooms = !this.defaultShowAllRooms;
  },
  methods: {
    showRoomImages(data) {
      this.selectedRoom = data;
      this.showDialog("imagesCarousel");
    },
  },
};
</script>
