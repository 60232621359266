<template>
  <v-col cols="12" sm="6" md="4" class="py-0">
    <h3 class="mb-2 text-sm-subtitle-2">
      {{ $t("stayDates") }}
    </h3>
    <div class="d-flex">
      <date-range-picker
        ref="dateRangePicker"
        :label="$t('stayDatesLabel')"
        :item-dates="dates"
        :disabled="roomBookings.length > 0"
        :min="minDate"
        :max="maxDate"
        :fixed-range="fixedRange"
        :disabled-dates="disabledDates"
        :loading="loadingCalendarData"
        @update:dates="dates = $event"
        @update:date-picker="dateRangePicker = $event"
        @get-dates-availablility="getAvailableDates"
      >
      </date-range-picker>
    </div>
  </v-col>
</template>

<script>
import { mapState } from "vuex";
import snackBarMessages from "@/mixins/snackBarMessages";
import dateRangePicker from "@/mixins/dateRangePicker";
import DateRangePicker from "@/components/formFields/DateRangePicker";

export default {
  name: "StayDates",
  components: {
    DateRangePicker,
  },
  mixins: [snackBarMessages, dateRangePicker],
  computed: {
    validRange() {
      return this.dates.length === 2 && this.dates[0] !== this.dates[1];
    },
    datesChanged() {
      return (
        this.dates[0] !== this.stayDates.arrivalDate ||
        this.dates[1] !== this.stayDates.departureDate
      );
    },
    dateRangeText() {
      const dates = this.dates;
      if (dates.length === 0) {
        return "";
      }
      const formatter = new Intl.DateTimeFormat(this.language.locale, {
        day: "numeric",
        month: "short",
      });

      return dates.map((date) => formatter.format(new Date(date))).join(" ~ ");
    },
    ...mapState([
      "roomBookings",
      "packageData",
      "stayDates",
      "hotel",
      "segment",
      "token",
    ]),
    ...mapState("language", ["language"]),
  },
  watch: {
    dateRangePicker(val) {
      if (!val) {
        if (this.validRange) {
          if (this.datesChanged) {
            this.$emit("dates-changed", this.dates);
          }
        } else {
          this.dates = JSON.parse(
            JSON.stringify([
              this.stayDates.arrivalDate,
              this.stayDates.departureDate,
            ])
          );
        }
      } else {
        this.dates = [];
      }
    },
    stayDates: {
      immediate: true,
      handler(val) {
        if (val) {
          this.dates = JSON.parse(
            JSON.stringify([val.arrivalDate, val.departureDate])
          );
        }
      },
    },
  },
};
</script>
