<template>
  <div class="card-text-card-actions-wrapper d-flex flex-column">
    <v-card-text class="px-3 px-sm-6 pb-0">
      <v-container class="px-0">
        <v-form ref="searchCriteriaForm" @submit.prevent>
          <v-row>
            <v-col cols="12" class="py-0">
              <h3 class="mb-4 text-sm-subtitle-2">
                {{ $t("stayDates") }}
              </h3>
              <date-range-picker
                v-if="hotel"
                ref="dateRangePicker"
                :label="$t('stayDatesLabel')"
                :item-dates="dates"
                :min="minDate"
                :max="maxDate"
                :disabled="roomBookings.length > 0"
                :fixed-range="fixedRange"
                :disabled-dates="disabledDates"
                :loading="loadingCalendarData"
                @update:dates="dates = $event"
                @update:date-picker="dateRangePicker = $event"
                @get-dates-availablility="getAvailableDates"
              >
              </date-range-picker>
              <date-range-picker
                v-else
                ref="dateRangePicker"
                :label="$t('stayDatesLabel')"
                :item-dates="dates"
                :min="minDate"
                :max="maxDate"
                :disabled="false"
                :fixed-range="0"
                :disabled-dates="[]"
                :loading="false"
                @update:dates="dates = $event"
                @update:date-picker="dateRangePicker = $event"
              >
              </date-range-picker>
            </v-col>
          </v-row>
          <template v-if="!packageData.rateCode">
            <v-row v-if="showPromoCodeInput">
              <v-col cols="12">
                <h3 class="mb-2 text-sm-subtitle-2">
                  {{ $t("promoCode") }}
                </h3>
                <default-text-field
                  v-model.trim="promotionCode"
                  :disabled="roomBookings.length > 0"
                  :label="$t('promoCode')"
                  :rules="validationRules('promoCode')"
                  @keydown.space.prevent
                >
                </default-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <h3 class="mb-2 text-sm-subtitle-2">
                  {{ $t("rooms") }}
                </h3>
                <div v-for="(room, idx) in rooms" :key="'room_' + idx">
                  <v-row no-gutters>
                    <v-col cols="10">
                      <v-row no-gutters class="align-center">
                        <v-col cols="6">
                          {{ $t("adults") }}
                        </v-col>
                        <v-col cols="2" class="text-center">
                          <v-btn
                            icon
                            :disabled="
                              room.adults <= 1 || roomIsBooked(room.id)
                            "
                            @click.stop="room.adults--"
                          >
                            <v-icon color="black" dark>$remove_circle</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="2" class="text-h6 text-center">
                          {{ room.adults }}
                        </v-col>
                        <v-col cols="2" class="text-center">
                          <v-btn
                            icon
                            :disabled="roomIsBooked(room.id)"
                            @click.stop="room.adults++"
                          >
                            <v-icon color="black" dark>$add_circle</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <template v-if="ageBuckets.length > 0">
                        <template v-if="childBucket">
                          <v-row no-gutters class="align-center">
                            <v-col cols="6">
                              {{
                                `${$t("children")} (${childBucket.minAge}-${
                                  childBucket.maxAge
                                })`
                              }}
                            </v-col>
                            <v-col cols="2" class="text-center">
                              <v-btn
                                icon
                                :disabled="
                                  room.children <= 0 || roomIsBooked(room.id)
                                "
                                @click.stop="room.children--"
                              >
                                <v-icon color="black" dark
                                  >$remove_circle</v-icon
                                >
                              </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-h6 text-center">
                              {{ room.children }}
                            </v-col>
                            <v-col cols="2" class="text-center">
                              <v-btn
                                icon
                                :disabled="roomIsBooked(room.id)"
                                @click.stop="room.children++"
                              >
                                <v-icon color="black" dark>$add_circle</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-if="infantBucket">
                          <v-row no-gutters class="align-center">
                            <v-col cols="6">
                              {{
                                `${$t("infants")} (${infantBucket.minAge}-${
                                  infantBucket.maxAge
                                })`
                              }}
                            </v-col>
                            <v-col cols="2" class="text-center">
                              <v-btn
                                icon
                                :disabled="
                                  room.infants <= 0 || roomIsBooked(room.id)
                                "
                                @click.stop="room.infants--"
                              >
                                <v-icon color="black" dark
                                  >$remove_circle</v-icon
                                >
                              </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-h6 text-center">
                              {{ room.infants }}
                            </v-col>
                            <v-col cols="2" class="text-center">
                              <v-btn
                                icon
                                :disabled="roomIsBooked(room.id)"
                                @click.stop="room.infants++"
                              >
                                <v-icon color="black" dark>$add_circle</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                    </v-col>
                    <v-col cols="2" class="text-center align-self-center">
                      <v-icon v-if="roomIsBooked(room.id)" large color="success"
                        >$checkmark</v-icon
                      >
                      <v-btn
                        v-else-if="rooms.length > 1"
                        icon
                        @click.stop="removeRoom(idx)"
                      >
                        <v-icon color="error">$delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-divider class="my-4"></v-divider>
                </div>
                <v-btn
                  v-if="showAddRoomButton"
                  color="primary"
                  @click.stop="addRoom"
                >
                  {{ $t("addRoom") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="d-flex justify-end px-6 py-3">
      <v-btn color="primary" :disabled="!criteriaChanged" @click="search">
        {{ $t("search") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import snackBarMessages from "@/mixins/snackBarMessages";
import dateRangePicker from "@/mixins/dateRangePicker";
import DefaultTextField from "@/components/formFields/DefaultTextField";
import DateRangePicker from "@/components/formFields/DateRangePicker";

export default {
  name: "SearchCriteriaDialog",
  components: {
    DateRangePicker,
    DefaultTextField,
  },
  mixins: [snackBarMessages, dateRangePicker],
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    stayDates: {
      type: Array,
      required: true,
    },
    showPromoCodeInput: {
      type: Boolean,
      required: true,
    },
    ageBuckets: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    rooms: [],
    promotionCode: "",
  }),
  computed: {
    roomsGuestsChanged() {
      const originalRooms = JSON.stringify(this.roomsGuests),
        currentrooms = JSON.stringify(this.rooms);
      return originalRooms !== currentrooms;
    },
    datesChanged() {
      if (this.dates.length !== 2) {
        return false;
      }
      return (
        this.dates[0] !== this.stayDates[0] ||
        this.dates[1] !== this.stayDates[1]
      );
    },
    promotionCodeChanged() {
      return this.promotionCode !== this.promoCode;
    },
    criteriaChanged() {
      return (
        this.roomsGuestsChanged ||
        this.datesChanged ||
        this.promotionCodeChanged
      );
    },
    childBucket() {
      return this.ageBuckets.find((bucket) => bucket.aqc === 8);
    },
    infantBucket() {
      return this.ageBuckets.find((bucket) => bucket.aqc === 7);
    },
    showAddRoomButton() {
      return this.rooms.length < 5;
    },
    ...mapState([
      "roomsGuests",
      "roomBookings",
      "promoCode",
      "packageData",
      "segment",
      "token",
      "hotel",
    ]),
  },
  watch: {
    showDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.dates = JSON.parse(JSON.stringify(this.stayDates));
          this.rooms = JSON.parse(JSON.stringify(this.roomsGuests));
          this.promotionCode = JSON.parse(JSON.stringify(this.promoCode));
          if (this.$refs.searchCriteriaForm) {
            this.$refs.searchCriteriaForm.resetValidation();
          }
        }
      },
    },
    dateRangePicker(val) {
      if (val) {
        this.dates = [];
      }
    },
  },
  methods: {
    search() {
      if (this.$refs.searchCriteriaForm.validate()) {
        if (this.promotionCodeChanged) {
          this.setPromoCode(this.promotionCode);
        }
        const rooms = JSON.parse(JSON.stringify(this.rooms));
        rooms.forEach((room) => {
          room.promoCode = this.promoCode;
        });
        const criteria = {
          dates: this.dates,
          rooms: this.rooms,
        };
        this.setRoomsGuests(this.rooms);
        this.$emit("search-rooms", criteria);
        this.$emit("close-dialog");
      } else {
        this.snackbarInvalidForm();
      }
    },
    roomIsBooked(roomId) {
      return this.roomBookings.some((booking) => booking.id === roomId);
    },
    addRoom() {
      this.rooms.push({
        id: this.rooms.slice(-1)[0].id + 1,
        adults: 2,
        children: 0,
        infants: 0,
      });
    },
    removeRoom(index) {
      this.$root
        .$confirm(
          this.$t("removeRoom"),
          this.$t("removeRoomsGuestsRoomConfirmMessage")
        )
        .then((confirmResult) => {
          if (confirmResult) {
            this.rooms.splice(index, 1);
          }
        });
    },
    validationRules(fieldname) {
      switch (fieldname) {
        case "promoCode":
          return [
            (v) =>
              v === "" ||
              (v.length > 1 && /^[a-zA-Z0-9-_]*$/.test(v)) ||
              "invalidValue",
          ];
      }
    },
    ...mapActions(["setPromoCode", "setRoomsGuests"]),
  },
};
</script>
