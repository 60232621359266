<template>
  <div class="rooms-wrapper flex-grow-1 mr-sm-4">
    <v-sheet max-width="600" class="my-0 mx-auto">
      <payment-result v-if="result"></payment-result>
      <template v-else>
        <h2 class="text-h5 mb-4">{{ paymentTitle }}</h2>
        <p v-if="paymentText" class="show-line-breaks">{{ paymentText }}</p>
        <payment-method
          ref="paymentMethod"
          :payment-data="paymentData"
          :payment-methods="paymentMethods"
        ></payment-method>
      </template>
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaymentMethod from "@/components/PaymentMethod";
import PaymentResult from "@/components/PaymentResult";

export default {
  name: "PaymentSection",
  components: {
    PaymentMethod,
    PaymentResult,
  },
  props: {
    paymentMethods: {
      type: Object,
      required: true,
    },
    paymentData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    paymentTitle() {
      if (this.preAuth) {
        return this.$t("paymentGuarantee");
      }
      return this.$t("pay");
    },
    paymentText() {
      let paymentText = "";
      const paymentTextMatch = this.hotel.uiSettings.payment.find(
        (lang) => lang.language === this.language.locale
      );
      if (paymentTextMatch) {
        paymentText = paymentTextMatch.description;
      }
      return paymentText;
    },
    ...mapState(["result", "preAuth", "hotel"]),
    ...mapState("language", ["language"]),
  },
};
</script>
