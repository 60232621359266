<template>
  <div>
    <v-btn
      v-if="!user"
      color="primary"
      outlined
      small
      class="px-2"
      @click="showDialog('loginDialog')"
    >
      <v-icon v-if="!isMobile" left>$login</v-icon>
      <span class="black--text text-none">{{ $t("login") }}</span>
    </v-btn>
    <v-menu v-else v-model="userMenu" offset-y :max-height="220">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :fab="isMobile"
          depressed
          :small="!isMobile"
          :x-small="isMobile"
          :outlined="!isMobile"
          class="px-2"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <template v-if="!isMobile">
            <v-icon left>$login</v-icon>
            <span class="user-name black--text">{{ user.name }}</span>
          </template>
          <span v-else class="text-h6">{{ user.name[0] }}</span>
        </v-btn>
      </template>
      <v-list dense>
        <template v-if="showBookingsMenuItem">
          <v-list-item @click="$router.push('/bookings')">
            <v-list-item-icon v-if="!isMobile" class="mr-2">
              <v-icon>$trips</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ $t("yourBookings") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <v-list-item @click="singOut">
          <v-list-item-icon v-if="!isMobile" class="mr-2">
            <v-icon>$logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ $t("logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <default-dialog
      v-if="selectedDialog"
      :show-dialog="selectedDialog.show"
      :width="selectedDialog.width"
      :title="$t(selectedDialog.titleKey)"
      :include-actions="selectedDialog.includeActions"
      :use-logo="true"
      @close-dialog="closeDialog"
    >
      <template #dialog-content>
        <component
          :is="selectedDialog.content"
          :ref="selectedDialog.ref"
          :show-dialog="selectedDialog.show"
          @close-dialog="closeDialog"
        ></component>
      </template>
    </default-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dialogs from "@/mixins/dialogs";
import isMobile from "@/mixins/isMobile";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import LoginDialog from "@/components/dialogs/LoginDialog";

export default {
  name: "UserLogin",
  components: {
    LoginDialog,
    DefaultDialog,
  },
  mixins: [isMobile, dialogs],
  data: () => ({
    userMenu: false,
  }),
  computed: {
    showBookingsMenuItem() {
      return (
        this.$route.name !== "bookings" &&
        this.roomBookings.length === 0 &&
        this.step === 1
      );
    },
    ...mapState("user", ["user"]),
    ...mapState(["roomBookings", "step"]),
  },
  methods: {
    singOut() {
      this.userMenu = false;
      const route = this.$route;
      if (route.name === "bookings") {
        this.$router.go(-1);
      }
      this.setUser(null);
    },
    ...mapActions("user", ["setUser"]),
  },
};
</script>
