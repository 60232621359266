import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("language", ["language"]),
  },
  methods: {
    getLocalizedText(data, property) {
      let text = "";
      const locale = data.find(
        (lang) => lang.language === this.language.locale
      );

      if (locale && locale[property] !== "") {
        text = locale[property];
      }
      return text;
    },
  },
};
