<template>
  <div>
    <v-alert
      v-if="paymentMethods.paymentMethods.length === 0"
      class="message-wrapper error-message"
      icon="$alert"
    >
      <p class="mb-0">
        {{ $t("noPaymentMethodsAvailable") }}
      </p>
    </v-alert>
    <template v-else>
      <v-alert
        v-if="showAlert"
        class="message-wrapper error-message"
        icon="$alert"
      >
        <p class="font-weight-bold">{{ $t("refusedResultHeader") }}</p>
        <span class="black--text">
          {{ alertMessage }}
        </span>
      </v-alert>
      <adyen-dropin
        v-if="paymentMethods.paymentMethods.length > 0"
        ref="adyenDropin"
        :payment-methods="paymentMethods"
        :payment-data="paymentData"
        @refusal-alert="handleAlert"
      ></adyen-dropin>
    </template>
  </div>
</template>

<script>
import AdyenDropin from "./AdyenDropin";
export default {
  name: "PaymentMethod",
  components: {
    AdyenDropin,
  },
  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    paymentMethods: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAlert: false,
      alertMessage: "",
    };
  },
  mounted() {
    this.showAlert = false;
    this.alertMessage = "";
  },
  methods: {
    handleAlert(data) {
      this.showAlert = data.show;
      if (data.refusalReasonCode) {
        let alertMessage = this.$t("refusalReason_" + data.refusalReasonCode);
        if (alertMessage) {
          this.alertMessage = this.$t("reason") + ": " + alertMessage;
        }
      } else {
        this.alertMessage = this.$t("defaultRefused");
      }
    },
  },
};
</script>
