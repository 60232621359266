const roomAmenities = [
  { id: 1, key: "singleBed", icon: "$single_bed" },
  { id: 2, key: "kingSizeBed", icon: "$king_bed" },
  { id: 3, key: "queenSizeBed", icon: "$queen_bed" },
  { id: 4, key: "extraLargeBed", icon: "$lits_jumeaux" },
  { id: 5, key: "wifi", icon: "$wifi" },
  { id: 6, key: "tv", icon: "$tv" },
  { id: 7, key: "airconditioning", icon: "$airco" },
  { id: 8, key: "bath", icon: "$bathtub" },
  { id: 9, key: "shower", icon: "$shower" },
  { id: 10, key: "bathShower", icon: "$bath_shower" },
  { id: 11, key: "petsAllowed", icon: "$pets" },
  { id: 12, key: "disabledFacilities", icon: "$wheelchair" },
  { id: 13, key: "doubleBed", icon: "$double_bed" },
  { id: 14, key: "twinBeds", icon: "$twin_beds" },
  { id: 15, key: "bunkBeds", icon: "$bunk_beds" },
  { id: 16, key: "sofaBed", icon: "$sofa_beds" },
  { id: 17, key: "sharedBathroom", icon: "$shared_bathroom" },
  { id: 18, key: "selfCatering", icon: "$self_catering" },
  { id: 19, key: "jacuzzi", icon: "$jacuzzi" },
  { id: 20, key: "teaCoffee", icon: "$tea_coffee" },
  { id: 21, key: "noPetsAllowed", icon: "$no_pets" },
  { id: 22, key: "balcony", icon: "$balcony" },
  { id: 23, key: "terrace", icon: "$terrace" },
];

const ibeRoomCategories = [
  { id: 0, key: "hotelRoom" },
  { id: 1, key: "apartment" },
  { id: 2, key: "cottage" },
  { id: 3, key: "villa" },
  { id: 4, key: "studio" },
  { id: 5, key: "suite" },
];

const rateLabelTypes = [
  { id: 0, key: "none" },
  { id: 1, key: "freeCancellation" },
  { id: 2, key: "depositRequired" },
  { id: 3, key: "fullyPrepaid" },
  { id: 4, key: "flexibleCancellation" },
  { id: 5, key: "guaranteedByCc" },
];

const serviceChargeTypes = [
  { key: "perAdultPerNight", id: 1 },
  { key: "perAdultPerStay", id: 2 },
  { key: "perChildPerNight", id: 3 },
  { key: "perChildPerStay", id: 4 },
  { key: "perNight", id: 5 },
  { key: "perUnit", id: 6 },
  { key: "perUnitPerAdult", id: 7 },
  { key: "perUnitPerAdultPerNight", id: 8 },
  { key: "perUnitPerNight", id: 9 },
  { key: "perStay", id: 10 },
  { key: "percentagePerStay", id: 11 },
  { key: "percentagePerStayPerAdult", id: 12 },
  { key: "selectedDatesPerStay", id: 14 },
  { key: "selectedDatesPerAdult", id: 15 },
  { key: "selectedDatesPerChild", id: 16 },
  { key: "perInfantPerNight", id: 17 },
  { key: "perInfantPerStay", id: 18 },
  { key: "selectedDatesPerInfant", id: 20 },
  { key: "perPersonPerNight", id: 26 },
  { key: "perPersonPerStay", id: 27 },
  { key: "selectedDatesPerPerson", id: 29 },
];

export { roomAmenities, rateLabelTypes, serviceChargeTypes, ibeRoomCategories };
