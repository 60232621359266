<template>
  <div class="room-wrapper">
    <div class="d-sm-flex">
      <div
        v-if="booking.profileImage"
        :class="{
          mobile: isMobile,
        }"
        class="room-image available"
      >
        <v-img
          max-width="100%"
          :src="booking.profileImage.slice(0, -5) + '2.png?'"
        >
        </v-img>
      </div>
      <div class="room-info mx-sm-3 d-flex flex-column flex-grow-1">
        <h3 class="my-0">
          {{ booking.rooms }}
        </h3>
        <p v-if="isMobile" class="text-body-2 mb-2">
          {{ $t("persons") }}: {{ booking.guestCount }}
        </p>
        <div class="dates-wrapper d-flex flex-wrap mt-auto">
          <div class="date-wrapper mr-4 mr-sm-8">
            <h4 class="text-grey-darken-1">
              {{ $t("arrival") }}
            </h4>
            <span class="text-subtitle-2">{{
              formattedDate(booking.checkin)
            }}</span>
          </div>
          <div class="date-wrapper">
            <h4 class="text-grey-darken-1">
              {{ $t("departure") }}
            </h4>
            <span class="text-subtitle-2">{{
              formattedDate(booking.checkout)
            }}</span>
          </div>
        </div>
      </div>
      <user-booking-details
        :amount="booking.resTotal"
        :nights="booking.duration"
        :currency="hotel.currencySign"
        :persons="booking.guestCount"
        :show-cancel-button="booking.resStatus === 1"
        @cancel-reservation="$emit('cancel-reservation', booking)"
      ></user-booking-details>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import isMobile from "@/mixins/isMobile";
import UserBookingDetails from "@/components/UserBookingDetails";

export default {
  name: "UserBooking",
  components: {
    UserBookingDetails,
  },
  mixins: [isMobile],
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nights() {
      return 2;
    },
    dateFormatter() {
      return new Intl.DateTimeFormat(this.language.locale, {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
    ...mapState(["hotel"]),
    ...mapState("language", ["language"]),
  },
  methods: {
    formattedDate(date) {
      return this.dateFormatter.format(new Date(date));
    },
  },
};
</script>
