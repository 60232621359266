<template>
  <div class="pt-2 pr-4">
    <v-expand-transition>
      <p v-show="showDescription" class="mb-0 show-line-breaks">
        {{ description }}
      </p>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "RoomDescription",
  props: {
    description: {
      type: String,
      required: true,
    },
    showDescription: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
