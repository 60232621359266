var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"room-wrapper"},[_c('div',{staticClass:"d-md-flex"},[(_vm.packageImage)?_c('div',{staticClass:"room-image available",class:{
          mobile: _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-img',{attrs:{"max-width":"100%","src":_vm.packageImage}},[(_vm.ibePackage.images.length > 1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('show-package-images', {
                images: _vm.images,
                name: _vm.packageName,
              })}}},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"white","dark":""}},on),[_vm._v("$plus")])]}}],null,false,1986495526)},[_c('span',[_vm._v(_vm._s(_vm.$t("showPackageImages")))])])],1):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"room-info mx-sm-3 d-flex flex-column flex-grow-1"},[_c('h3',{staticClass:"my-2 mt-sm-0"},[_c('span',{staticClass:"mr-2 notranslate"},[_vm._v(" "+_vm._s(_vm.packageName))]),(_vm.packagePolicy)?_c('text-popover',{attrs:{"text":_vm.packagePolicy}}):_vm._e()],1),_c('p',{staticClass:"mb-sm-0 show-line-breaks"},[_vm._v(_vm._s(_vm.packageDescription))]),(_vm.showPackageStayAlert)?_c('v-alert',{staticClass:"mt-2 mb-0 d-inline-block align-self-start",attrs:{"dense":"","text":"","type":"error"}},[_vm._v(_vm._s(_vm.$t("selectPackageWarning").format(_vm.ibePackage.minLos)))]):_vm._e()],1),_c('package-best-available-rate',{attrs:{"package-rate":_vm.ibePackage.bestAvailableRate,"hide-rooms-button":_vm.hideRoomsButton,"min-los":_vm.ibePackage.minLos,"occupancy":_vm.ibePackage.occupancy},on:{"show-package-rooms-view":function($event){return _vm.$emit('show-package-rooms-view')}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }