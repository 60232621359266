<template>
  <v-form ref="selectDatesForm" :disabled="disableForm" @submit.prevent>
    <selectable-date-option
      v-for="dateOption in serviceDateOptions"
      :key="dateOption.date"
      :option="dateOption"
      :charge-type="chargeType"
      :table-reservation="tableReservation"
      @update-date-option="updateDateOption"
    ></selectable-date-option>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import SelectableDateOption from "@/components/SelectableDateOption";

export default {
  name: "SelectedDatesTypeServiceForm",
  components: {
    SelectableDateOption,
  },
  props: {
    disableForm: {
      type: Boolean,
      required: true,
    },
    serviceDateOptions: {
      type: Array,
      required: true,
    },
    tableReservation: {
      type: Boolean,
      required: true,
    },
    chargeType: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    dateOptions: [],
  }),
  computed: {
    ...mapState(["stayDates", "nights"]),
  },
  methods: {
    updateDateOption(changedOption) {
      const serviceDateOptions = JSON.parse(
        JSON.stringify(this.serviceDateOptions)
      );
      const itemIndex = serviceDateOptions.findIndex(
        (option) => option.date === changedOption.date
      );
      if (itemIndex !== -1) {
        serviceDateOptions.splice(itemIndex, 1, changedOption);
      }
      this.$emit("update-service-date-options", serviceDateOptions);
    },
  },
};
</script>
