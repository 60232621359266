<template>
  <div class="d-flex flex-column">
    <v-card-text class="pa-0">
      <booking-summary-data></booking-summary-data>
    </v-card-text>
  </div>
</template>

<script>
import BookingSummaryData from "@/components/BookingSummaryData";

export default {
  name: "BookingSummaryDialog",
  components: { BookingSummaryData },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
