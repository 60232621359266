<template>
  <v-textarea
    ref="textareaInput"
    v-bind="$attrs"
    :validate-on-blur="validateOnBlur"
    :outlined="outlined"
    :dense="dense"
    autocomplete="off"
    spellcheck="false"
    no-resize
    auto-grow
    color="black"
    background-color="white"
    v-on="$listeners"
  >
  </v-textarea>
</template>

<script>
export default {
  name: "DefaultTextarea",
  inheritAttrs: false,
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
