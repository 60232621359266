import { dialogsData } from "@/static/dialogsData";

export default {
  data() {
    return {
      selectedDialog: null,
    };
  },
  methods: {
    showDialog(id) {
      const dialog = dialogsData.find((dialog) => dialog.id === id);
      if (dialog) {
        this.selectedDialog = JSON.parse(JSON.stringify(dialog));
      }
    },
    closeDialog() {
      if (this.selectedDialog) {
        this.selectedDialog.show = false;
        setTimeout(() => {
          this.selectedDialog = null;
        }, 300);
      }
    },
  },
};
