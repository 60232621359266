<template>
  <div class="room-wrapper">
    <div class="d-md-flex">
      <div
        v-if="hotel.hotelImage"
        :class="{
          mobile: $vuetify.breakpoint.smAndDown,
          available: hotel.isAvailable,
        }"
        class="room-image"
      >
        <v-img max-width="100%" :src="hotelImageUrl"> </v-img>
      </div>
      <div class="d-sm-flex flex-grow-1">
        <div class="room-info mx-sm-3 d-flex flex-column flex-grow-1">
          <h3 class="my-0">{{ hotel.hotelName }}</h3>
          <p class="mb-sm-1 font-weight-bold">{{ hotel.cityName }}</p>
          <p class="mb-sm-1">{{ hotelDescription }}</p>
        </div>
        <hotel-best-rate
          :hotel-data="hotelData"
          :loading="loading"
          @select-hotel="$emit('select-hotel')"
        ></hotel-best-rate>
      </div>
    </div>
  </div>
</template>

<script>
import localizedText from "@/mixins/localizedText";
import HotelBestRate from "@/components/HotelBestRate";

export default {
  name: "HotelOption",
  components: { HotelBestRate },
  mixins: [localizedText],
  props: {
    hotel: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    hotelDescription() {
      return this.getLocalizedText(this.hotel.hotelImage.imageData, "name");
    },
    hotelImageUrl() {
      const urlBase = this.hotel.hotelImage.imageURL.slice(0, -5);
      let imageSize = "2.png";
      if (this.$vuetify.breakpoint.smAndDown) {
        imageSize = "1.png";
      }
      return urlBase + imageSize;
    },
    hotelData() {
      if (!this.hotel) {
        return null;
      }
      const hotel = this.hotel;
      const hotelData = {
        bestAvailableRate: hotel.bestAvailableRate,
        id: hotel.apiKey,
        isAvailable: hotel.isAvailable,
        currency: hotel.currencyCode,
      };
      return hotelData;
    },
  },
};
</script>
