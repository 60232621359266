<template>
  <div
    class="room-image"
    :class="{
      available: available,
      mobile: $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-img :src="image.imageURL.slice(0, -5) + '2.png?' + noImageCache">
      <v-btn
        v-if="showCarouselButton"
        icon
        @click.stop="$emit('show-room-images')"
      >
        <v-tooltip top color="primary">
          <template #activator="{ on }">
            <v-icon color="white" dark v-on="on">$plus</v-icon>
          </template>
          <span>{{ $t("showRoomImages") }}</span>
        </v-tooltip>
      </v-btn>
    </v-img>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RoomImage",
  props: {
    image: {
      type: Object,
      required: true,
    },
    available: {
      type: Boolean,
      required: true,
    },
    showCarouselButton: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(["noImageCache"]),
  },
};
</script>
