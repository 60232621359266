<template>
  <v-autocomplete
    ref="autocompleteInput"
    v-bind="$attrs"
    :validate-on-blur="validateOnBlur"
    :outlined="outlined"
    :dense="dense"
    :menu-props="selectMenuProps"
    color="black"
    background-color="white"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    <template #message="{ message }">
      {{ $t(message) }}
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "DefaultAutocomplete",
  inheritAttrs: false,
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
    menuProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    selectMenuProps() {
      const selectMenuProps = { offsetY: true, maxHeight: 176 };
      const menuProps = this.menuProps;
      return {
        ...selectMenuProps,
        ...menuProps,
      };
    },
  },
};
</script>
