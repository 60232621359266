<template>
  <div
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
    }"
    class="room-rates d-flex flex-column flex-sm-row justify-sm-end flex-md-column justify-md-center align-center ml-auto"
  >
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <template v-else>
      <div
        class="d-flex flex-column justify-center align-center pr-sm-8 pr-md-0"
      >
        <template v-if="hotelData.isAvailable">
          <div class="best-roomrate d-flex flex-column my-1">
            <div class="best-roomrate-amount">
              <span class="text-caption">{{ $t("from") }}</span>
              <span class="currency-sign mx-2">{{ hotelData.currency }}</span>
              <span class="text-h4">{{
                getFormattedAmount(hotelData.bestAvailableRate).units
              }}</span
              ><span class="ml-n1">
                {{
                  getFormattedAmount(hotelData.bestAvailableRate).cents
                }}</span
              >
            </div>
            <span class="text-caption ml-auto mt-n2">
              {{
                nights > 1
                  ? nights + " " + $t("nights_plur")
                  : nights + " " + $t("night")
              }}
            </span>
          </div>
          <v-btn
            class="align-self-sm-end align-self-md-center"
            color="primary"
            :to="ibeUrl"
            >{{ $t("book") }}
            <v-icon large right>$arrow_right</v-icon>
          </v-btn>
        </template>
        <p v-else>{{ $t("noAvailableRoomsFound") }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import amounts from "@/mixins/amounts";
import { mapState } from "vuex";

export default {
  name: "HotelBestRate",
  mixins: [amounts],
  props: {
    hotelData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ibeUrl() {
      if (!this.hotelData || !this.stayDates) {
        return "";
      }
      const id = this.hotelData.id,
        arrival = this.stayDates.arrivalDate,
        departure = this.stayDates.departureDate;
      let ibeUrl = `/?id=${id}&arrival=${arrival}&departure=${departure}&language=${this.language.locale}`;
      return ibeUrl;
    },
    ...mapState(["nights", "stayDates", "roomsGuests", "promoCode"]),
    ...mapState("language", ["language"]),
  },
};
</script>
