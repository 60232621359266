import { mapState, mapActions } from "vuex";

export default {
  computed: {
    totalPrice() {
      if (this.roomBookings.length === 0) {
        return 0;
      }
      let totalPrice = 0;
      this.roomBookings.forEach((room) => {
        totalPrice += parseFloat(room.rate.total);
        room.services.forEach((service) => {
          totalPrice += parseFloat(service.total);
        });
        if (this.mandatoryRoomServices.length) {
          this.mandatoryRoomServices
            .filter(
              (service) =>
                service.rooms.length === 0 ||
                service.rooms.includes(room.room.code)
            )
            .forEach((service) => {
              totalPrice += parseFloat(
                this.calculateServiceAmount(service, room)
              );
            });
        }
      });
      if (this.mandatoryBookingServices.length) {
        this.mandatoryBookingServices.forEach((service) => {
          totalPrice += parseFloat(this.calculateServiceAmount(service));
        });
      }
      return Number(totalPrice.toFixed(4));
    },
    dueNowTotal() {
      if (this.guaranteeNow) {
        return this.roomsDueNowTotal;
      }
      return this.roomsDueNowTotal + this.additionalServicesDueNow;
    },
    roomsDueNowTotal() {
      const dueNow = this.calcDueNow(this.roomBookings);
      return dueNow;
    },
    additionalServicesDueNow() {
      let dueNow = 0;
      if (this.mandatoryBookingServices.length > 0) {
        // als er tenminste 1 kamer is met een betaaltype Deposit + amount 0 + percentage > 0, of NonRefundable + percentage
        // dan worden alle mandatory bookingservices bij de due now gerekend
        if (
          this.roomBookings.some(
            (room) =>
              (room.rate.paymentMethod.paymentType === 4 &&
                room.rate.paymentMethod.percentage > 0) ||
              (room.rate.paymentMethod.paymentType === 2 &&
                !room.rate.paymentMethod.amount &&
                room.rate.paymentMethod.percentage > 0)
          )
        ) {
          this.mandatoryBookingServices.forEach((service) => {
            dueNow += parseFloat(this.calculateServiceAmount(service));
          });
        }
      }
      return Number(dueNow.toFixed(4));
    },
    guaranteeNow() {
      return this.roomBookings.some(
        (room) => room.rate.paymentMethod.paymentType === 3
      );
    },
    mandatoryBookingServices() {
      return this.additionalServices.filter(
        (service) => service.applicationType === 4
      );
    },
    mandatoryRoomServices() {
      return this.additionalServices.filter(
        (service) => service.applicationType === 3
      );
    },
    ...mapState([
      "roomsGuests",
      "roomBookings",
      "nights",
      "additionalServices",
      "total",
    ]),
    ...mapState("language", ["language"]),
  },
  watch: {
    totalPrice: {
      immediate: true,
      handler(val) {
        this.setTotal(val);
      },
    },
    dueNowTotal: {
      immediate: true,
      handler(val) {
        this.setDueNow(Number(val.toFixed(2)));
      },
    },
  },
  methods: {
    calculateServiceAmount(service, room) {
      let amount = 0;
      let adults = this.roomBookings
        .map((booking) => parseInt(booking.selectedRoom.adults))
        .reduce((prev, curr) => prev + curr);
      let children = this.roomBookings
        .map((booking) => parseInt(booking.selectedRoom.children))
        .reduce((prev, curr) => prev + curr);
      let infants = this.roomBookings
        .map((booking) => parseInt(booking.selectedRoom.infants))
        .reduce((prev, curr) => prev + curr);
      let totalNettAmount = this.roomBookings
        .map((booking) => parseFloat(booking.rate.nettTotal))
        .reduce((prev, curr) => prev + curr);
      if (room) {
        adults = room.selectedRoom.adults;
        children = room.selectedRoom.children;
        infants = room.selectedRoom.infants;
        const availableService = room.availableServices.find(
          (availableService) =>
            availableService.serviceCode === service.serviceCode
        );
        if (availableService) {
          service = availableService;
        }
        totalNettAmount = this.roomBookings.find(
          (roombooking) => roombooking.id === room.id
        ).rate.nettTotal;
      }

      const nights = this.nights;
      switch (service.chargeType) {
        case 1: // perAdultPerNight,
          amount = service.price * adults * nights;
          break;
        case 2: // perAdultPerStay,
          amount = service.price * adults;
          break;
        case 3: // perChildPerNight,
          amount = service.price * children * nights;
          break;
        case 4: // perChildPerStay,
          amount = service.price * children;
          break;
        case 5: // perNight,
          amount = service.price * nights;
          break;
        case 6: // perUnit,
          amount = service.price;
          break;
        case 7: // perUnitPerAdult,
          amount = service.price * adults;
          break;
        case 8: // perUnitPerAdultPerNight,
          amount = service.price * adults * nights;
          break;
        case 9: // perUnitPerNight,
          amount = service.price * nights;
          break;
        case 10: // perStay
          amount = service.price;
          break;
        case 11: // percentagePerStay,
          amount = 0.01 * service.price * totalNettAmount;
          break;
        case 12: // percentagePerStayPerAdult,
          amount = 0.01 * service.price * totalNettAmount * adults;
          break;
        case 17: // perInfantPerNight,
          amount = service.price * infants * nights;
          break;
        case 18: // perInfantPerStay,
          amount = service.price * infants;
          break;
        case 26: // PerPersonPerNight,
          amount = service.price * (adults + children + infants) * nights;
          break;
        case 27: // PerPersonPerStay,
          amount = service.price * (adults + children + infants);
          break;
        case 28: // PercentagePerStayPerPerson,
          amount =
            0.01 *
            service.price *
            totalNettAmount *
            (adults + children + infants);
          break;
        default:
          break;
      }
      return amount;
    },
    calcDueNow(rooms) {
      let totalDueNow = 0;
      if (this.guaranteeNow) {
        totalDueNow = parseFloat(this.total);
      } else {
        rooms.forEach((room) => {
          const paymentMethod = room.rate.paymentMethod;
          let roomTotal = 0;
          let roomDueNow = 0;
          roomTotal += parseFloat(room.rate.total);
          room.services.forEach((service) => {
            roomTotal += parseFloat(service.total);
          });
          if (this.mandatoryRoomServices.length > 0) {
            this.mandatoryRoomServices
              .filter(
                (service) =>
                  service.rooms.length === 0 ||
                  service.rooms.includes(room.room.code)
              )
              .forEach((service) => {
                roomTotal += parseFloat(
                  this.calculateServiceAmount(service, room)
                );
              });
          }
          switch (paymentMethod.paymentType) {
            case 2:
              if (paymentMethod.amount) {
                roomDueNow = paymentMethod.amount;
              } else {
                roomDueNow = (paymentMethod.percentage / 100) * roomTotal;
              }
              break;
            case 4:
              roomDueNow = (paymentMethod.percentage / 100) * roomTotal;
              break;
          }
          totalDueNow += roomDueNow;
        });
      }
      return totalDueNow;
    },
    ...mapActions(["setTotal", "setDueNow"]),
  },
};
