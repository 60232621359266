<template>
  <div
    class="flex-grow-1 mr-2 mr-lg-4"
    :class="{
      'not-mobile': $vuetify.breakpoint.smAndUp,
    }"
  >
    <h2 class="text-h5 mb-4">
      {{ $t("selectPackage") }}
      <room-to-select-info v-if="roomsGuests.length > 1"></room-to-select-info>
    </h2>
    <p v-if="packages.length === 0">
      {{ $t("noPackagesFound") }}
    </p>
    <ibe-package
      v-for="ibePackage in packages"
      v-else
      :key="ibePackage.ratePlanCode"
      :ibe-package="ibePackage"
      @show-package-images="showPackageImages"
      @show-package-rooms-view="$emit('show-package-rooms-view', ibePackage)"
    ></ibe-package>
    <default-dialog
      v-if="selectedDialog"
      :show-dialog="selectedDialog.show"
      :width="selectedDialog.width"
      :title="selectedPackage.name"
      :include-actions="selectedDialog.includeActions"
      @close-dialog="closeDialog"
    >
      <template #dialog-content>
        <component
          :is="selectedDialog.content"
          :ref="selectedDialog.ref"
          :show-dialog="selectedDialog.show"
          :images="selectedPackage.images"
          @close-dialog="closeDialog"
        ></component>
      </template>
    </default-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dialogs from "@/mixins/dialogs";
import IbePackage from "@/components/IbePackage";
import RoomToSelectInfo from "@/components/RoomToSelectInfo";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import ImagesCarousel from "@/components/dialogs/ImagesCarousel";

export default {
  name: "SelectPackages",
  components: {
    IbePackage,
    DefaultDialog,
    ImagesCarousel,
    RoomToSelectInfo,
  },
  mixins: [dialogs],
  props: {
    packages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedPackage: null,
  }),
  computed: {
    ...mapState(["roomsGuests"]),
  },
  methods: {
    showPackageImages(data) {
      this.selectedPackage = data;
      this.showDialog("imagesCarousel");
    },
  },
};
</script>
